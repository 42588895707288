<template>
  <!-- <label :class="labelclass">{{ label }} </label> -->
  <label
    class="d-flex"
    :class="Editclass == undefined ? 'align-items-center' : Editclass"
  >
    <div :class="labelclass"></div>
    <!-- <Icon icon="bi:dot" :class="labelclass" width="32" height="32" /> -->
    {{ name }}
  </label>
</template>

<script>
// import { Icon } from '@iconify/vue';
export default {
  props: {
    name: { type: String },
    // label: { type: String },
    labelclass: { type: String },
    Editclass: { type: String },
  },
  components: {
    // Icon
  },
};
</script>

<style scoped lang="scss">
label {
  font-weight: normal !important;
  div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    // margin-right: 4px;
  }
}
</style>
