export const typeSchema = [
  {
    // class: "ms-5",
    group: [
      {
        formID: "Campus",
        group: [
          {
            class: "row m-0 gx-3 p-0",
            group: [
              {
                class: "d-flex flex-row align-items-start mb-3",

                group: [
                  {
                    class: "d-flex justify-content-between w-100 mb-4 p-0",
                    component: [
                      {
                        name: "2",
                        label: "201", //Campus information
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle",
                      },
                      {
                        label: "34", // Require
                        type: "HeadLabel",
                        class: "text-danger-500 fnt-regular fnt-caption",
                      },
                    ],
                  },
                  // -----
                  {
                    class: "col-lg-8 mb-3 pe-lg-2 ps-sm-0 col-sm-12 p-0 ",

                    component: [
                      {
                        label: "66", // Campus name TH
                        type: "InputText",
                        datavalue: "strCampusNameTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpCampusnameTH",
                        validate: "letterTH",
                        request: true,
                        maxlength: 500
                      },
                    ],
                  },
                  {
                    class: "col-lg-4 mb-3 ps-lg-2 ps-sm-0 me-0 p-0 col-sm-12",
                    component: [
                      {
                        label: "67", //Campus initial TH
                        type: "InputText",
                        datavalue: "strCampusInitialTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpInitialTH",
                        validate: "InitialTH",
                        request: true,
                        maxlength: 20
                      },
                    ],
                  },
                ],
              },

              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-8 mb-3 pe-lg-2 p-sm-0 col-sm-12 p-0",
                    component: [
                      {
                        label: "63", // Campus name EN
                        type: "InputText",
                        datavalue: "strCampusNameEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpCampusnameEN",
                        validate: "letterEN",
                        request: true,
                        maxlength: 500
                      },
                    ],
                  },
                  {
                    class: "col-sm-12 col-lg-4 mb-3 ps-lg-2 p-sm-0 p-0",

                    component: [
                      {
                        label: "64", // Campus initial EN
                        type: "InputText",
                        datavalue: "strCampusInitialEN",
                        alertMsg: "202",
                        hint: "Entered Data is Incorrect",
                        name: "inpInitialEN",
                        validate: "InitialEN",
                        request: true,
                        maxlength: 20
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            class: "row m-0",
            group: [
              {
                class: "row mb-4 gx-3",
                group: [
                  {
                    class: "d-flex justify-content-between p-0",
                    component: [
                      {
                        label: "32", //Contact
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-4",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-4 col-sm-4 p-0",
                    component: [
                      {
                        type: "InputPrefix",
                        label: "364",
                        request: true,
                        alertMsg: "202",
                        datavalue: "strCountrycode",
                        validate: "countrycode",
                        maxlength: 20,
                      },
                    ],
                  },

                  {
                    class: "col-lg-10 col-sm-12 mb-3 pe-lg-2 p-sm-0 ",
                  },
                ],
              },
              {
                class: "row gx-3",
                group: [
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-flex justify-content-end", // Label phone ex.
                    component: [
                      {
                        type: "HeadLabel",
                        label: "372",
                        helper: true,
                        class: "fnt-regular fnt-body",
                        tooltipMsg: "375",
                        classBtn: "tooltiper",
                        classBtnIcon:'fill-black',
                        icon: "QuestionOutline",
                        tooltipPlacement: "tooltip-right"
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-sm-none d-lg-flex",
                  },
                  {
                    class: "col-lg-6 col-sm-12 pe-lg-2 mb-3 mb-lg-3 mb-md-3 mb-sm-0 p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "365", // Dis
                        require: " *",
                        datavalue: "strCampusTelTH",
                        validate: "PhoneNumberTH",
                        alertMsg: "202",
                        class: "fnt-regular fnt-body",
                        request: true,
                        maxlength: 500,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 ps-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "366",
                        require: " *",
                        datavalue: "strCampusTelEN",
                        validate: "PhoneNumberEN",
                        alertMsg: "202",
                        class: "fnt-regular fnt-body",
                        request: true,
                        maxlength: 500,

                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-flex justify-content-end", // Label Fax ex.
                    component: [
                      {
                        type: "HeadLabel",
                        // name:"Example of filling in fax information.",
                        label: "373",
                        helper: true,
                        class: "fnt-regular fnt-body",
                        tooltipMsg: "375",
                        classBtn: "tooltiper",
                        classBtnIcon:'fill-black',
                        icon: "QuestionOutline",
                        tooltipPlacement: "tooltip-right"
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-2 p-0 d-sm-none d-lg-flex",
                  },
                  {
                    class: "col-lg-6 col-sm-12 mb-3 pe-lg-2  p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "367",
                        datavalue: "strCampusFaxTH",
                        validate: "letterTH",
                        alertMsg: "202",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 ps-lg-2 mb-3 p-sm-0 p-0",
                    component: [
                      {
                        type: "InputLongText",
                        label: "368",
                        datavalue: "strCampusFaxEN",
                        validate: "letterEN",
                        alertMsg: "202",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row  mb-3 gx-3",
                group: [
                  {
                    class: "col-lg-6 col-sm-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        label: "71", // Facebook
                        type: "InputText",
                        datavalue: "strCampusFacebook",
                        alertMsg: "202",
                        hint: "e.g. https://www.facebook.com/ex/",
                        name: "inpCampusFacebook",
                        validate: "facebookpage",
                        labelSub: ": https://www.facebook.com/ex/",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                  {
                    class: "col-lg-6 col-sm-12 p-sm-0 mb-3 ps-lg-2 p-0",
                    component: [
                      {
                        label: "70", //Email
                        type: "InputText",
                        datavalue: "strCampusEmail",
                        alertMsg: "202",
                        hint: "Email: name@email.com",
                        name: "inpCampusEmail",
                        validate: "email",
                        labelSub: ": name@email.com",
                        request: true,
                        maxlength: 100,
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-12 mb-3 p-0",
                    component: [
                      {
                        label: "73", // Website
                        type: "InputText",
                        datavalue: "strCampusWebsite",
                        alertMsg: "202",
                        hint: "e.g. https://www.wu.ac.th/",
                        name: "inpCampusWebsite",
                        labelSub: ": https://www.wu.ac.th/",
                        validate: "webUrl",
                        request: true,
                        maxlength: 200,
                      },
                    ],
                  },
                ],
              },
              // Toggle
            ],
          },

          {
            class: "row m-0",
            group: [
              {
                class: "my-4",
                group: [
                  {
                    class: "p-0",
                    component: [
                      {
                        label: "371", //Contact
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-3",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row gx-3",
                group: [
                  {
                    class: "col-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        label: "369", // Address number
                        type: "InputText",
                        datavalue: "strCampusAddressTH",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAddressnumberTH",
                        // validate: "addressTH",
                        validate: "",//พี่บอล&พี่ปามให้ปล่อยฟรี
                        request: true,
                        maxlength: 1000,
                      },
                    ],
                  },
                  {
                    class: "col-12 mb-3 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        type: "Selectdropdown",
                        strName: "376", // Provice
                        star: " *",
                        state: "address",
                        List: true,
                        alertMsg: "202",
                        blnSystemword: false,
                        defaultLang: "TH",
                        datavalue: "intSubdistrict",
                        blnAddress: true,
                        blnChangelang:true,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            class: "row m-0",
            group: [
              {
                class: "my-4",
                group: [
                  {
                    class: "p-0",
                    component: [
                      {
                        label: "359", //Contact
                        type: "HeadLabel",
                        class: "fnt-medium fnt-subtitle mb-3",
                      },
                    ],
                  },
                ],
              },
              {
                class: "row mb-3 gx-3",
                group: [
                  {
                    class: "col-12 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [
                      {
                        label: "370", // Address number
                        type: "InputText",
                        datavalue: "strCampusAddressEN",
                        alertMsg: "202",
                        hint: "Please input in Thai",
                        name: "inpAddressnumberEN",
                        // validate: "addressEN",
                        validate: "",//พี่บอล&พี่ปามให้ปล่อยฟรี
                        request: true,
                        maxlength: 1000,
                      },
                    ],
                  },
                  {
                    class: "col-12 mb-3 mb-3 pe-lg-2 p-sm-0 p-0",
                    component: [

                      {
                        type: "Selectdropdown",
                        strName: "377", // Provice
                        star: " *",
                        state: "address",
                        List: true,
                        alertMsg: "202",
                        blnSystemword: false,
                        defaultLang: "TH",
                        datavalue: "strSubdistrict",
                        blnAddress: true,
                        blnChangelang:true,
                      },
                    ],
                  },
                  // {
                  //   class: "col-12 mb-3 mb-3 pe-lg-2 p-sm-0",
                  //   component: [
                  //     {
                  //       type: "Selectdropdown",
                  //       strName: "382", // Provice
                  //       star: " *",
                  //       state: "address",
                  //       List: true,
                  //       alertMsg: "202",
                  //       blnSystemword: false,
                  //       defaultLang: "EN",
                  //       datavalue: "intSubdistrict",
                  //     },
                  //   ],
                  // },
                ],
              },
            ],
          },
          {
            class: "d-flex flex-column gx-3 mt-3",
            group: [
              {
                class: "mb-lg-3 mb-sm-2 p-0",
                component: [
                  {
                    name: "21", // Main campus
                    type: "Toggle",
                    datavalue: "intCampusMain",
                    open: "214", // On
                    close: "215", // Off
                    alertMsg: "358",
                    altMsgBox: "358",
                    tooltipMsg: "217",
                    tooltipPlacement: "tooltip-top"
                  },
                ],
              },
              {
                class: "mb-lg-3 mb-sm-2 p-0",
                component: [
                  {
                    name: "19", // Status
                    type: "Toggle",
                    datavalue: "intCpstatus",
                    open: "61", // Open
                    style: "z-index:-1 !important;",
                    close: "62", // Close
                    alertMsg: "354",
                    altMsgBox: "354",
                    tooltipMsg: "379",
                    tooltipPlacement: "tooltip-top"
                  },
                ],
              },
            ],
          },
          {
            class: "row mb-lg-3 mb-sm-2 ps-3",
            group: [
              {
                class: "p-0",
                component: [
                  {
                    type: "HeadLabel",
                    require: " *",
                    label: "33", // Logo
                    class: "mb-3 p-0 fnt-subtitle fnt-medium",
                  },
                ],
              },
              {
                class: "col-12 p-0",
                component: [
                  {
                    name: "33", // Logo
                    type: "InputFile",
                    datavalue: "strPicpath",
                    alertMsg: "202",
                    helper: "374",
                    maxSize: 2000000,
                    subname: "405",
                    widthicon: "32",
                    heighticon: "32",
                    filetype: "image/jpeg,image/png"
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    group: [
      {
        class: "d-flex justify-content-between mt-6",
        group: [
          {
            class: "d-flex",
            group: [
              {
                class: "modal-footer border-0 justify-content-start p-0 ",
                component: [
                  {
                    type: "Button",
                    name: "36", // OK
                    state: "submit",
                    class: "btn-fix me-3",
                    deleteinform: false,
                  },
                  {
                    type: "Button",
                    name: "35", // Cancel
                    state: "cancel",
                    class: "btn-fix btn-cancel",
                    deleteinform: false,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        class: "w-100 d-flex justify-content-center",
        group: [
          {
            component: [
              {
                class: "mt-3",
                type: "DeleteinForm",
                icon: "DeleteFill",
                classBtnIcon: "fill-secondary",
                datavalue: "blnDeleteinForm",
                state: "delete",
              },
            ],
          },
        ],
      },
    ],
  },
];
export const request = [
  "strCampusNameEN",
  "strCampusInitialEN",
  "intSubdistrict",
  "strCampusNameTH",
  "strCampusInitialTH",
  "strCampusAddressTH",
  "strCampusAddressEN",
  "strCampusEmail",
  "strCampusFacebook",

  "strCampusWebsite",
  "strPicpath",

  "strCampusTelTH",
  "strCampusTelEN",

  "strCountrycode",
];
