<template>
  <div class="loading d-flex flex-column align-items-center p-4">
    <img src="../assets/img/logo.png" width="50" height="50" alt="" />
    <div class="progress w-100 my-2" style="height: 5px; border-radius: 25px">
      <div
        class="progress-bar bg-secondary"
        role="progressbar"
        aria-valuenow="0"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="'width:' + percen + '% !important'"
      ></div>
      <!-- ใช้ style="width:25%" ในการโหลด -->
    </div>
    <span class="fnt-regular fnt-body">Loading... Please wait</span>
  </div>
</template>

<script>
  export default {
    props: {
      percen: { type: String },
    },
  };
</script>

<style lang="scss" scoped>
.loading {
  width: 275px;
  position: relative;
  .progress-bar {
    transition: 0.3s;
  }
}
</style>
