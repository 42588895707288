<template>
  <div
    class="p-0 rounded-3 bg-white"
    :style="
      device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
    "
  >
    <label
      for=""
      class="fnt-medium fnt-subtitle mb-4"
      :class="device == 'mobile' ? 'fnt-body' : ''"
      >{{ $t("517") + " : " }}</label
    >

    <Search
      :mode="'auto'"
      :hint="$t('447', { fieldname: $t('528').toLowerCase() })"
      @onSearch="searchMenuList($event)"
      @onClean="clear()"
      ref="Search"
    />

    <div
      v-if="Loading == false"
      class="tree-container none-scrollbar mt-4"
      :style="'height:fit-content;'"
    >
      <table class="table m-0">
        <thead>
          <tr class style="text-align: left">
            <th scope="col" class="fnt-body" style="width: 0">
              <div class="d-flex align-items-center justify-content-center">
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                  {{ $t("18") }}
                </label>
              </div>
            </th>
            <th scope="col" class="fnt-body">
              <div class="d-flex align-items-center">
                <button
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                >
                  {{ "intModuleUsed" }}
                </button>
              </div>
            </th>
            <th scope="col" class="fnt-body">
              <div class="d-flex align-items-center">
                <button
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                >
                  {{ "NameEN" }}
                </button>
              </div>
            </th>
            <th scope="col" class="fnt-body">
              <div class="d-flex align-items-center">
                <button
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                >
                  {{ "NameTH" }}
                </button>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <!-- {{
          ListSortData
        }} -->
        <tbody class="border-0" v-if="ListSortData.length != 0">
          <template v-for="(item, index) in ListSortData" :key="index">
            <tr
              :class="item.highlight == true ? 'bg-secondary-50' : ''"
              class="border-bottom"
              @click="expand(item.strModuleID)"
            >
              <!-- {{ item.strModuleID }} -->
              <th>
                <div
                  class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                >
                  <!-- {{ item.level }} -->
                  <!-- {{ index }}  -->

                  {{ parseInt(index) + 1 }}
                </div>
              </th>
              <td class="fnt-regular fnt-body first-row">
                <div class="mt-1">
                  <span
                    class="q-tree-link q-tree-label d-flex align-items-center"
                  >
                    <moveDown
                      width="18"
                      class="fill-secondary me-2"
                      :class="
                        item.listRoleMenus.length > 0
                          ? opened.includes(item.strModuleID)
                            ? 'rotate-180'
                            : 'rotate-180-revert'
                          : 'opacity-0'
                      "
                    />
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="theCheckbox"
                        :checked="
                          item['intMenuUseCount'] ==
                            item['listRoleMenus'].length.toString() &&
                          item['intMenuUseCount'] !== 0
                            ? true
                            : false
                        "
                        :indeterminate="
                          item['intMenuUseCount'] !=
                            item['listRoleMenus'].length.toString() &&
                          item['intMenuUseCount'] !== 0
                            ? true
                            : false
                        "
                        @change="AccessModule($event, item['strModuleID'])"
                      />
                    </div>
                  </span>
                </div>
              </td>
              <td class="fnt-regular fnt-body first-row">
                <div class="mt-1">
                  <span lass="q-tree-link q-tree-label">
                    {{ item[filterLang(item, "EN")] }}
                  </span>
                </div>
              </td>
              <td class="fnt-regular fnt-body first-row">
                <div class="mt-1">
                  <span lass="q-tree-link q-tree-label">
                    {{ item[filterLang(item, "TH")] }}
                  </span>
                </div>
              </td>
            </tr>
            <transition name="move" class="">
              <tr
                v-if="opened.includes(item.strModuleID)"
                class="border-bottom"
              >
                <td
                  colspan="12"
                  class="table-expand"
                  v-if="item.listRoleMenus.length > 0"
                >
                  <div>
                    <table class="table table-hover">
                      <tbody>
                        <tr
                          v-for="(child, index) in item.listRoleMenus"
                          :key="index"
                        >
                          <td>
                            <div
                              class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                            >
                              {{ parseInt(index) + 1 }}
                            </div>
                          </td>
                          <td class="fnt-regular fnt-body first-row">
                            <div class="mt-1">
                              <span lass="q-tree-link q-tree-label">
                                <span class="form-check">
                                  <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="theCheckbox"
                                    :checked="child['blnMenuUsed']"
                                    @change="
                                      AccessMenu(
                                        $event,
                                        child['strMenuID'],
                                        child['strModuleID']
                                      )
                                    "
                                  />
                                </span>
                              </span>
                            </div>
                          </td>
                          <td class="fnt-regular fnt-body first-row">
                            <div class="mt-1">
                              <span lass="q-tree-link q-tree-label">
                                {{ child[filterLang(child, "EN")] }}
                              </span>
                            </div>
                          </td>
                          <td class="fnt-regular fnt-body first-row">
                            <div class="mt-1">
                              <span lass="q-tree-link q-tree-label">
                                {{ child[filterLang(child, "EN")] }}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
                <!-- <th>
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-center
                      fnt-regular fnt-body
                      mt-1
                    "
                  >
                    {{ parseInt(index) + 1 }}
                  </div>
                </th> -->
              </tr>
            </transition>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { moveDown } from "@/components/icon/index";
import Search from "@/components/input/inputSearch.vue";
// import ListAdd from "@/components/form/UserGroup/listAdd.vue";
import customfilter from "@/typescript/filter";
// import Button from "../button/button.vue";
// import axios from "axios";
// // import UserRoleAccess from "@/components/form/UserRoleAccess.vue";
// import apiuserrolegroup from "@/typescript/urlapi/adminsystem/APIUserroleGroup";
// import urlapi from "@/typescript/urlapi/url";

export default {
  components: {
    Search,
    moveDown,
    // ListAdd,
    // Button,
  },
  computed: {
    ListSortData() {
      return this.listSortData;
    },
  },
  emits: ["addMenu", "addModule"],
  props: {
    listMenu: {
      type: Object,
    },
    Loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      listSortData: [],
      opened: [],
      SelectedList: [
        { field: "strModuleNameEN", head: "strModuleNameEN" },
        { field: "strModuleNameTH", head: "strModuleNameTH" },
        { field: "strMenuNameEN", head: "strMenuNameEN" },
        { field: "strMenuNameTH", head: "strMenuNameTH" },
      ],
      // ["strModuleNameEN", "strModuleNameTH"],
    };
  },
  mounted() {
    this.listSortData = { ...this.listMenu };
  },
  methods: {
    searchMenuList(event) {
      console.log(event);
      if (event != null || event != "") {
        this.listSortData = this.filterSelected(
          this.listMenu.slice(),
          this.SelectedList,
          event
        );
      }

      // if (event !== null && event.toString() !== "") {
      //   this.listSortData = this.filter(this.listMenu.slice(), event);
      //   console.log(this.listSortData);
      //   // let indexnumber = 1;
      //   // for (let obj in this.listSortData) {
      //   //   this.listSortData[obj].indexnumber = indexnumber;
      //   //   indexnumber++;
      //   // }
      // } else if (event == null || event.toString() == "") {
      //   this.listSortData = this.listMenu.slice();
      // }
    },

    filter(array, text) {
      const getNodes = (result, object) => {
        if (Array.isArray(object.listRoleMenus)) {
          const listRoleMenus = object.listRoleMenus.reduce(getNodes, []);

          if (listRoleMenus.length) {
            result.push({ ...object, listRoleMenus });
            return result;
          }
        }
        if (this.filterSelected(object, this.SelectedList, text)) {
          result.push(object);
          return result;
        }

        return result;
      };

      return array.slice().reduce(getNodes, []);
    },
    filterSelected(array, filters, event) {
      // console.log(array);
      const filterKeys = Object.keys(filters);
      return Object.values(array).filter((item) => {
        // validates all filter criteria
        return filterKeys.some((key) => {
          // console.log(filters[key]);
          // ignores non-function predicates
          if (typeof item[filters[key].field] == "undefined") {
            return false;
          } else {
            return (
              item[filters[key].field]
                .toString()
                .toLowerCase()
                .indexOf(event.toString().toLowerCase()) !== -1
            );
          }
        });
      });
    },

    // Expand Table ด้านใน
    expand(id) {
      this.blnexpand = !this.blnexpand;
      const index = this.opened.indexOf(id);
      console.log(this.opened.indexOf(id));
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    AccessMenu(event, menuID, moduleID) {
      // console.log(event.target.checked);
      // console.log(event + " : " + menuID + " : " + moduleID);
      this.$emit("addMenu", {
        event: event.target.checked,
        moduleID: moduleID,
        menuID: menuID,
      });
    },
    AccessModule(event, moduleID) {
      // console.log(event.target.checked);
      // console.log(event + " : " + moduleID);
      this.$emit("addModule", {
        event: event.target.checked,
        moduleID: moduleID,
      });
    },
    filterLang(items, lang) {
      return customfilter.filterLang(items, lang);
      // console.log(this.defaultLang);
      // if (items !== undefined && this.$i18n.locale == null) {
      //   return customfilter.filterLang(items, this.$i18n.locale);
      // } else if (items !== undefined && this.$i18n.locale != null) {
      //   return customfilter.filterLang(items, this.$i18n.locale);
      // }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_custom.scss";
.form-check {
  cursor: pointer !important;
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 2px !important;
    &:hover,
    &:focus,
    &:indeterminate {
      border: solid 1px $primary;
    }
  }
  input[type="checkbox"]:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }
  input[type="checkbox"]:indeterminate {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}
.tree-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 303px);
  width: auto;

  .table {
    overflow-y: auto;
    // width: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: $white !important;
      z-index: 1;

      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $black-300;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      tr {
        th {
          height: 48px;
        }

        th:first-child {
          position: sticky;
          left: 0;
          background-color: $white;
        }

        th:last-child {
          position: sticky;
          right: 0;
          background-color: $white;
        }
      }
    }

    tbody {
      tr {
        th:first-child {
          position: sticky;
          left: 0;
          background-color: #fff;
        }

        th:last-child {
          position: sticky;
          right: 0;
          background-color: #fff;
        }

        &:hover {
          td {
            background-color: $black-50;
          }

          th:first-child {
            background-color: $black-50 !important;
          }

          th:last-child {
            background-color: $black-50 !important;
          }
        }
      }
    }

    td {
      padding-right: 1rem;
      height: 44px;
      width: fit-content;

      // padding: 15px auto;
      div {
        white-space: nowrap;
      }
    }

    th {
      padding-right: 1rem;
      white-space: nowrap;
    }

    tr {
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}
.rotate-180 {
  transform: rotate(-180deg) !important;
  transition: 0.3s;
}
.rotate-180-revert {
  transform: rotate(-360deg) !important;
  transition: 0.3s;
}

.move-enter-active {
  animation: move-in 0.3s;
}
.move-leave-active {
  animation: move-in 0.3s reverse;
}
@keyframes move-in {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>