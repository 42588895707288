<template>
  <div
    class="d-flex flex-row justify-content-center align-items-center w-100 py-5"
  >
    <div
      class="
        d-flex
        flex-column
        align-items-center
        justify-content-center
        p-0
        text-black-50
      "
    >
      <Icon icon="ic:round-screen-search-desktop" width="100" height="100" />
      <span :class="classNotFound">{{ detail == '' ? $t("95", { fieldname: $t(name) }) : $t(detail) }}</span>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  props: {
    name: { type: String },
    detail: { type: String, default:'' },
    classNotFound:{type:String}
  },
};
</script>

<style></style>
