<template>
  <div @dragover.prevent.stop="onDragOver">
    <transition-group name="draggable-item-list">
      <draggable-item
        v-for="(item, index) in items"
        :key="item.id"
        :item="item"
        :containerId="id"
        :fieldtype="
          typeof item.data.items !== 'undefined' ? 'fieldset' : 'fieldnormal'
        "
        :groupType="groupType"
        :position="index"
        :blndraggable="blnDraggable(item.data.strID)"
        @itemDragOver="onItemDragOver"
        @DragEndClone="onDragEndClone"
        @dragend="dragend()"
        @dragstart="dragstart()"
        @dragenter.prevent
        :class="classDroplist"
      >
        <!-- {{
          typeof item.data.items !== "undefined" ? "fieldset" : "fieldnormal"
        }} -->
        <slot
          name="item"
          :fieldtype="
            typeof item.data.items !== 'undefined' ? 'fieldset' : 'fieldnormal'
          "
          :item="item.data"
        ></slot>
      </draggable-item>
    </transition-group>
  </div>
</template>

<script>
import { toRefs } from "vue";
import DraggableItem from "./DraggableItem.vue";
import { useDraggableContainer } from "../typescript/composables/draggable";

export default {
  name: "Draggable",
  components: {
    DraggableItem,
  },
  emits: ["dragend", "dragstart"],
  props: {
    groupType: String,
    modelValue: Array,
    classDroplist: { type: String },
    transition: {
      default: "0",
      type: String,
    },
    listdraggable: [],
  },

  methods: {
    dragend() {
      this.$emit["dragend"];
      console.log("end");
    },
    dragstart() {
      this.$emit["dragstart"];
      console.log("start");
    },
    blnDraggable(id) {
      // console.log(id);
      let drag = true;
      if (typeof this.listdraggable != "undefined") {
        // console.log(this.listdraggable);
        Object.values(this.listdraggable).filter((ele) => {
          if (ele == id) {
            drag = false;
          }
        });
      }

      return drag;
    },
    // onSetup() {
    //   const { modelValue, groupType } = toRefs(this.props);
    //   const { id, items, onDragOver, onItemDragOver, onDragEndClone } =
    //     useDraggableContainer(modelValue, this.context, groupType);

    //   return { id, items, onDragOver, onItemDragOver, onDragEndClone };
    // },
  },

  setup(props, context) {
    const { modelValue, groupType } = toRefs(props);
    const { id, items, onDragOver, onItemDragOver, onDragEndClone } =
      useDraggableContainer(modelValue, context, groupType);

    return { id, items, onDragOver, onItemDragOver, onDragEndClone };
  },
  computed: {
    transitionStyle() {
      //console.log(this.items);
      return `transform ${this.transition}ms`;
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-item-list-move {
  transition: v-bind(transitionStyle);
}
.Dropfix {
  width: fit-content !important;
}
</style>
