const apiuserrolegroup = {
  GetUserroleGroupList: "/v1/api/AdsUserRole/UserroleGroup",
  InsertUserroleGroup: "/v1/api/AdsUserRole/InsertUserRoleGroup",
  GetUserroleGroupbyID: "/v1/api/AdsUserRole/UserRoleGroupByID/",
  UpdateUserroleGroup: "/v1/api/AdsUserRole/UpdateUserroleGroup/",
  DeleteUserroleGroup: "/v1/api/AdsUserRole/DeleteUserRole/",
  GetModuleList: "/v1/api/AdsUserRole/Module/",
  SearchUser: "/v1/api/AdsUserRole/GetUserInfoList",
  InsertMemberToUserRoleGroup: "/v1/api/AdsUserRole/InserMemberToUserRoleGroup/",
  GetMemberOfuserRoleGroup:"/v1/api/AdsUserRole/UserLink/"
};
export default apiuserrolegroup;

