<template>
    <!-- {{ inactive }} -->
  <div
    v-if="inactive == false"
    class="deleteinform border-top w-100 d-flex justify-content-center"
    :class="classBtnDeleteinform"
  >
    <!-- {{ tooltip }} -->
    <button
      @click="$emit('input')"
      class="btn shadow-0 d-flex align-items-center justify-content-center"
      :data-bs-toggle="dataToggle"
      :data-bs-target="exampleModal"
      :class="classBtn"
      :title="tooltipMsg"
      :data-bs-placement="placement"
    >
      <!-- <Icon
      :icon="leftBtn"
      :width="width"
      :height="height"
      :class="classLeftBtn"
    /> -->
      <component
        :is="icon"
        :width="widthIcon"
        :height="heightIcon"
        :class="classBtnIcon"
      ></component>
      <span class="fnt-regular" :class="className">{{
        name != null ? $t(name) : name
      }}</span>
      <Icon
        :icon="rightBtn"
        :width="width"
        :height="height"
        class="ms-2 d-none"
      />
    </button>
    <div class="overlay-delete-inform"></div>
  </div>
</template>

<script>
import {
  ListOutline,
  BIconFilterOutline,
  CloseOutline,
  DisplayConfig,
  DeleteFill,
  EditFill,
  AddFill,
  SortArrow,
  Drag,
  RemoveFill,
  moveDown,
  ExpandIn,
  ExpandOut,
  AddOutline,
  OpenEye,
  NotiOutline,
} from "@/components/icon/index";
import { Icon } from "@iconify/vue";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
export default {
  props: {
    classBtnDeleteinform: { type: String },
    name: String,
    leftBtn: String,
    rightBtn: String,
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
    widthIcon: {
      type: Number,
      default: 24,
    },
    heightIcon: {
      type: Number,
      default: 24,
    },
    dataToggle: String,
    exampleModal: String,
    classBtn: { type: String, default: "" },
    classLeftBtn: String,
    tooltipMsg: String,
    icon: { type: String },
    className: { type: String },
    classBtnIcon: { type: String },
    placement: { type: String, default: "top" },
  },
  emits: ["input"],
  components: {
    // Icon name
    Icon,
    BIconFilterOutline,
    DisplayConfig,
    EditFill,
    DeleteFill,
    AddFill,
    SortArrow,
    Drag,
    RemoveFill,
    moveDown,
    ExpandIn,
    ExpandOut,
    CloseOutline,
    AddOutline,
    ListOutline,
    OpenEye,
    NotiOutline,
  },
  data() {
    return {
      iconType: "",
      inactive: false,
      tooltip: "",
      hover: false,
    };
  },
  mounted() {
    this.customTolltip();
  },
  methods: {
    //inti tooltip
    customTolltip() {
      const btnTooltip = document.querySelectorAll(
        `button[data-bs-toggle="${this.dataToggle}"]`
      );
      Array.from(btnTooltip).forEach(
        (tooltipNode) =>
          new Tooltip(tooltipNode, {
            trigger: "hover",
            delay: { show: 500, hide: 200 },
            html: true,
          })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  transition: 0.2s;
}
.tooltip-config {
  cursor: context-menu !important;
  &:hover {
    color: $black-500 !important;
  }
}
.deleteinform {
  z-index: 0 !important;
  button {
    transition: 150ms;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    z-index: 2 !important;
    svg {
      width: 30px;
      height: 30px;
    }
    &:hover {
      transform: scale(1.2);
      background-color: $danger;
      svg {
        fill: $white !important;
      }
      & + .overlay-delete-inform {
        opacity: 1;
        visibility: visible;
        z-index:1;
      }
    }
  }
  // &:hover{
  //   z-index: 999 !important;
  // }
}
.overlay-delete-inform {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(216, 42, 76, 0.15);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 150ms;
  z-index: 0;
  border-radius: 4px;
}
// button{
//   height: 36px !important;
// }
</style>
