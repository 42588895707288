<template>
  <!-- <div class="filter-container"> -->
  <!-- <div class="overlay" @click="onCancel()"></div> -->
  <div
    class="d-flex flex-column bg-white filter align-items-start px-4 pt-4 rounded-2 shadow"
    :class="classFilter"
    :style="device == 'mobile' ? 'width:80% !important;' : ''"
  >
    <div class="d-flex justify-content-between align-items-center w-100">
      <label for="" class="fnt-medium fnt-subtitle fs-4 mb-3">{{
        $t("22")
      }}</label>
      <button
        @click="Clear()"
        class="btn p-0 m-0 text-secondary fnt-regular fnt-body"
      >
        {{ $t("80") }}
      </button>
    </div>

    <template v-for="(data, index) in listDataFilterProcess" :key="index">
      <Select
        v-if="
          (data['list'][1].data.length <= 3 ||
            data['list'][2].state == 'intCpstatus') &&
          blnOpenFilter == true
        "
        :ref="'Selection' + index"
        @selected="addFilter($event, index + 1)"
        :intId="index"
        :strName="$t(data['list'][0].name)"
        class="mb-3"
        :listDataSelection="data['list'][1].data"
        :strValueName="data['list'][2].state"
        :listDataSelected="FiltersSelected(data['list'][2].state)"
        :relations="
          blnHaveDecision == true
            ? index > 0
              ? listDataFilter[index - 1]['list'][2]['state']
              : ''
            : ''
        "
        :strSelectedValue="listActiveFilters"
        :remove="false"
      />
      <CheckDropdown
        :ref="'Selection' + index"
        class="mb-3"
        v-if="
          data['list'][1].data.length > 3 &&
          data['list'][2].state != 'intCpstatus' &&
          blnOpenFilter == true
        "
        :strName="$t(data['list'][0].name)"
        :listDataSelection="data['list'][1].data"
        :strValueName="data['list'][2].state"
        @selected="FilterToggelSeleted($event)"
        @unselected="FilterToggelUnSeleted($event)"
        :listDataSelected="FiltersSelected(data['list'][2].state)"
        :used="listActiveFiltersSelected.length > 0 ? true : false"
        :listdataTable="listdataTable"
      />
    </template>
    <!-- "{
          select:
            index > 0 ? listDataFilter[index - 1]['list'][2]['state'] : '',
          id: FilterDataSelection(
            index > 0 ? listDataFilter[index - 1]['list'][2]['state'] : '',
            index
          ),
        }" -->
    <!-- <Dropdown /> -->
    <div class="mt-3 d-flex pb-4" :class="btnFix">
      <Button
        @click="appectFilter()"
        :name="$t('36')"
        classBtn="btn-primary me-3 btn-fix"
      />
      <Button
        @click="onCancel()"
        :name="$t('35')"
        classBtn="btn-cancel text-primary btn-fix"
      />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Select from "../input/selectDropdown.vue";
import Button from "../button/button.vue";
import CheckDropdown from "../input/checkDropdown.vue";
export default {
  data() {
    return {
      listActiveFilters: [],
      blnCheckSelection: [],
      SelectedValue: [],
    };
  },
  emits: ["appectFilter", "Cancel"],
  components: {
    Button,
    Select,
    CheckDropdown,
  },
  mounted() {
  },
  computed: {
    listDataFilterProcess() {
      //  if( this.blnOpenFilter
      return this.listDataFilter;
    },
  },
  methods: {
    //เพิ่มเงื่อนไขในการ filter
    addFilter(event, index) {
      //ตรวจสอบว่ามีเงื่อนไขนั้นหรือยัง
      this.listActiveFilters = this.listActiveFilters.filter(function (ele) {
        return ele.state != event.state;
      });

      //ถ้าค่าที่ได้รับไม่เป็น null ให้เพิ่มเงื่อนไขได้
      if (event.id != null || event.id == 0) {
        this.listActiveFilters.push({
          state: event.state,
          id: event.id,
          lang: event.lang,
        });
      }
      let s = "Selection" + index.toString();
      //สั่งแสดงผล
      if (typeof this.$refs[s] !== "undefined") {
        if (this.$refs[s][0] != undefined) {
          if (this.$refs[s][0].relations !== "") {
            this.$refs[s][0].show = true;
          }
        }
      }
    },

    //ตรวจสอบค่าว่าง
    isEmpty(value) {
      return (
        // null or undefined
        value == null ||
        // has length and it's zero
        value.length === 0 ||
        // is an Object and has no keys
        (value.constructor === Object && Object.keys(value).length === 0)
      );
    },
    //return event กลับไปยัง component ต้น
    appectFilter() {
      this.$emit("appectFilter", this.listActiveFilters);
    },

    //ยกเลิกการ filter
    onCancel() {
      if (this.isEmpty(this.listActiveFiltersSelected) == true) {
        this.Clear();
        this.$emit("Cancel");
      } else {
        this.$emit("Cancel");
      }
    },

    //reset filter
    Clear() {
      for (let [key, id] of Object.entries(this.$refs)) {
        if (/^Selection/.test(key)) {
          if (id[0] !== undefined) {
            id[0].reset();
          }
        }
      }
    },

    //filter แบบ checkbox เลือกค่า
    FilterToggelSeleted(event) {
      // this.listActiveFilters = this.listActiveFilters.filter(function (ele) {
      //   if (ele.state != event.state && ele.id != event.id) {
      //     return true;
      //   }
      // });
      this.listActiveFilters = this.listActiveFilters.filter(function (ele) {
        if (ele.state != event.state) {
          return true;
        } else if (ele.state == event.state && ele.id != event.id) {
          return true;
        }
      });

      if (event.id != null || event.id == 0) {
        this.listActiveFilters.push({
          state: event.state,
          id: event.id,
          lang: event.lang,
        });
      }
    },
    //filter แบบ checkbox ยกเลิกการเลือกค่า
    FilterToggelUnSeleted(event) {
      this.listActiveFilters = Object.values(this.listActiveFilters).filter(
        function (ele) {
          if (ele.state != event.state) {
            return true;
          } else if (ele.state == event.state && ele.id != event.id) {
            return true;
          }
        }
      );
    },
    //ส่งข้อมูลlist สำหรับ dropdown ตัวนั้นๆ
    FiltersSelected(state) {
      var selected = Object.values(this.listActiveFiltersSelected).filter(
        function (ele) {
          return ele.state == state;
        }
      );

      return selected;
    },
  },

  props: {
    btnFix: { type: String, default: "" },
    classFilter: { type: String, default: "" },
    FilterDown: { type: String },
    listDataFilter: [],
    listActiveFiltersSelected: [],
    blnHaveDecision: { type: Boolean },
    blnOpenFilter: { type: Boolean, default: false },
    listdataTable: { type: Array },
  },
};
</script>

<style lang="scss" scoped>
// .filter-container{
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 9999;
// }
.filter {
  width: 415px;
  z-index: 1;
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
}
.filter-fix {
  max-height: 400px !important;
  overflow-y: scroll !important;
}
.rotateFilter {
  transform: rotate(45deg) !important;
  transition: 0.2s;
}

.btn-sm {
  width: 77px;
}
// .overlay {
//   background-color: rgba(0, 0, 0, 0.263);
//   width: 100%;
//   height: 100%;
//   z-index: 10;
// }
.btn-group-fix {
  position: sticky !important;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 99 !important;
  background-color: $white !important;
}
</style>
