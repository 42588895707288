<template>
  <div class="popup-container align-items-center">
    <transition name="zoom">
      <div
        class="flex-column d-flex align-items-start justify-content-center alertpop bg-white p-5 rounded-3 shadow mx-3"
      >
        <div class="text-nowrap scroll-load">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="fnt-body" style="width: 0">
                  <div
                    class="d-flex align-items-center justify-content-center bg-white"
                  >
                    <label
                      class="fnt-body fnt-bold text-black-500 ms-1"
                      style="margin-bottom: 1.55px !important"
                    >
                    </label>
                  </div>
                </th>
                <th scope="col" class="fnt-body" style="width: 0">
                  <div
                    class="d-flex align-items-center justify-content-center bg-white"
                  >
                    <label
                      class="fnt-body fnt-bold text-black-500 ms-1"
                      style="margin-bottom: 1.55px !important"
                    >
                      {{ $t("18") }}
                    </label>
                  </div>
                </th>

                <template
                  v-for="(itemsState, indexState) in listDataField"
                  :key="indexState"
                >
                  <th scope="col" class="fnt-body" style="width: 0">
                    <div
                      class="d-flex align-items-center justify-content-start bg-white"
                    >
                      <label
                        class="fnt-body fnt-bold text-black-500 ms-1"
                        style="margin-bottom: 1.55px !important"
                      >
                        {{ $t(itemsState.head) }}
                      </label>
                    </div>
                  </th>
                </template>
                <th scope="col" class="fnt-body" style="width: 0">
                  <div
                    class="d-flex align-items-center justify-content-start bg-white"
                  >
                    <label
                      class="fnt-body fnt-bold text-black-500 ms-1"
                      style="margin-bottom: 1.55px !important"
                    >
                    </label>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody
              class="border-0"
              v-if="listDataTable != null || listDataTable != ''"
            >
              <tr
                v-for="(items, index) in listDataTable"
                :key="index"
                class="border-bottom"
              >
                <th>
                  <div
                    class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1 form-check"
                  >
                    <radio
                      class=""
                      inputname="radioUsertype"
                      :usechecked="true"
                      @change="ChangeSelectValue(items)"
                    />
                  </div>
                </th>
                <th>
                  <div
                    class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                  >
                    {{ parseInt(index) + 1 }}
                  </div>
                </th>

                <td
                  v-for="(itemsState, indexState) in listDataField"
                  :key="indexState"
                  class="fnt-regular fnt-body"
                >
                  <div class="d-flex align-items-center mt-1">
                    {{ items[itemsState.field] }}
                  </div>
                </td>
                {{
                  items.intMemberID
                }}
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-end mt-lg-3 mt-sm-2 w-100">
          <Button
            :name="strButtonSubmit == '' ? $t('36') : $t(strButtonSubmit)"
            @click="confirm()"
            class="btn-fix btn-primary me-3"
            :class="classOkBtn"
          />

          <Button
            aria-label="Close"
            :name="$t('35')"
            @click="cancel()"
            class="btn-fix btn-cancel"
            :class="classCancelBtn"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Button from "@/components/button/button.vue";
import radio from "@/components/input/radio.vue";
export default {
  components: {
    Button,
    radio,
  },
  data() {
    return {
      intSelectValue: null,
    };
  },
  props: {
    Cancel: { type: Boolean, default: true },
    blnError: { type: Boolean, default: false },
    sub: { type: String, default: "" },
    errorMsg: { type: String, default: "" },
    artName: { type: String, default: "" },
    idName: { type: String, default: "" },
    artIcon: { type: String, default: "" },
    altMsgBox: { type: String, default: "" },
    classCancelBtn: { type: String, default: "" },
    classOkBtn: { type: String, default: "" },
    classIcon: { type: String, default: "" },
    strButtonSubmit: { type: String, default: "" },
    strButtonCancel: { type: String, default: "" },
    type: { type: String, default: "" },
    Helper: { type: String, default: "" },
    showpass: { type: Boolean, default: false },
    btnpop: { type: Boolean, default: false },
    classAlertbox: { type: String, default: "" },
    acorgSub: { type: Boolean, default: false },
    subtitleLine1: { type: String, default: "" },
    subtitleLine2: { type: String, default: "" },
    style: { type: String, default: "" },
    listDataTable: { type: Array },
    listDataField: { type: Array },
  },
  emits: ["confirm", "cancel"],
  methods: {
    ChangeSelectValue(index) {
      this.intSelectValue = index.strID;
    },
    cancel() {
      this.$emit("cancel", false);
    },
    confirm() {
      console.log(this.intSelectValue);
      this.$emit("confirm", this.intSelectValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.alertpop {
  width: fit-content !important;
  z-index: 2;
  // position: fixed;
  // top: 70px;
  // left: 50%;
  // transform: translateX(-50%);
  // z-index: 9999;
}
.alert-mobile {
  width: 90% !important;
}
.danger {
  .iconalert {
    fill: $danger !important;
  }
}
.warning {
  .iconalert {
    fill: $warning !important;
  }
}
.success {
  .iconalert {
    fill: $success !important;
  }
}
.overlaying {
  background-color: rgba(0, 0, 0, 0.404);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}
</style>