<template>
  <div
    class="select-dropdown"
    :class="
      error || canChange == false || inactive == true ? 'disable-dropdown' : ''
    "
  >
    <!-- ส่วนของ Dropdown selector -->
    <div
      class="select rounded-2"
      @click="blnDrop = !blnDrop"
      :class="
        blnDrop == true
          ? 'active-dropdown'
          : '' || strResult != ''
          ? 'have-data'
          : ''
      "
    >
      <label
        class="text-black-500"
        for=""
        :class="strResult != '' || blnDrop ? 'move px-2' : ''"
        >{{ $t(strName) }}</label
      >
      <span id="result">{{ $t(strResult) }}</span>
      <Icon
        icon="akar-icons:chevron-down"
        class="arrow-dropdown text-black-500"
        :class="
          blnDrop && canChange == false
            ? 'text-danger'
            : '' || blnDrop
            ? 'icon-dropdown-active'
            : ''
        "
      />
      <Button
        v-if="remove == true"
        icon="CloseOutline"
        class="arrow-dropdown me-4 close"
        classBtnIcon="fill-black-500"
        :class="strResult !== '' && strResult !== null ? '' : 'd-none'"
        @click="removeSelect"
      />
    </div>
    <!-- Dropdown list -->
    <div
      class="items bg-white w-100"
      style="z-index: 999"
      v-show="blnDrop == true && canChange == true"
      v-if="inactive == true ? blnDrop == false : blnDrop == true"
      :id="intId"
    >
      <div
        class="item d-flex align-items-center"
        v-for="(op, index) in filterListDataSelection()"
        :key="index"
        @click="onSeletction(op)"
      >
        {{ $t(op["lang"]) }}
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-start text-danger error-msg"
    >
      <CloseFill width="16" height="16" class="fill-danger me-1" />
      {{ msgError }}
    </div>
  </div>
</template>

<script>
import { CloseFill } from "@/components/icon/icons";
import { Icon } from "@iconify/vue";
import Button from "@/components/button/button.vue";
export default {
  data() {
    return {
      blnDrop: false,
      strResult: "",
      show: true,
      SelectedValue: [],
      inactive: false,
    };
  },
  emits: ["selected", "input"],
  props: {
    canChange: { type: Boolean, default: true },
    remove: { type: Boolean, default: true },
    strName: { type: String, default: "" },
    listDataSelection: { type: Array },
    intId: { type: Number },
    strValueName: { type: String, default: "" },
    listDataSelected: { tpye: Array },
    strSelectedValue: { type: Array },
    relations: { type: String, default: "" },
    value: { type: String, default: "" },
    error: { type: String, default: "" }, // ใช้ Class "error" เพื่อบอกว่า field นี้ error
    msgError: { type: String, default: "" },
  },
  components: {
    Icon,
    Button,
    CloseFill,
  },

  methods: {
    removeSelect() {
      this.strResult = "";
      // console.log("Yes");
    },
    FilterDataSelection(relations) {
      let selected = "";
      if (relations == null) {
        return selected;
      }
      if (relations != null) {
        for (let { state, id } of this.strSelectedValue) {
          if (state == relations) {
            selected = id;

            return selected;
          }
        }
      }
    },
    filterListDataSelection() {
      var select = this.FilterDataSelection(this.relations);
      // console.log(select);
      if (typeof select !== "undefined") {
        let filterList = [];

        filterList = this.listDataSelection.filter((elem) => {
          return elem[this.relations] == select;
        });

        if (this.show == true) {
          this.filterSelection(filterList);
        }
        if (filterList.length != 0) {
          return filterList;
        }
      } else if (typeof select === "undefined" || typeof select === "string") {
        let filterList = this.listDataSelection.filter((elem) => {
          return elem[this.relations] == "" || elem[this.relations] == null;
        });
        // console.log(filterList);
        if (filterList.length != 0) {
          if (this.show == true) {
            this.filterSelection(filterList);
          }
          return filterList;
        }
      }
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.blnDrop = false;
      }
    },
    onClickOutside() {
      if (this.blnDrop == true) {
        this.blnDrop = false;
      }
    },
    onSeletction(select) {
      this.strResult = select.lang;
      this.blnDrop = false;
      this.$emit("selected", {
        state: this.strValueName,
        id: select["id"],
        lang: select["lang"],
      });
      this.$emit("input", {
        state: this.strValueName,
        id: select["id"],
        lang: select["lang"],
      });
    },

    reset() {
      this.strResult = this.listDataSelection[0]["lang"];
      this.blnDrop = false;
      this.$emit("selected", {
        state: this.strValueName,
        id: this.listDataSelection[0]["id"],
        lang: this.listDataSelection[0]["lang"],
      });
      this.$emit("input", {
        state: this.strValueName,
        id: this.listDataSelection[0]["id"],
        lang: this.listDataSelection[0]["lang"],
      });
    },
    filterSelection(selected) {
      // console.log(selected);
      // console.log(this.value);
      if (selected.length == 1) {
        this.strResult = selected[0]["lang"];
        this.$emit("selected", {
          state: this.strValueName,
          id: selected[0]["id"],
          lang: selected[0]["lang"],
        });
        this.$emit("input", {
          state: this.strValueName,
          id: selected[0]["id"],
          lang: selected[0]["lang"],
        });
      }

      this.show = false;
    },
    async process() {
      if (this.value !== null || this.value !== undefined) {
        // console.log(this.listDataSelection);
        var select = await this.listDataSelection.filter((ele) => {
          // console.log(ele);
          return ele.id == this.value;
        });
        // console.log(select);
        if (select.length != 0) {
          this.strResult = select[0]["lang"];

          this.$emit("selected", {
            state: this.strValueName,
            id: select[0]["id"],
            lang: select[0]["lang"],
          });
          this.$emit("input", {
            state: this.strValueName,
            id: select[0]["id"],
            lang: select[0]["lang"],
          });
        }
      }
      //  document.addEventListener("click",this.closeDrop)
      setTimeout(() => {
        if (this.listDataSelected != undefined) {
          if (this.listDataSelected.length == 0) {
            this.strResult = this.listDataSelection[0]["lang"];
            this.$emit("selected", {
              state: this.strValueName,
              id: this.listDataSelection[0]["id"],
              lang: this.listDataSelection[0]["lang"],
            });
            this.$emit("input", {
              state: this.strValueName,
              id: this.listDataSelection[0]["id"],
              lang: this.listDataSelection[0]["lang"],
            });
          } else if (
            this.listDataSelected.length != 0 &&
            this.listDataSelected[0] != undefined
          ) {
            this.strResult =
              this.listDataSelected[0] !== undefined
                ? this.listDataSelected[0]["lang"]
                : "";
            this.$emit("selected", {
              state: this.listDataSelected[0]["state"],
              id: this.listDataSelected[0]["id"],
              lang: this.listDataSelected[0]["lang"],
            });
            this.$emit("input", {
              state: this.listDataSelected[0]["state"],
              id: this.listDataSelected[0]["id"],
              lang: this.listDataSelected[0]["lang"],
            });
          }
        }
      }, 10);
    },
  },

  unmounted() {
    document.removeEventListener("click", this.close);
    this.process();
  },
  mounted() {
    setTimeout(() => {
      this.process();
    }, 10);

    document.addEventListener("click", this.close);
    // console.log(this.value);
  },
};
</script>

<style lang="scss" scoped>
.icon-dropdown-active {
  transform: rotate(180deg) translateY(50%) !important ;
  top: 50%;
}
.disabled {
  position: relative;
  width: 100%;
  user-select: none;
  background-color: $black-200;
  border: solid 1px $black-500 !important;
}
.error {
  .error-msg {
    display: flex;
    svg {
      display: block !important;
    }
  }
  .select {
    border: solid 1px $danger !important;
    label {
      color: $danger !important;
    }
    .items {
      border-radius: 4px;
      border: solid 1px $black-400;
      height: fit-content;
      position: absolute;
      z-index: 10;
      top: 0% !important;
    }
  }
}
.move {
  top: -16% !important;
  transform: translateY(-16%) !important;
  font-size: 14px;
  transition: 0.15s !important;
  left: 8px !important;
  &:hover {
    color: $primary;
  }
}
.active-dropdown {
  border: solid 1px $primary !important;
  label {
    color: $primary;
  }
}
// ใช้คลาสนี้เพื่อปิด Dropdown
.disable-dropdown {
  background-color: $black-200 !important;
  pointer-events: none;
  &:hover {
    .select {
      .close {
        opacity: 0 !important;
      }
      .move {
        color: $black-500 !important;
      }
    }
  }
  .move {
    background-color: $black-200 !important;
    border-radius: 4px;
  }
  .active-dropdown {
    border-color: $black-400 !important;
    .move {
      color: $black-500 !important;
    }
    .arrow-dropdown {
      color: $black-400 !important;
    }
    label {
      background-color: $black-200 !important;
      border-radius: 4px !important;
    }
  }
}
.active-dropdown {
  border: solid 1px $primary !important;
  label {
    color: $primary;
  }
}
.have-data {
  &:hover {
    label {
      color: $primary;
    }
  }
}
.rotate-180 {
  transform: translateY(-50%) rotate(-180deg) !important;
  top: 50%;
  transition: 0.3s;
}
.select-dropdown {
  position: relative;
  width: 100%;
  user-select: none;
  cursor: pointer;
  height: 48px;
  &:hover {
    .select {
      .close {
        opacity: 1;
      }
    }
  }
  .error-msg {
    display: none;
    svg {
      display: none;
    }
  }
  .items {
    border-radius: 4px;
    border: solid 1px $black-400;
    height: fit-content;
    position: absolute;
    z-index: 10;
    top: 110%;
    .item {
      height: 48px;
      padding: 16px;
      &:hover {
        background-color: $black-300;
        cursor: pointer;
      }
    }
  }
  .select {
    border: solid 1px $black-500;
    height: 48px;
    width: 100%;
    position: relative;
    .close {
      opacity: 0;
    }
    label {
      // padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      padding: 2px 0;
      transition: 0.15s;
      background-color: $white;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
    .arrow-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 0.15s;
    }
    &:hover {
      border: solid 1px $primary;
    }
  }
}
.active-list {
  background-color: $black-300;
}
</style>
