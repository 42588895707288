<template>
  <div
    class="
      list-group-item
      mb-2
      rounded-2
      border
      d-flex
      justify-content-between
      align-items-center
      bg-black-200
      p-sm-2
    "
    :class="device == 'mobile' ? 'list-mobile':''"
    :style="device == 'mobile' ? 'margin-bottom:4px !important;':''"
  >
    <div class="d-flex align-items-center">
      <button class="btn p-0 m-0" disabled>
        <moveLeft class="fill-black-400 me-2" width="18" height="18" />
      </button>
      <div
        class="d-flex align-items-center fnt-regular fnt-subtitle"
        :style="device == 'mobile' ? 'font-size:10px !important;' : ''"
      >
        <label for="">{{ name }}</label>
      </div>
    </div>
    <button class="btn p-0 m-0">
      <Drag width="18" height="18" class="fill-secondary" />
    </button>
  </div>
</template>

<script>
import { moveLeft, Drag } from "@/components/icon/index";
export default {
  components: {
    moveLeft,
    Drag,
  },
  props: {
    name: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.list-mobile {
  padding: 4px !important;
}
</style>