<template>
  <div class="px-5 pt-6 pb-5 bg-white rounded-2 shadow-sm form-sm">
    <Label :label="$t(label)" class="mb-4" />
    <div class="p-3 rounded-2 bg-black-100">
      <div
        class="mb-3"
        v-for="(items, index) in listRelate.module"
        :key="index"
      >
        <div class="head d-flex align-items-center">
          <div class="circle me-2"></div>
          <span class="fnt-title fnt-regular text-primary">{{
            $t(items.moduleName)
          }}</span>
        </div>
        <div class="d-flex flex-column align-items-start">
          <div class="px-2" style="height: 8px"></div>
          <div
            class="sub"
            v-for="(item, index) in items.subModuleName"
            :key="index"
          >
            <div
              class="d-flex align-items-center justify-content-center pe-3 text-nowrap"
            >
              <div class="line"></div>
              <span class="ms-5 text-primary">{{ $t(item) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Label from "@/components/label/label.vue";
  export default {
    data() {
      return {
        //   listRelate: [
        //     {
        //       name: "Admin system",
        //       sub: [
        //         { name: "Department" },
        //         { name: "Academics" },
        //         { name: "User information" },
        //       ],
        //     },
        //   ],
      };
    },
    props: {
      listRelate: { type: Object },
      label:{type: String, default:"343"},
    },
    components: {
      Label,
    },
  };
</script>

<style lang="scss" scoped>
.circle {
  width: 10px;
  height: 10px;
  background-color: $primary;
  border-radius: 50%;
}
.sub {
  margin-top: -2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  .line {
    width: 1px;
    height: 28px;
    background-color: $primary-300;
    position: relative;
    margin-left: 2px;
    &::after {
      content: "";
      position: absolute;
      width: 24px;
      height: 1px;
      background-color: $primary-300;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
  }
  &:not(:first-child){
    margin-top:-2px;
  }
  &:last-child {
    span {
      padding-top: 8px;
    }
    margin-top: -9px !important;
    .line {
      height: 18px !important;
      &::after {
        content: "";
        top: 100%;
      }
    }
  }
}
</style>