const apiusergroup = {
  GetUserGroupList: "/v1/api/AdsUserGroup/UserGroupList",
  InsertUserGroup: "/v1/api/AdsUserGroup/InsertUserGroup",
  GetUserGroupbyID: "/v1/api/AdsUserGroup/UserGroupByID/",
  UpdateUserGroup: "/v1/api/AdsUserGroup/UpdateUserGroup/",
  DeleteUserGroup: "/v1/api/AdsUserGroup/DeleteUserGroup/",
  GetUserLink: "/v1/api/AdsUserGroup/UserLink/",
  GetUserFieldLing: "/v1/api/AdsUserGroup/LoadUserFieldRole/",
  InsertUserGroupLink: "/v1/api/AdsUserGroup/InsertUserGroupLink",
  SearchUser: "/v1/api/AdsUserGroup/GetUserInfoList?searchkeyword=",
  InsertMemberAccess: "/v1/api/AdsUserGroup/InsertMemberAccess/",
};
export default apiusergroup;

