<template>
  <div class="select-dropdown bg-white">
    <div
      class="select rounded-2"
      @click="blnDrop = !blnDrop"
      :class="
        blnDrop || valueResult.length != 0 || closed ? 'active-dropdown' : ''
      "
    >
      <label
        for=""
        class="text-black-500"
        :class="valueResult.length != 0 || blnDrop ? 'move' : ''"
      >
        {{ strName }}
      </label>

      <span
        id="result"
        :class="device == 'mobile' ? 'w-75 text-truncate' : ''"
        >{{ convertValueName() }}</span
      >
      <Icon icon="akar-icons:chevron-down" class="arrow-dropdown" />
    </div>
    <div class="items bg-white w-100 p-4 pb-3" v-show="blnDrop" :id="intId">
      <div
        class="form-check mb-3"
        v-for="(item, index) in listDataSelection"
        :key="index"
      >
        <input
          class="form-check-input check-item"
          type="checkbox"
          :value="$t(item['lang'])"
          :id="$t(item['lang'])"
          @change="onSeletction($event, item)"
          :ref="'option' + index"
          :checked="showChecked(item['lang'])"
        />
        <label class="form-check-label" :for="$t(item['lang'])">
          {{ $t(item["lang"]) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
export default {
  data() {
    return {
      blnDrop: false,
      strResult: "",
      valueResult: [],
    };
  },
  components: {
    Icon,
  },
  emits: ["unselected", "selected"],
  props: {
    strName: { type: String },
    listDataSelection: { type: Array },
    listDataSelected: { tpye: Array },
    strSelectedValue: { type: Array },
    strValueName: { type: String },
    used: { type: Boolean, default: false },
    listdataTable: { type: Array },
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },

  //เริ่มต้น
  mounted() {
    document.addEventListener("click", this.close);
    setTimeout(() => {
      //ยังไม่มีการใช้งาน
      if (this.used == false) {
        this.valueResult = [];

        //หาค่าที่มีการใช้งาน
        for (let i in this.listDataSelection) {
          if (this.listDataSelection[i]["id"] != null) {
            this.$refs["option0"][0].checked = true;
            this.valueResult.push(this.listDataSelection[i]["lang"]);
            this.$emit("selected", {
              state: this.strValueName,
              id: this.listDataSelection[i]["id"],
              lang: this.listDataSelection[i]["lang"],
            });
          }
        }
      }

      //มีการใช้งานอยุ่แล้ว
      if (this.used == true) {
        this.valueResult = [];
        // if (this.listDataSelected.length == 0) {
        //   for (var i in this.listDataSelection) {
        //     if (this.listDataSelection[i]["id"] != null) {
        //       this.valueResult.push(this.listDataSelection[i]["lang"]);
        //       this.$emit("selected", {
        //         state: this.strValueName,
        //         id: this.listDataSelection[i]["id"],
        //         lang: this.listDataSelection[i]["lang"],
        //       });
        //     }
        //   }
        // } else
        if (this.listDataSelected.length != 0) {
          for (let i in this.listDataSelected) {
            if (this.listDataSelected[i]["id"] != null) {
              this.valueResult.push(this.listDataSelected[i]["lang"]);
              this.$emit("selected", {
                state: this.strValueName,
                id: this.listDataSelected[i]["id"],
                lang: this.listDataSelected[i]["lang"],
              });
            }
          }
        }
      }

      setTimeout(() => {
        // console.log(this.listdataTable)
        if(this.listdataTable != undefined){
          if(this.listdataTable.length == 0){
            this.onclear()
          }
        }
      }, 10);
    }, 10);
  },
  methods: {
    //โชว์ check
    showChecked(data) {
      if (data !== "79") {
        let dummy = this.valueResult.slice();
        let checking = dummy.filter(function (ele) {
          if (ele == data) {
            return true;
          }
        });

        if (checking.length > 0) {
          return true;
        } else if (checking.length < 0) {
          return false;
        }
      }
      if (this.valueResult.length == this.listDataSelection.length - 1) {
        this.$refs["option0"][0].checked = true;
      }
    },

    //reset ค่า
    reset() {
      for (var i in this.listDataSelection) {
        let s = "option" + i.toString();
        this.$refs[s][0].checked = true;

        if (this.listDataSelection[i]["id"] != null) {
          let data = this.listDataSelection[i]["lang"];

          this.valueResult = this.valueResult.filter(function (ele) {
            return ele != data;
          });
          this.valueResult.push(this.listDataSelection[i]["lang"]);
          this.$emit("selected", {
            state: this.strValueName,
            id: this.listDataSelection[i]["id"],
            lang: this.listDataSelection[i]["lang"],
          });
        }
      }
    },
    check(event) {
      console.log(event.target.checked);
    },
    //ปิด
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.blnDrop = false;
      }
    },

    //กดด้านนอก
    onClickOutside() {
      if (this.blnDrop == true) {
        this.blnDrop = false;
      }
    },

    //เลือก
    onSeletction(event, select) {
      if (event.target.checked == true) {
        if (select["id"] !== null) {
          this.valueResult = this.valueResult.filter(function (ele) {
            return ele != select["lang"];
          });
          this.valueResult.push(select["lang"]);
          this.strResult = select.lang;

          this.$emit("selected", {
            state: this.strValueName,
            id: select["id"],
            lang: select["lang"],
          });
        } else if (select["id"] === null) {
          for (var i in this.listDataSelection) {
            if (this.listDataSelection[i]["id"] != null) {
              this.valueResult = this.valueResult.filter(function (ele) {
                return ele != select["lang"];
              });
              let data = this.listDataSelection[i]["lang"];

              this.valueResult = this.valueResult.filter(function (ele) {
                return ele != data;
              });
              this.valueResult.push(this.listDataSelection[i]["lang"]);
              this.$emit("selected", {
                state: this.strValueName,
                id: this.listDataSelection[i]["id"],
                lang: this.listDataSelection[i]["lang"],
              });
            }
          }
        }
      } else if (event.target.checked == false) {
        if (select["id"] !== null) {
          this.valueResult = this.valueResult.filter(function (ele) {
            return ele != select["lang"];
          });

          this.$emit("unselected", {
            state: this.strValueName,
            id: select["id"],
            lang: select["lang"],
          });
        } else if (select["id"] === null) {
          for (let i in this.listDataSelection) {
            if (this.listDataSelection[i]["id"] !== null) {
              //console.log(this.listDataSelection[i]["lang"]);
              let data = this.listDataSelection[i]["lang"];
              this.$emit("unselected", {
                state: this.strValueName,
                id: this.listDataSelection[i]["id"],
                lang: this.listDataSelection[i]["lang"],
              });

              this.valueResult = this.valueResult.filter(function (ele) {
                return ele != data;
              });
            }
          }
          for (let i in this.listDataSelection) {
            let s = "option" + i.toString();
            this.$refs[s][0].checked = false;
          }
        }
      }

      if (this.valueResult.length != this.listDataSelection.length - 1) {
        this.$refs.option0[0].checked = false;
      } else if (this.valueResult.length == this.listDataSelection.length - 1) {
        for (let i in this.listDataSelection) {
          let s = "option" + i.toString();
          this.$refs[s][0].checked = true;
        }
      }
    },

    //แสดงผล result
    convertValueName() {
      var s = "";
      for (var i in this.valueResult) {
        if (this.valueResult.length < 5) {
          s += this.$t(this.valueResult[i]);
          if (i < this.valueResult.length - 1) {
            s += " , ";
          }
        } else if (this.valueResult.length >= 5) {
          if (i < this.valueResult.length - 1) {
            s += this.$t(this.valueResult[i]);
            s += " , ";
          }
          if (i > this.valueResult.length - 2) {
            s += " ....";
            break;
          }
        }
      }
      // console.log(this.valueResult);

      return s;
    },

    onclear() {
      for (let i in this.listDataSelection) {
        if (this.listDataSelection[i]["id"] !== null) {
          //console.log(this.listDataSelection[i]["lang"]);
          let data = this.listDataSelection[i]["lang"];
          this.$emit("unselected", {
            state: this.strValueName,
            id: this.listDataSelection[i]["id"],
            lang: this.listDataSelection[i]["lang"],
          });

          this.valueResult = this.valueResult.filter(function (ele) {
            return ele != data;
          });
        }
      }
      for (let i in this.listDataSelection) {
        let s = "option" + i.toString();
        this.$refs[s][0].checked = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.move {
  top: -16% !important;
  transform: translateY(-16%) !important;
  font-size: 14px;
  transition: 0.15s !important;
  left: 8px !important;
  color: $primary;
}
.active-dropdown {
  border-color: $primary !important;
}
.rotate-180 {
  transform: translateY(-50%) rotate(-180deg) !important;
  top: 50%;
}
.select-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
  .items {
    border-radius: 4px;
    border: solid 1px $black-400;
    height: fit-content;
    position: relative;
    z-index: 10;
    top: 110%;
    margin-top: 8px;
    .item {
      height: 48px;
      padding: 16px;
      &:hover {
        background-color: $black-300;
        cursor: pointer;
      }
    }
  }
  .select {
    border: solid 1px $black-400;
    height: 48px;
    width: 100%;
    position: relative;
    label {
      // padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      padding: 2px 8px;
      transition: 0.15s;
      background-color: $white;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
    }
    .arrow-dropdown {
      padding: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      transition: 0.15s;
    }
  }
}
.active-list {
  background-color: $black-300;
}
.check-item {
  width: 16px !important;
  height: 16px !important;
  border-radius: 2px !important;
  &:focus {
    border: solid 1px $primary;
  }
}
</style>