<template>
  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('60')"
        :Helper="$t('345')"
        @confirm="fetchDeleteUserRoleGroup($event)"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t('357', { number: intPinCount - intWrongLogin })"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin < intPinCount"
        @click="ConfirmDelete()"
      ></div>
    </transition>
  </div>

  <div
    class="popup-container align-items-center"
    v-show="blnPopupUserConfirmDelete == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="
          blnPopupUserConfirmDelete == true && intWrongLogin < intPinCount
        "
        type="Danger"
        classOkBtn="btn-danger"
        artIcon="deleteFill"
        :altMsgBox="$t('60')"
        :Helper="$t('345')"
        @confirm="fetchDeleteUserOfUserRoleGroup($event)"
        ref="AlertPopup"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-danger"
        :errorMsg="$t('357', { number: intPinCount - intWrongLogin })"
        :blnError="blnError"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="
          blnPopupUserConfirmDelete == true && intWrongLogin < intPinCount
        "
        @click="confirmDeleteUser()"
      ></div>
    </transition>
  </div>

  <div
    class="popup-container align-items-center"
    v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('356', { number: intPinCount, phoneno: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage($event)"
        ref="AlertPopupp"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnPopupConfirmDelete == true && intWrongLogin >= 3"
      ></div>
    </transition>
  </div>
  <AltStatus
    :blnalert="blnAltStatus"
    icon="CorrectFill"
    coloricon="fill-success"
    class="success"
    :name="$t(strSMSGsuccess)"
    :class="blnAltStatus ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnAltStatusunsucess"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('174')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltStatusunsucess ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnAltLoadingData"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('172')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnAltLoadingData ? 'show' : ''"
  />

  <AltStatus
    :blnalert="blnnotdelete"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('428')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotdelete ? 'show' : ''"
  />
  <AltStatus
    :blnalert="blnnotedit"
    icon="IncorrectFill"
    coloricon="fill-danger"
    class="error"
    :name="$t('222')"
    @mousemove="hover = true"
    @mouseleave="hover = false"
    :class="blnnotedit ? 'show' : ''"
  />
  <!-- </div> -->
  <Header
    icon="campusOutline"
    :headerName="
      $t(
        strMode == 'UserRoleGroup' || strMode == 'UserGroup'
          ? 'UserRoleGroup'
          : strMode == 'UserLink'
          ? $i18n.locale == 'en'
            ? this.strRoleGroupName[0].strRoleGroupNameEN
            : this.strRoleGroupName[0].strRoleGroupNameTH
          : '529'
      )
    "
    :headerNamebreadcrumb="strMode == 'UserLink' ? $t('UserRoleGroup') : ''"
    :breadcrumbname="
      strMode == 'UserRoleGroup' || strMode == 'UserGroup'
        ? action == 'Insert'
          ? $t('Add User role group')
          : '' || action == 'Update'
          ? $t('Edit User role group')
          : ''
        : lstUserFieldLink.length == 0
        ? blnopenmember == true
          ? $i18n.locale == 'en'
            ? this.strRoleGroupName[0].strRoleGroupNameEN
            : this.strRoleGroupName[0].strRoleGroupNameTH
          : $t('564')
        : '' || lstUserFieldLink.length > 0
        ? $t('565')
        : strMode == 'UserLink'
        ? action == 'Insert'
          ? blnOpenAddmember == true
            ? $i18n.locale == 'en'
              ? this.strRoleGroupName[0].strRoleGroupNameEN
              : this.strRoleGroupName[0].strRoleGroupNameTH
            : $t('719', {
                fieldname:
                  $i18n.locale == 'en'
                    ? this.strRoleGroupName[0].strRoleGroupNameEN
                    : this.strRoleGroupName[0].strRoleGroupNameTH,
              })
          : '' || action == 'Update'
          ? $t('720', {
              fieldname:
                $i18n.locale == 'en'
                  ? this.strRoleGroupName[0].strRoleGroupNameEN
                  : this.strRoleGroupName[0].strRoleGroupNameTH,
            })
          : ''
        : ''
    "
    :btnName="
      $t(
        strMode == 'UserRoleGroup' || strMode == 'UserGroup'
          ? 'Add User role group'
          : 'Add Group access control User role group access control'
      )
    "
    class="mb-3"
    @onInput="openform($event)"
    :breadcrumb="
      blnOpenform == true || blnUserRoleGroupForm == true || blnShowUserLink
        ? ''
        : 'd-none'
    "
    :helperHeader="
      blnOpenform == true
        ? $t('Please enter user role group information')
        : blnUserRoleGroupForm == true
        ? $t('526')
        : blnShowUserLink == true
        ? $t(blnOpenAddmember == true ? '721' : '')
        : ''
    "
    :hintHelperHeader="
      blnOpenform == true ||
      blnUserRoleGroupForm == true ||
      blnShowUserLink == true
        ? ''
        : 'd-none'
    "
    :rightHeader="
      blnOpenform == true ||
      blnUserRoleGroupForm == true ||
      blnShowUserLink == true
        ? 'd-none'
        : ''
    "
    :underline="
      blnOpenform == true ||
      blnUserRoleGroupForm == true ||
      blnShowUserLink == true
        ? 'openUnderline'
        : 'closeUnderline'
    "
    @showFilter="openFilter($event)"
    id="header"
    ListCampusClass="d-none"
    :Searchname="'Search UserRoleGroup'"
    @onSearch="SearchUserGroup($event)"
    @onClean="clean()"
    :blnOpenform="
      blnOpenform == true || blnUserRoleGroupForm == true ? true : false
    "
    :showFilter="blnOpenFilter"
    :filterActive="{
      blnOpenform: blnOpenFilter,
      useFilter: activeFiltersSelected.length == 0 ? false : true,
      disableFilter: true,
    }"
    :tooltipPlacement="
      device == 'mobile' ? 'tooltip-top-mobile' : 'tooltip-top'
    "
    ref="header"
  />
  <div class="popup-container" v-show="blnOpenFilter == true">
    <transition name="zoom">
      <Filter
        v-show="blnOpenFilter == true"
        @appectFilter="filteredData($event)"
        :listDataFilter="listcampusFilter"
        @Cancel="blnOpenFilter = !blnOpenFilter"
        :blnOpenFilter="blnOpenFilter"
        :listActiveFiltersSelected="activeFiltersSelected"
        :blnHaveDecision="true"
        ref="filter"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="blnOpenFilter == true"
        @click="blnOpenFilter = false"
      ></div>
    </transition>
  </div>
  <div
    v-show="
      ListUserRoleGroup.dataFields != null &&
      blnUserRoleGroupForm == false &&
      blnShowUserLink == false
    "
  >
    <Table
      :progressScore="progress"
      :nametable="'2'"
      v-if="!blnOpenform"
      :ListDataTable="ListUserRoleGroup"
      firstSort="intCpstatus"
      secondSort="strCampusNameEN"
      @edit="fetchGetUserRoleGroupByID($event)"
      @ConfigDisplay="reloadConfigDisplay()"
      @onSaveConfigDisplay="onSaveConfigDisplay($event)"
      @EditUserList="fetchGetUserLink($event, 1, 20)"
      :blnEditUserRoleGroup="true"
      ref="table"
    />
  </div>

  <div
    v-if="
      lstUserLink.length != 0 &&
      blnShowUserLink == true &&
      strMode == 'UserLink'
    "
  >
    <div
      :class="blnOpenAddmember == false ? '' : 'd-none'"
      class="p-5 rounded-3 shadow-sm config-display bg-white m-sm-4"
      :style="
        device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
      "
    >
      <div
        :class="
          device == 'mobile'
            ? 'row g-0' + ' ' + rightHeader
            : 'd-flex align-items-center justify-content-end right-header' +
              ' ' +
              rightHeader
        "
      >
        <div
          :class="
            device == 'mobile' ? 'col-8' : 'me-lg-4 my-lg-0 my-sm-3 me-sm-1'
          "
        >
          <InputSearch
            :hint="Searchname"
            :class="searchHeader"
            @onSearch="search($event)"
            @onClean="clean()"
            mode="auto"
            ref="inputSearch"
          />
        </div>
        <button @click="OpenFilter" class="used btn">
          <BIconFilterOutline class="fill-primary" width="24" height="24" />

          <div class="red-dot"></div>
        </button>
        <!-- Filter -->
        <div
          :class="
            device == 'mobile' ? 'col-2' + ' ' + addHeader : ' ' + addHeader
          "
          class="ms-lg-3 ms-sm-1"
        >
          <router-link
            @click="
              () => {
                blnOpenAddmember = true;
              }
            "
            to=""
            class="btn btn-primary fnt-regular fnt-subtitle"
          >
            <Icon
              icon="fluent:add-24-filled"
              :class="device == 'mobile' || device == 'tablet' ? '' : 'me-2'"
            />
            <span
              :class="
                device == 'mobile'
                  ? 'd-none'
                  : '' && device == 'tablet'
                  ? 'd-flex'
                  : ''
              "
            >
              {{ $t("651") }}</span
            >
          </router-link>
        </div>
      </div>
      <Table
        :progressScore="progresstableGroupAccess"
        :nametable="'Group Access Member'"
        v-if="!blnOpenform"
        :ListDataTable="lstUserLink"
        firstSort="strFirstNameTH"
        secondSort="strFirstNameEN"
        @onLoadData="
          ($event) => {
            fetchGetUserLink(null, $event.page, $event.perpage);
          }
        "
        @edit="fetchGetUserRoleGroupByID($event)"
        @ConfigDisplay="reloadConfigDisplayMember()"
        @onSaveConfigDisplay="onSaveConfigDisplayMember($event)"
        @EditUserList="fetchGetUserLink($event, 1, 20)"
        :blnEditUserGroup="false"
        :blnPaginationOfFont="true"
        :blnUseDeleteButton="true"
        ref="tableGroupAccess"
        @delete="confirmDeleteUser($event)"
      />
    </div>

    <Popuptable
      v-if="blnOpenPopupUserRole == true"
      :listDataField="ListUserGroup.dataFields"
      :listDataTable="ListUserGroup.data"
      @cancel="
        () => {
          blnOpenPopupUserRole = false;
        }
      "
      @confirm="GetUserGroupByID($event)"
    />
    <!-- form Addmember -->
    <div
      :class="blnOpenAddmember == true ? '' : 'd-none'"
      ref="AddMember"
      class="p-5 rounded-3 shadow-sm bg-white m-sm-4"
      :style="
        device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
      "
    >
      <div
        :class="
          device == 'mobile' ? 'col-8' : 'me-lg-4 my-lg-0 my-sm-3 me-sm-1'
        "
      >
        <!-- <div class="mb-3">
          <span
            class="fnt-regular fnt-subtitle text-secondary back-icon"
            @click="
              () => {
                blnOpenAddmember = false;
              }
            "
          >
            <Icon
              icon="ph:arrow-left-duotone"
              color="#f3933b"
              width="24"
              height="24"
            />
            {{
              $i18n.locale == "en"
                ? this.strRoleGroupName[0].strRoleGroupNameEN
                : this.strRoleGroupName[0].strRoleGroupNameTH
            }}</span
          >
        </div> -->

        <p class="fnt-medium fnt-subtitle mb-4">{{ $t("650") }}</p>
        <div class="mb-4">
          <div class="d-flex mb-3 gap-3">
            <template v-for="item in listradioUsertype" :key="item.id">
              <radio
                class=""
                inputname="radioUsertype"
                :name="$t(item.name)"
                :usechecked="item.id == 0 ? true : false"
                @change="radioUserType(item)"
              />
            </template>
          </div>
          <div class="row">
            <div class="col-12">
              <inputText
                v-if="blnOpenAddmember == true"
                :label="$t('314')"
                ref="inputname"
                @keypress="
                  (e) => {
                    if (e.key == 'Enter') {
                      processSearchUser(strInputname);
                    }
                  }
                "
                @input="
                  (e) => {
                    strInputname = e.target.value;
                  }
                "
                :value="''"
              />
            </div>
          </div>
        </div>
        <Button
          icon="SearchOutline"
          :name="$t('447')"
          classBtnIcon="stroke-white me-2"
          classBtn="btn-fix btn-primary d-flex"
          @input="processSearchUser(strInputname)"
        />
        <hr :class="blnopenmember != false ? 'd-none' : ''" />
        <!-- <InputSearch
            :mode="'enter'"
            :hint="Searchname"
            :class="searchHeader"
            @onSearch="fetchSearchUser($event)"
            @onClean="clean()"
            ref="inputSearch"
          /> -->
      </div>
      <!-- ผลการค้นหา -->
      <Loading1 v-if="progressSearch == 0" />
      <div
        v-if="progressSearch == 100"
        :class="blnopenmember != false ? '' : 'd-none'"
        class="mt-3"
      >
        <p class="fnt-h6 fnt-light">
          <span>{{ $t("312") }}</span>
          <span class="mx-2 fnt-medium">{{ intSearchResult }}</span>
          <span>{{ $t("433") }}</span>
          <span class="fnt-medium">{{ strInputname }}</span>
        </p>
        <hr :class="blnopenmember != false ? '' : 'd-none'" />
        <div
          class="scroll-load text-nowrap"
          @scroll="handleScroll($event)"
          v-if="blnopenmember == true"
        >
          <table class="table" aria-describedby="tableAddmember">
            <thead>
              <tr>
                <th scope="col" class="fnt-body" style="width: 0">
                  <div
                    class="d-flex align-items-center justify-content-center bg-white"
                  >
                    <label
                      class="fnt-body fnt-bold text-black-500 ms-1"
                      style="margin-bottom: 1.55px !important"
                    >
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="theCheckbox"
                          @change="checkAll($event)"
                          ref="checkAll"
                        />
                      </div>
                    </label>
                  </div>
                </th>
                <template
                  v-for="(itemsState, indexState) in selectedUserList"
                  :key="indexState"
                >
                  <th scope="col" class="fnt-body" style="width: 0">
                    <div
                      class="d-flex align-items-center justify-content-start bg-white"
                    >
                      <label
                        class="fnt-body fnt-bold text-black-500"
                        style="margin-bottom: 1.55px !important"
                      >
                        {{ $t(itemsState.head) }}
                      </label>
                    </div>
                  </th>
                </template>
                <th scope="col" class="fnt-body" style="width: 0">
                  <div
                    class="d-flex align-items-center justify-content-start bg-white"
                  >
                    <label
                      class="fnt-body fnt-bold text-black-500 ms-1"
                      style="margin-bottom: 1.55px !important"
                    >
                    </label>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody
              class="border-0"
              v-if="listSortUserData != null || listSortUserData != ''"
            >
              <tr
                v-for="(items, index) in listSortUserDatas"
                :key="index"
                class="border-bottom"
                ref="tr_Checkbox"
              >
                <th>
                  <div
                    class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1 form-check"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="theCheckbox"
                      :checked="blnCheckall"
                      @change="checkSome($event, items['intMemberID'])"
                      ref="checkMySelected"
                    />
                  </div>
                </th>

                <td
                  v-for="(itemsState, indexState) in selectedUserList"
                  :key="indexState"
                  class="fnt-regular fnt-body"
                >
                  <div class="d-flex align-items-center mt-1">
                    {{ items[itemsState.Field] }}
                  </div>
                </td>
                <td class="fnt-regular fnt-body">
                  <div class="d-flex align-items-center mt-1">
                    <!-- <Button
                    icon="AddFill"
                    classBtnIcon="fill-secondary"
                    widthIcon="20"
                    heightIcon="20"
                    classBtn="p-0 m-0"
                    class="pe-3"
                    @click="fetchAddUserAccess(items.intMemberID)"
                  /> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <NotFound
            v-if="listSortUserData == null || listSortUserData == ''"
            :style="
              listSortUserData == null || listSortUserData == ''
                ? 'height:fit-content;'
                : ''
            "
            class="border-bottom w-100 notfound"
            :name="$t('649')"
          />
        </div>

        <div class="my-3">
          <Button
            class="btn-fix btn-primary me-3"
            :name="'36'"
            @click="addToMySelected()"
          />
          <Button
            class="btn-fix btn-cancel"
            :name="'35'"
            @input="clearMySelected()"
          />
        </div>
      </div>

      <!-- รายการที่เลือก -->
      <p class="fnt-medium fnt-subtitle">{{ $t("649") }}</p>
      <!-- <pre>{{ listSortUserDatas }}</pre> -->
      <div class="text-nowrap scroll-load">
        <table class="table" aria-describedby="tableSelected">
          <thead>
            <tr>
              <th scope="col" class="fnt-body" style="width: 0">
                <div
                  class="d-flex align-items-center justify-content-center bg-white"
                >
                  <label
                    class="fnt-body fnt-bold text-black-500 ms-1"
                    style="margin-bottom: 1.55px !important"
                  >
                    {{ $t("18") }}
                  </label>
                </div>
              </th>
              <template
                v-for="(itemsState, indexState) in selectedUserList"
                :key="indexState"
              >
                <th scope="col" class="fnt-body" style="width: 0">
                  <div
                    class="d-flex align-items-center justify-content-start bg-white"
                  >
                    <label
                      class="fnt-body fnt-bold text-black-500 ms-1"
                      style="margin-bottom: 1.55px !important"
                    >
                      {{ $t(itemsState.head) }}
                    </label>
                  </div>
                </th>
              </template>

              <th scope="col" class="fnt-body" style="width: 0">
                <div
                  class="d-flex align-items-center justify-content-start bg-white"
                >
                  <label
                    class="fnt-body fnt-bold text-black-500 ms-1"
                    style="margin-bottom: 1.55px !important"
                  >
                  </label>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            class="border-0"
            v-if="listselecFromcheckbox != null || listselecFromcheckbox != ''"
          >
            <tr
              v-for="(items, index) in listselecFromcheckbox"
              :key="index"
              class="border-bottom"
            >
              <th>
                <div
                  class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
                >
                  {{ parseInt(index) + 1 }}
                </div>
              </th>

              <td
                v-for="(itemsState, indexState) in selectedUserList"
                :key="indexState"
                class="fnt-regular fnt-body"
              >
                <div class="d-flex align-items-center mt-1">
                  {{ items[itemsState.Field] }}
                </div>
              </td>
              <td class="fnt-regular fnt-body">
                <div class="d-flex align-items-center">
                  <Button
                    icon="RemoveFill"
                    classBtnIcon="fill-secondary"
                    widthIcon="20"
                    heightIcon="20"
                    classBtn="p-0 m-0"
                    class="pe-3"
                    @click="removeMyselected(items.intMemberID)"
                  />
                  <!-- <Button
                  icon="RemoveFill"
                  classBtnIcon="fill-secondary"
                  widthIcon="20"
                  heightIcon="20"
                  classBtn="p-0 m-0"
                  class="pe-3"
                  @click="fetchAddUserAccess(items.intMemberID)"
                /> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <NotFound
          v-if="listselecFromcheckbox == null || listselecFromcheckbox == ''"
          :style="
            listselecFromcheckbox == null || listselecFromcheckbox == ''
              ? 'height:fit-content;'
              : ''
          "
          class="border-bottom w-100 notfound"
          :name="$t('649')"
        />
      </div>

      <div
        :class="
          device == 'mobile' ? 'col-2' + ' ' + addHeader : ' ' + addHeader
        "
        class="ms-lg-3 ms-sm-1 text-end mt-3"
      >
        <!-- button -->
        <Button
          class="btn-fix btn-primary me-3"
          :name="'36'"
          @input="addData()"
        />
        <Button
          class="btn-fix btn-cancel"
          :name="'35'"
          @input="
            () => {
              intSearchResult = 0;
              blnCheckall = false;
              blnOpenAddmember = false;
              strInputname = '';
              listSortUserData = [];
              blnopenmember = false;
              listselecFromcheckbox = [];
              DummylistselecFromcheckbox = [];
            }
          "
        />
      </div>
    </div>
  </div>

  <div
    class="d-flex mt-sm-3 ms-lg-9 ms-md-0 m-sm-0 justify-content-start align-items-start"
    v-if="blnOpenform == true"
    :class="
      device == 'mobile' || device == 'tablet'
        ? 'flex-wrap move-to-top-level'
        : ''
    "
  >
    <div
      class="rounded-2 shadow-sm bg-white me-lg-5 me-sm-0 layout"
      :class="device == 'mobile' ? 'mb-5 me-0 w-100' : 'form-lg-fix mb-4'"
    >
      <!-- {{ formdata }} -->
      <Form
        v-if="blnOpenform == true && blnUserRoleGroupForm == false"
        :data="formdata"
        @onInput="openform($event)"
        @Insert="fetchInsertUserRoleGroup($event)"
        @Update="fetchUpdateUserRoleGroup($event)"
        @delete="ConfirmDelete()"
        ref="form"
        :inactive="formInactive"
        :msgalertDataDuplicate="msgalertDataDuplicate"
        :action="action"
        :UserRoleGroupID="intUserRoleGroupID"
      />
    </div>

    <Relate
      :class="device == 'mobile' || device == 'tablet' ? 'mt-0 mb-3 w-100' : ''"
      v-if="
        blnOpenform == true &&
        listRelate.used == true &&
        blnUserRoleGroupForm == false
      "
      :listRelate="listRelate"
      style="width: 350px"
      class="layout"
    />
  </div>
</template>
<script>
import Relate from "@/components/cards/relate.vue";
import Filter from "@/components/filter/filter.vue";
import Header from "@/components/headerMenu/haeder.vue";
import Table from "@/components/table/table.vue";
import Form from "@/components/form/UserRoleGroupForm.vue";
import URLAPI from "@/typescript/urlapi/url.ts";
import apiuserrolegroup from "@/typescript/urlapi/adminsystem/APIUserroleGroup";
import { typeSchema, request } from "@/components/constants/schemaAddCampus";
import axios from "axios";
// import URLAPI from "@/typescript/URLAPI/url";
import AltStatus from "@/components/alert/alertStatus.vue";
import Alert from "@/components/alert/alertPopup.vue";
import AdsInventories from "@/typescript/inventory";
//import SortArray from "../typescript/sort/sorting";
import Enumerable from "linq";
// import apicampus from "@/typescript/URLAPI/adminsystem/apiuserrolegroup";
// import NotFound from "../components/notFound/notFound.vue"

// import UserGroup from "@/components/form/UserGroup/UserGroup.vue";
import Login from "@/typescript/login";
import InputSearch from "@/components/input/inputSearch.vue";
import Button from "@/components/button/button.vue";
import { Icon } from "@iconify/vue";
import inputText from "@/components/input/inputText.vue";
import NotFound from "@/components/notFound/notFound.vue";
import radio from "@/components/input/radio.vue";
// import apiuserrolegroup from "@/typescript/urlapi/adminsystem/APIUserGroup";
import * as AesEncrytion from "@/typescript/AesEncryption";
import Loading1 from "@/components/Skeleton.vue";
import apiusergroup from "@/typescript/urlapi/adminsystem/APIUserGroup";
import Popuptable from "@/components/PopupTable.vue";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";

export default {
  components: {
    Header,
    Table,
    Form,
    Filter,
    AltStatus,
    Relate,
    Alert,
    Button,
    Icon,
    inputText,
    NotFound,
    radio,
    // UserGroup,
    InputSearch,
    Loading1,
    Popuptable,
  },
  data() {
    return {
      blnPopupUserConfirmDelete: false, //Delete User of UserRoleGroup
      blnOpenPopupUserRole: false,
      strMode: "UserRoleGroup",
      lstUserRoleGroupList: { type: Object },
      ListUserGroup: { type: Object },
      blnPopupConfirmDelete: false,
      strSearch: "",
      msgalertDataDuplicate: [], //msg
      action: "Insert", //action of form
      strSMSGsuccess: "",
      blnAltStatus: false, //Alt Status Success
      blnAltStatusunsucess: false, //Alt Status unsuccess
      blnAltLoadingData: false, //Alt Status logint
      blnnotedit: false,
      blnnotdelete: false,
      hover: false, //hover alt
      timerEnabled: false,
      timerCount: 0,
      timerCountHover: 0,
      progress: 0,
      typeSchema: typeSchema,
      request: request,
      formdata: {
        strRoleGroupNameTH: "",
        strRoleGroupNameEN: "",
        intRoleGroupStatus: 0,
        roleModule: [],
      },
      formdataDummy: {},
      campusId: null,
      blnOpenform: false,
      ListUserRoleGroup: {},
      ListDummy: {},
      formInactive: [],
      blnOpenFilter: false,
      blnopenConfigDisplay: false,
      blnError: false,
      intPinCount: 1,
      strTel: "",
      activeFiltersSelected: [],
      listRelate: [],
      updateAdsInventories: null,

      intWrongLogin: 0,
      blnUserRoleGroupForm: false,
      blnShowUserLink: false,
      blnOpenAddmember: false,
      lstUserLink: { type: Array },
      lstUserFieldList: { type: Object },
      lstUserFieldLink: { type: Object },
      lstuserGroupInfo: { type: Array },
      progresstableGroupAccess: 0,
      strSearchField:[
        "strRoleGroupNameEN","strRoleGroupNameTH"
      ],
      strInputname: "",
      listselecFromcheckbox: [],
      DummylistselecFromcheckbox: [],
      blnCheckall: false,
      blnOpenDataMySelected: false,
      intSearchResult: 0,
      strRadioUserType: "Student",
      intRadioUserType: 1,
      listradioUsertype: [
        {
          id: 0,
          name: "450", //นักศึกษา
        },
        {
          id: 1,
          name: "451", //เจ้าหน้าที่
        },
        {
          id: 2,
          name: "452", //บุคคลภายนอก
        },
      ],
      listSortUserData: [],
      SelectedUserList: [],
      blnopenmember: false,
      maxcount: 20,
      mincount: 0,
      progressSearch: 100,
      strRoleGroupName: "",
    };
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCountHover++;
        }, 1000);
      }
    },
    timerCountHover: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCountHover++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
          this.blnAltLoadingData = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount: {
      handler(value) {
        if (value < 10 && this.timerEnabled && this.progress < 75) {
          setTimeout(() => {
            this.timerCount++;

            this.progress += 5 * this.timerCount;
          }, 200);
        } else {
          this.progress = 100;
        }
      },

      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  unmounted() {
    clearInterval(this.updateAdsInventories);
  },
  mounted() {
    console.log(this.state);

    this.updateAdsInventories = setInterval(() => {
      if (AdsInventories.blnCloseform == false) {
        this.openform(false);
        AdsInventories.blnCloseform = true;
      }
    }, 500);
    this.blnOpenFilter = false;
    // setTimeout(() => {
    //   if (localStorage.getItem("LoginState") != "false") {
    //     console.log("logined");
    //     this.$router.push("/systemadmin/campus");
    //   }
    // }, 1000);
  },
  computed: {
    listSortUserDatas() {
      return Object.values(this.listSortUserData).slice(
        this.mincount,
        this.maxcount
      );
    },
    selectedUserList() {
      let select = [];
      console.log(this.$i18n.locale);
      if (this.$i18n.locale == "en" || this.$i18n.locale == "EN") {
        select = this.SelectedUserList.filter((ele) => {
          if (ele.Field != "strCode") {
            return ele.Field.includes("NameEN");
          } else {
            return ele.Field;
          }
        });
      }
      if (this.$i18n.locale == "th" || this.$i18n.locale == "TH") {
        select = this.SelectedUserList.filter((ele) => {
          if (ele.Field != "strCode") {
            return ele.Field.includes("NameTH");
          } else {
            return ele.Field;
          }
        });
      }
      return select;
    },
    // test() {
    //   return this.listSortUserData.filter(e => e.intMemberID);
    // },
  },
  created() {
    this.processLoadingData();
  },
  methods: {
    search(event) {
      // this.strSearch = event;
      console.log(event);
      this.$refs.tableGroupAccess.SearchListDataTable(event);
    },
    async fetchDeleteUserOfUserRoleGroup(event) {
      try {
        if (event == false) {
          this.blnPopupUserConfirmDelete = !this.blnPopupUserConfirmDelete;
        } else {
          if (
            this.$refs.AlertPopup.password != null &&
            this.$refs.AlertPopup.password != ""
          ) {
            var strUsername = AdsInventories.userinfo.strUsername.split("@");
            var reslogin = await Login.login(
              strUsername[0],
              event,
              axios,
              URLAPI
            );
            var login = {
              username: strUsername[0],
              password: event,
            };
            console.log(reslogin);
            if (reslogin.logined == true) {
              this.intPinCount = reslogin.pincode;
              this.strTel = reslogin.tel;
              var res = await axios.put(
                URLAPI.DeleteUserOfUserroleGroup + this.intUserID + login
              );
              console.log(res.data);
              if (res.data.candelete == true) {
                this.blnError = false;
                this.blnPopupUserConfirmDelete = false;
                this.strSMSGsuccess = "28";
                this.blnOpenform = false;
                this.blnAltStatus = true;
                //delay 1 sec
                setTimeout(() => {
                  this.processLoadingData();
                  this.blnAltStatus = false;
                }, 1000);
                // this.$refs.table.checkstateofdelete(true);
              } else if (res.data.candelete == false) {
                this.blnAltStatusunsucess = true;
                this.timerCountHover = 0;
                this.timerEnabled = true;
                this.hover = false;
                this.blnPopupUserConfirmDelete = false;
                // reset PIN หลังจากDelete
                this.$refs.AlertPopup.password = null;
                setTimeout(() => {
                  this.blnAltStatusunsucess = false;
                }, 5000);
              }
            }else {
              if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
            }
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        // this.blnAltLoadingData = true;
        // this.timerCountHover = 0;
        // this.timerEnabled = true;
        // this.hover = false;
        console.log(error);
      }
    },
    OpenPopupUserRole() {
      this.fetchListUserGroup();
    },
    confirmDeleteUser(event) {
      this.blnPopupUserConfirmDelete = !this.blnPopupUserConfirmDelete;
      console.log(event);
    },
    async fetchListUserGroup() {
      this.timerCount = 0;
      this.timerEnabled = true;
      await axios
        .get(URLAPI + apiusergroup.GetUserGroupList)
        .then((res) => {
          this.ListUserGroup = res.data;
          this.blnOpenPopupUserRole = true;
          //เช็คข้อมูลวิทยาเขต ว่าลบได้หรือไม่
        })
        .catch((e) => {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
      return true;
    },
    async fetchAddUserAccess(memberID) {
      try {
        console.log(memberID);
        console.log(
          URLAPI +
            apiuserrolegroup.InsertMemberToUserRoleGroup +
            this.intUserRoleGroupID +
            "?MemberID=" +
            memberID
        );
        var res = await axios.post(
          URLAPI +
            apiuserrolegroup.InsertMemberToUserRoleGroup +
            this.intUserRoleGroupID +
            "?MemberID=" +
            memberID
        );
        console.log(res.data);
        if (res.data.inserted == true) {
          this.listSortUserData = this.listSortUserData.filter((ele) => {
            return ele.intMemberID !== memberID;
          });
          this.blnOpenAddmember = false;
          this.fetchGetUserLink(this.intUserRoleGroupID, 1, 20);
          this.intSearchResult = 0;
          this.blnCheckall = false;
          this.blnOpenAddmember = false;
          this.strInputname = "";
          this.listSortUserData = [];
          this.blnopenmember = false;

          this.listselecFromcheckbox = [];
          this.DummylistselecFromcheckbox = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async processSearchUser(keyword) {
      this.progressSearch = 0;
      await this.fetchSearchUser(keyword).then(() => {
        setTimeout(() => {
          console.log("progressSearch:100");
          this.progressSearch = 100;
        }, 500);
      });
    },
    async fetchSearchUser(keyword) {
      try {
        if (
          keyword != null &&
          keyword != undefined &&
          keyword !== "" &&
          keyword.length > 1
        ) {
          console.log(
            URLAPI +
              apiuserrolegroup.SearchUser +
              "?searchkeyword=" +
              keyword +
              "&UserRoleGroupID=" +
              this.intUserRoleGroupID +
              "&UsertypeID=" +
              this.intRadioUserType
          );
          var res = await axios.get(
            URLAPI +
              apiuserrolegroup.SearchUser +
              "?searchkeyword=" +
              keyword +
              "&UserRoleGroupID=" +
              this.intUserRoleGroupID +
              "&UsertypeID=" +
              this.intRadioUserType
          );
          console.log(res.data.data);
          var decrypt = JSON.parse(AesEncrytion.decrypt(res.data.data));
          console.log(decrypt);
          console.log(decrypt.data);
          console.log(decrypt.dataFields);
          // let test = this.filters(decrypt.data,'นักศึกษา')
          // console.log(test);
          if (this.listselecFromcheckbox.length == 0) {
            this.listSortUserData = decrypt.data;
            this.intSearchResult = this.listSortUserData.length;
          } else if (this.listselecFromcheckbox.length != 0) {
            this.listSortUserData = decrypt.data;
            // remove ตัวที่เลือกไปแล้วจากการค้นหา
            for (let i = 0; i < this.listselecFromcheckbox.length; i++) {
              const element = this.listselecFromcheckbox[i];
              this.listSortUserData = this.listSortUserData.filter((ele) => {
                return ele.intMemberID !== element.intMemberID;
              });
            }
            this.intSearchResult = this.listSortUserData.length;
            // clear checkbox
            setTimeout(() => {
              for (let l = 0; l < this.$refs.checkMySelected.length; l++) {
                this.$refs.checkMySelected[l].checked = false;
              }
              this.$refs.checkAll.checked = false;
            }, 1000);
          }
          this.listSortUserData = this.listSortUserData.filter((item) =>
            item.strUserGrouptypeNameEN.includes(this.strRadioUserType)
          );
          this.intSearchResult = this.listSortUserData.length;
          console.log(this.listSortUserData);
          // this.listSortUserData = decrypt.data;
          this.SelectedUserList = await decrypt.dataFields;

          // console.log(res.data.data);
          this.blnopenmember = true;
        }
        if (keyword === "") {
          this.clean();
          this.blnopenmember = false;
        }
      } catch (error) {
        console.log(error);
        this.blnopenmember = false;
      }
    },

    async fetchInsertUserRoleGroupLink(data) {
      console.log("data");

      try {
        var UserGroupLink = {
          intMemberID: AdsInventories.userinfo.intMemberID,
          intUserRoleGroupID: this.intUserRoleGroupID,
          action: "Insert",
          lstUserRoleGroupList: data,
        };
        console.log(JSON.stringify(UserGroupLink));
        var res = await axios.post(
          URLAPI + apiuserrolegroup.InsertUserGroupLink,
          UserGroupLink
        );
        console.log(res);

        if (res.data.code == 200 && res.data.inserted == true) {
          this.blnUserRoleGroupForm = false;
          this.blnAltStatus = true;
          this.strSMSGsuccess = "27";
          setTimeout(() => {
            this.blnAltStatus = false;
          }, 2000);
          //delay 1 sec
          setTimeout(() => {
            //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
            this.openform(false);
          }, 1000);
        }
        if (
          (res.data.code == 200 && res.data.inserted == false) ||
          res.data.code === 500
        ) {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchGetUserLink(intUserRoleGroupID, page, perPage) {
      console.log(intUserRoleGroupID);
      console.log(page);
      console.log(perPage);
      try {
        if (intUserRoleGroupID != null) {
          this.intUserRoleGroupID = intUserRoleGroupID;
        }
        this.progresstableGroupAccess = 0;
        console.log(
          URLAPI +
            apiuserrolegroup.GetMemberOfuserRoleGroup +
            this.intUserRoleGroupID +
            "?page=" +
            page +
            "&rowofpage=" +
            perPage
        );
        var res = await axios.get(
          URLAPI +
            apiuserrolegroup.GetMemberOfuserRoleGroup +
            this.intUserRoleGroupID +
            "?page=" +
            page +
            "&rowofpage=" +
            perPage
        );
        console.log(res);
        if (res.data.code == "200") {
          this.progresstableGroupAccess = 100;
          this.lstUserLink = res.data;
          console.log(this.lstUserLink);
          this.blnShowUserLink = true;
          this.strMode = "UserLink";
          setTimeout(() => {
            // this.blnUserRoleForm = true;
            // console.log(res.data.defaultField);
            if (localStorage.getItem("SelectedListMemberRoleGroup") == null) {
              localStorage.setItem(
                "SelectedListMemberRoleGroup",
                JSON.stringify(res.data.defaultField)
              );
            }
            if (this.$refs.tableGroupAccess != null) {
              this.$refs.tableGroupAccess.onClean();
              this.$refs.tableGroupAccess.calculatePage(
                this.lstUserLink.total,
                this.lstUserLink.data
              );
              console.log(localStorage.getItem("SelectedListMemberRoleGroup"));
              this.$refs.tableGroupAccess.setSelectedList(
                JSON.parse(localStorage.getItem("SelectedListMemberRoleGroup"))
              );
            }
          }, 10);
          this.strRoleGroupName = this.ListUserRoleGroup.data.filter((ele) => {
            return ele.strID == this.intUserRoleGroupID;
          });
          console.log(this.strRoleGroupName[0]);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchGetUserFieldLink(intUserRoleGroupID) {
      try {
        this.intUserRoleGroupID = intUserRoleGroupID;
        console.log(
          URLAPI + apiuserrolegroup.GetUserFieldLing + intUserRoleGroupID
        );
        var res = await axios.get(
          URLAPI + apiuserrolegroup.GetUserFieldLing + intUserRoleGroupID
        );
        console.log(res);
        if (res.data.code == "200") {
          this.lstUserFieldList = res.data.userFieldList;
          this.lstUserFieldLink = res.data.userGroupFieldLink;
          this.lstuserGroupInfo = res.data.userGroupInfo;
          setTimeout(() => {
            this.blnUserRoleForm = true;
            this.strMode = "UserGroupLink";
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },

    detectPage() {
      if (this.state == "view") {
        this.blnOpenform = false;
        this.processLoadingData();
      } else if (this.state == "form") {
        this.blnOpenform = true;
      }
    },
    reloadConfigDisplay() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListUserRoleGroup"))
        );
      }
    },
    reloadConfigDisplayMember() {
      if (this.$refs.table != null) {
        this.$refs.table.setSelectedList(
          JSON.parse(localStorage.getItem("SelectedListMemberRoleGroup"))
        );
      }
    },
    //Filter รับค่า จากตัวกรองกับข้อมูลชุดเดิม
    filter(activeFilters, ListDummy) {
      this.ListUserRoleGroup.data = ListDummy.slice();

      for (var field in activeFilters) {
        this.ListUserRoleGroup.data = this.ListUserRoleGroup.data.filter(
          (elem) => elem[activeFilters[field].state] == activeFilters[field].id
        );
      }
      if (this.$refs.table != null) {
        this.$refs.table.onClean();
        this.$refs.table.calculatePage(
          this.ListUserRoleGroup.data.length,
          this.ListUserRoleGroup.data
        );
      }
      this.$refs.table.SearchListDataTable(this.strSearch);
      this.blnOpenFilter = false;

      this.ListDummy.data = ListDummy;
    },
    // filteredData(activeFilters) {
    //   this.activeFiltersSelected = activeFilters;
    //   this.filter(activeFilters, this.ListDummy.data);
    // },

    onSaveConfigDisplay(SelectedList) {
      localStorage.setItem(
        "SelectedListUserRoleGroup",
        JSON.stringify(SelectedList)
      );
    },
    onSaveConfigDisplayMember(SelectedList) {
      localStorage.setItem(
        "SelectedListMemberRoleGroup",
        JSON.stringify(SelectedList)
      );
    },
    ConfirmDelete() {
      console.log("click button delete");
      this.$refs.AlertPopup.password = null;
      this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
    },
    //เปิดฟอร์ม
    async openform(state) {
      try {
        this.activeFiltersSelected = [];
        console.log(state);
        //ปิดฟอร์ม
        if (state == false) {
          //เคลียข้อมูลในฟอร์ม
          this.blnShowUserLink = false;
          this.blnUserRoleForm = false;

          this.lstUserFieldList = [];
          this.lstUserFieldLink = [];
          this.lstuserGroupInfo = [];
          this.strMode = "UserGroup";

          await this.processLoadingData();
          if (this.$refs.header != null) {
            this.$refs.header.onClear();
          }

          if (this.state == "view") {
            this.blnOpenform = false;
          } else if (this.state == "form") {
            this.blnOpenform = true;
          }
        }
        //เปิดฟอร์ม
        else if (state == true) {
          this.formInactive = [];
          //กำหนด action insert
          this.action = "Insert";
          this.strMode = "UserGroup";
          //   let inactive = {
          //     name: "intCampusMain",
          //     inactive: true,
          //     tooltip: true,
          //   };
          //   // var tooltip = {
          //   //   name: "intCampusMain",
          //   // };
          //   this.formInactive.push(inactive);
          var activeDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          // let activeConfirm = {
          //   name: "intRoleGroupStatus",
          //   blnConfirm: true,
          // };
          // this.formInactive.push(activeConfirm);
          this.formInactive.push(activeDelete);
          //เคลียข้อมูลในฟอร์ม

          this.formdata = {};

          if (this.state == "view") {
            this.blnOpenform = false;
          } else if (this.state == "form") {
            this.blnOpenform = true;
          }
        }
        this.blnOpenform = state;
      } catch (error) {
        console.log(error);
      }
    },

    async processLoadingData() {
      this.listRelate = [];
      this.progress = 0;
      this.ListDummy = [];
      this.ListUserRoleGroup = [];
      this.timerCountHover = 0;
      this.timerEnabled = true;

      this.formdata = {};
      this.formdataDummy = {};
      this.ListUserRoleGroup = {};
      this.ListDummy = {};
      await this.fetchListUserRoleGroup().then(() => {
        this.processData().then(() => {
          setTimeout(() => {
            if (this.progress > 70) {
              console.log("100");
              this.progress = 100;
            }
          }, 1000);
        });
      });

      //this.progress = 100;
    },

    //บันทึกข้อมูลของ ด ไปยัง Database
    async fetchInsertUserRoleGroup(data) {
      this.msgalertDataDuplicate = [];

      console.log(data);
      let strRoleGroupNameEN = data.strRoleGroupNameEN;
      let strRoleGroupNameTH = data.strRoleGroupNameTH;
      let intRoleGroupStatus = data.intRoleGroupStatus;
      let roleModule = data.roleModule;

      const formdata = {
        strRoleGroupNameEN,
        strRoleGroupNameTH,

        intRoleGroupStatus,
        roleModule,
      };
      try {
        if (roleModule.length != 0) {
          console.log(
            URLAPI +
              apiuserrolegroup.InsertUserroleGroup +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              (data.confirmblnUserGroupStatus != undefined
                ? "&Confirm=" + data.confirmblnUserGroupStatus
                : "&Confirm=" + false)
          );

          var res = await axios.post(
            URLAPI +
              apiuserrolegroup.InsertUserroleGroup +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              (data.confirmblnUserGroupStatus != undefined
                ? "&Confirm=" + data.confirmblnUserGroupStatus
                : "&Confirm=" + false),
            formdata
          );
          console.log(res);
          if (res.data.inserted == true) {
            this.blnOpenform = false;
            this.blnAltStatus = true;
            this.strSMSGsuccess = "26";
            setTimeout(() => {
              this.blnAltStatus = false;
            }, 2000);
            //delay 1 sec
            setTimeout(() => {
              //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
              this.openform(false);
            }, 1000);
          }
          if (res.data.inserted == false) {
            this.msgalertDataDuplicate = [];
            if (res.data.blnRoleGroupNameEN == true) {
              let Message = { name: "strRoleGroupNameEN", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
            }
            if (res.data.blnRoleGroupNameTH == true) {
              let Message = { name: "strRoleGroupNameTH", msg: "221" };
              this.msgalertDataDuplicate.push(Message);
            }
            console.log(this.msgalertDataDuplicate);
            this.$refs.form.detectalertDataDuplicate(
              this.msgalertDataDuplicate
            );
          }
          if (res.data.code === 500) {
            this.blnAltStatusunsucess = true;
            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;

            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          }
        } else {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;

          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
        }
      } catch (error) {
        this.blnAltStatusunsucess = true;
        this.timerCountHover = 0;
        this.timerEnabled = true;
        this.hover = false;

        setTimeout(() => {
          this.blnAltStatusunsucess = false;
        }, 5000);
        throw new Error(error);
      }
      return true;
    },
    //จัดการข้อมูลแล้วไปแสดงผลในตาราง
    async processData() {
      setTimeout(() => {
        this.ListUserRoleGroup.data = Enumerable.from(
          this.ListUserRoleGroup.data
        )
          .orderByDescending((r) => r.intRoleGroupStatus)
          .thenBy((r) => r.strRoleGroupNameEN.toString().toLowerCase())
          .toArray();
      }, 100);
      setTimeout(() => {
        if (localStorage.getItem("SelectedListUserRoleGroup") == null) {
          localStorage.setItem(
            "SelectedListUserRoleGroup",
            JSON.stringify(this.ListUserRoleGroup.defaultField)
          );
        }
      }, 100);
      //delay 1 sec
      setTimeout(() => {
        if (this.$refs.table != null) {
          this.$refs.table.onClean();
          this.$refs.table.calculatePage(
            this.ListUserRoleGroup.total,
            this.ListUserRoleGroup.data
          );

          this.$refs.table.setSelectedList(
            JSON.parse(localStorage.getItem("SelectedListUserRoleGroup"))
          );
        }
      }, 100);
      if (this.$refs.filter != null) {
        this.$refs.filter.Clear();
      }

      //this.timerEnabled = true;
      return true;
    },
    async fetchUpdateUserRoleGroup(data) {
      this.msgalertDataDuplicate = [];
      console.log(data);

      let strRoleGroupNameEN = data.data.strRoleGroupNameEN;
      let strRoleGroupNameTH = data.data.strRoleGroupNameTH;
      let intRoleGroupStatus = data.data.intRoleGroupStatus;
      let roleModule = data.data.roleModule;
      const formdata = {
        strRoleGroupNameEN,
        strRoleGroupNameTH,

        intRoleGroupStatus,
        roleModule,
      };
      // let isEqual = JSON.stringify(this.formdataDummy) === JSON.stringify(data);
      // console.log(this.formdataDummy);
      // console.log(data);

      console.log(this.formdataDummy);
      if (
        this.formdataDummy.intRoleGroupStatus == data.intRoleGroupStatus &&
        this.formdataDummy.strRoleGroupNameTH == data.strRoleGroupNameTH &&
        this.formdataDummy.strRoleGroupNameEN == data.strRoleGroupNameEN &&
        data.merge == true
      ) {
        await axios
          .get(
            URLAPI +
              apiuserrolegroup.GetUserroleGroupbyID +
              this.intUserRoleGroupID
          )
          .then((res) => {
            console.log(res.data);
            if (
              res.data.message ==
              "Cannot edit ,Please check mapping Id in interface"
            ) {
              this.blnOpenform = false;
              this.blnnotedit = true;
              setTimeout(() => {
                this.blnnotedit = false;
                this.processLoadingData();
              }, 5000);
            } else {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 2000);
            }
          });
      } else {
        console.log(
          URLAPI +
            apiuserrolegroup.UpdateUserroleGroup +
            this.intUserRoleGroupID +
            "?MemberID=" +
            AdsInventories.userinfo.intMemberID +
            (data.confirmintRoleGroupStatus != undefined
              ? "&Confirm=" + data.confirmintRoleGroupStatus
              : "&Confirm=" + false)
        );
        await axios
          .put(
            URLAPI +
              apiuserrolegroup.UpdateUserroleGroup +
              this.intUserRoleGroupID +
              "?MemberID=" +
              AdsInventories.userinfo.intMemberID +
              (data.confirmintRoleGroupStatus != undefined
                ? "&Confirm=" + data.confirmintRoleGroupStatus
                : "&Confirm=" + false),
            formdata
          )
          .then((res) => {
            console.log(res);
            if (res.data.edited == true) {
              this.blnOpenform = false;
              this.blnAltStatus = true;
              this.strSMSGsuccess = "27";
              setTimeout(() => {
                this.blnAltStatus = false;
              }, 2000);
              //delay 1 sec
              setTimeout(() => {
                //ถ้าบันทึกสำเร็จ ทำการเรียกข้อมูลใหม่อีกครั้ง
                this.openform(false);
              }, 1000);
            }
            if (res.data.edited == false) {
              if (res.data.campusnameEN == true) {
                let Message = { name: "blnUserRoleGroupNameEN", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
                console.log("ชื่ออังกฤษซ้ำ");
              }
              if (res.data.campusnameTH == true) {
                let Message = { name: "blnUserRoleGroupNameTH", msg: "221" };
                this.msgalertDataDuplicate.push(Message);
                console.log("ชื่อไทยซ้ำ");
              }
              //ถ้าไม่สามารถ edit ได้ แล้วชื่อไม่ซ้ำ
              if (
                res.data.message ==
                "Cannot edit ,Please check mapping Id in interface"
              ) {
                console.log("ไม่ซ้ำ");
                this.blnOpenform = false;
                this.blnnotedit = true;
                setTimeout(() => {
                  this.blnnotedit = false;
                  this.processLoadingData();
                }, 5000);
              }

              this.$refs.form.detectalertDataDuplicate(
                this.msgalertDataDuplicate
              );
            }
            if (res.data.code === 500) {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              this.hover = false;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
          })
          .catch((e) => {
            this.blnAltStatusunsucess = true;

            this.timerCountHover = 0;
            this.timerEnabled = true;
            this.hover = false;
            console.log(e);

            setTimeout(() => {
              this.blnAltStatusunsucess = false;
            }, 5000);
          });
      }
    },

    async fetchGetUserRoleGroupByID(intUserRoleGroupID) {
      try {
        this.intUserRoleGroupID = intUserRoleGroupID;

        // console.log(URLAPI + apiuserrolegroup.checkusedstatus + intUserRoleGroupID);

        var res = await axios.get(
          URLAPI + apiuserrolegroup.GetUserroleGroupbyID + intUserRoleGroupID
        );

        this.action = "Update";
        console.log(res.data);
        //ถ้าลบไปแล้ว แต่ยังอยู่หน้า view แล้วกดEdit
        if (
          res.data.message == "Not found ,Please check mapping Id in interface"
        ) {
          this.blnnotedit = true;
          setTimeout(() => {
            this.blnnotedit = false;
            this.processLoadingData();
          }, 4000);
        }

        if (res.data.data.intRoleGroupStatus == 1) {
          this.formInactive = [];
          let inactive = {
            name: "intRoleGroupStatus",
            inactive: false,
            tooltip: true,
            enable: false, //first toggle = open
          };

          this.formInactive.push(inactive);
          // let inactivestauts = {
          //   name: "intRoleGroupStatus",
          //   inactive: true,
          //   tooltip: false,
          // };
          let deleteinactive = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: true,
          };
          this.formInactive.push(deleteinactive);

          let activeConfirm = {
            name: "intRoleGroupStatus",
            blnConfirm: true,
          };

          this.formInactive.push(activeConfirm);
          // this.formInactive.push(inactivestauts);
          // this.formInactive.push(tooltip);
        } else if (res.data.data.intRoleGroupStatus != 1) {
          this.formInactive = [];
          let inactive = {
            name: "intRoleGroupStatus",
            inactive: false,
            tooltip: false,
          };
          let activeConfirm = {
            name: "intRoleGroupStatus",
            blnConfirm: true,
          };

          this.formInactive.push(activeConfirm);
          this.formInactive.push(inactive);
          // this.formInactive.push(tooltip);
        }

        this.formdata = res.data.data;
        this.formdataDummy = { ...res.data.data };
        // this.listRelate = { ...resRelate.data };

        // this.formInactive.push(activeConfirm);
        if (res.data.data.intCountUser > 1) {
          var inactiveDelete = {
            name: "blnDeleteinForm",
            inactive: true,
            tooltip: false,
          };
          this.formInactive.push(inactiveDelete);
        }
        console.log(this.formInactive);
        this.blnOpenform = true;
      } catch (error) {
        console.log(error);
      }
    },

    //เรียกข้อมูลของ ด ทั้งหมด
    async fetchListUserRoleGroup() {
      this.timerCount = 0;
      this.timerEnabled = true;
      console.log(URLAPI + apiuserrolegroup.GetUserroleGroupList);
      await axios
        .get(URLAPI + apiuserrolegroup.GetUserroleGroupList)
        .then((res) => {
          this.ListDummy = res.data;
          this.ListUserRoleGroup = res.data;
          //เช็คข้อมูลวิทยาเขต ว่าลบได้หรือไม่
        })
        .catch((e) => {
          this.blnAltStatusunsucess = true;
          this.timerCountHover = 0;
          this.timerEnabled = true;
          this.hover = false;
          setTimeout(() => {
            this.blnAltStatusunsucess = false;
          }, 5000);
          console.log(e);
        });
      return true;
    },

    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    // ลบข้อมูลด้วย ID
    async fetchDeleteUserRoleGroup(state) {
      try {
        if (state === false) {
          console.log(state);
          this.blnPopupConfirmDelete = !this.blnPopupConfirmDelete;
        } else {
          var strUsername = AdsInventories.userinfo.strUsername.split("@");
          console.log(strUsername);
          var reslogin = await Login.login(
            strUsername[0],
            state,
            axios,
            URLAPI
          );

          var login = {
            username: strUsername[0],
            password: state,
          };
          console.log(login);
          if (reslogin.logined == true) {
            this.intPinCount = reslogin.pincode;
            this.strTel = reslogin.tel;
            var res = await axios.put(
              URLAPI +
                apiuserrolegroup.DeleteUserroleGroup +
                this.intUserRoleGroupID +
                "?MemberID=" +
                AdsInventories.userinfo.intMemberID,
              login
            );
            console.log(res.data);
            if (res.data.candelete == true) {
              this.blnError = false;
              this.blnPopupConfirmDelete = false;
              this.strSMSGsuccess = "28";
              this.blnOpenform = false;
              this.blnAltStatus = true;
              //delay 1 sec
              setTimeout(() => {
                this.processLoadingData();
                this.blnAltStatus = false;
              }, 1000);
              // this.$refs.table.checkstateofdelete(true);
            } else if (res.data.candelete == false) {
              this.blnOpenform = false;
              this.fetchGetUserGroupbyId(this.intUserRoleGroupID);

              this.timerCountHover = 0;
              this.timerEnabled = true;
              this.hover = false;
              this.blnPopupConfirmDelete = false;
              // reset PIN หลังจากDelete
              this.$refs.AlertPopup.password = null;

              //ถ้าลบไปแล้ว กดลบซ้ำ
              if (
                res.data.message ==
                "Cannot delete ,Please check mapping Id in interface"
              ) {
                //ข้อมูลนี้เพิ่งถูกลบไป ไม่สามารถลบได้
                this.blnnotdelete = true;
                setTimeout(() => {
                  this.blnnotdelete = false;
                  this.processLoadingData();
                }, 5000);
              }
              //กรณีเปลี่ยนเป็นMain แล้วกดลบ
              else if (
                res.data.message == "This data is main campus,Cannot delete"
              ) {
                //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                this.blnAltLoadingData = true;
                setTimeout(() => {
                  this.blnAltLoadingData = false;
                  this.processLoadingData();
                }, 5000);
              } else {
                //ข้อมูลนี้เพิ่งถูกใช้งาน ไม่สามารถลบได้
                this.blnAltLoadingData = true;
                setTimeout(() => {
                  this.blnAltLoadingData = false;
                  this.processLoadingData();
                }, 5000);
              }
            } else {
              this.blnAltStatusunsucess = true;
              this.timerCountHover = 0;
              this.timerEnabled = true;
              this.hover = false;
              this.blnPopupConfirmDelete = false;
              // reset PIN หลังจากDelete
              this.$refs.AlertPopup.password = null;
              setTimeout(() => {
                this.blnAltStatusunsucess = false;
              }, 5000);
            }
            this.blnPopupConfirmDelete = false;

            // reset PIN หลังจากDelete
          } else {
            this.intPinCount = reslogin.pincode;
            this.strTel = reslogin.tel;
            this.intWrongLogin = reslogin.WrongLogin;
            this.blnError = true;
            this.$refs.AlertPopup.blnShowMSG = true;
            // this.$refs.table.checkstateofdelete(false);
            // setTimeout(() => {
            //   this.processLoadingData();
            // }, 1000);
          }
        }
      } catch (error) {
        this.$refs.AlertPopup.blnShowMSG = true;
        // this.blnAltLoadingData = true;
        // this.timerCountHover = 0;
        // this.timerEnabled = true;
        // this.hover = false;
        console.log(error);
      }
    },
    openFilter(showFilter) {
      this.blnOpenFilter = showFilter;
    },
    // blnopenConfigDisplay(showDisplay) {
    //   this.blnopenConfigDisplay = showDisplay;

    // },
    SearchUserGroup(event) {
      this.strSearch = event;
      this.$refs.table.SearchListDataTable(event);
    },

    clean() {
      this.strSearch = "";
      this.SearchUserGroup("");
      //this.filteredData(this.activeFiltersSelected);
    },

    checkSome(event, memberID) {
      console.log(event);
      console.log(memberID);
      this.blnOpenDataMySelected = false;
      let data = this.listSortUserData.filter((ele) => {
        return ele.intMemberID === memberID;
      });
      const index = this.DummylistselecFromcheckbox.indexOf(data[0]);
      if (index > -1) {
        this.DummylistselecFromcheckbox.splice(index, 1);
      } else {
        this.DummylistselecFromcheckbox.push(data[0]);
      }
      console.log(this.DummylistselecFromcheckbox);
    },
    handleScroll(e) {
      if (e.target.scrollTop + 50 * 10 + 1 >= e.target.scrollHeight) {
        this.maxcount = this.maxcount + 10;
      }
    },
    checkAll(event) {
      console.log("checkAll");
      this.blnCheckall = event.target.checked;
      this.blnOpenDataMySelected = false;
      if (this.blnCheckall == false) {
        for (let i = 0; i < this.$refs.checkMySelected.length; i++) {
          this.$refs.checkMySelected[i].checked = false;
        }
        this.$refs.checkAll.checked = false;
      }
      if (this.blnCheckall == true) {
        for (let i = 0; i < this.$refs.checkMySelected.length; i++) {
          this.$refs.checkMySelected[i].checked = true;
        }
        this.$refs.checkAll.checked = true;
      }
      let data = this.listSortUserData;
      for (let i = 0; i < data.length; i++) {
        const index = this.DummylistselecFromcheckbox.indexOf(data[i]);
        if (index > -1) {
          this.DummylistselecFromcheckbox.splice(index, 1);
        } else {
          this.DummylistselecFromcheckbox.push(data[i]);
        }
      }
    },
    addToMySelected() {
      console.log("addToMySelected");
      console.log(this.blnOpenDataMySelected);
      this.blnOpenDataMySelected = true;
      // if (this.blnCheckall == true) {
      //   this.blnopenmember = false;

      //   this.listselecFromcheckbox = this.DummylistselecFromcheckbox;
      // } else {
      if (this.blnOpenDataMySelected == true) {
        this.blnopenmember = false;
        this.listselecFromcheckbox = this.DummylistselecFromcheckbox.filter(
          (e) => e
        );
      }

      // }
      console.log(this.blnopenmember);
      console.log(this.DummylistselecFromcheckbox);
      console.log(this.DummylistselecFromcheckbox.filter((e) => e));
      console.log(this.listselecFromcheckbox);
    },
    clearMySelected() {
      console.log("clearCheckbox");
      this.listSortUserData = [];
      this.listselecFromcheckbox = [];
      this.blnopenmember = false;
      this.intSearchResult = 0;
      this.blnCheckall = false;
      this.blnOpenAddmember = false;
      this.strInputname = "";
      for (let i = 0; i < this.$refs.checkMySelected.length; i++) {
        if (this.$refs.checkMySelected[i] != null) {
          this.$refs.checkMySelected[i].checked = false;
        }
      }
      this.$refs.checkAll.checked = false;
      this.blnCheckall = false;
      this.blnOpenAddmember = false;
      this.DummylistselecFromcheckbox = [];
    },
    removeMyselected(memberID) {
      console.log("removeMyselected");
      this.listselecFromcheckbox = this.listselecFromcheckbox.filter((ele) => {
        return ele.intMemberID !== memberID;
      });
      this.DummylistselecFromcheckbox = this.listselecFromcheckbox;
      console.log(this.listselecFromcheckbox);
    },
    addData() {
      for (let i = 0; i < this.listselecFromcheckbox.length; i++) {
        const element = this.listselecFromcheckbox[i];
        console.log(element.intMemberID);
        this.fetchAddUserAccess(element.intMemberID);
      }
    },
    radioUserType(item) {
      console.log(item.id);
      // this.strRadioUserType = item.id;
      if (item.id == 0) {
        this.strRadioUserType = "Student";
        this.intRadioUserType = 1;
      } else if (item.id == 1) {
        this.strRadioUserType = "Officer";
        this.intRadioUserType = 2;
      } else if (item.id == 2) {
        this.strRadioUserType = "Others";
        this.intRadioUserType = 3;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        height: 48px;
      }

      th:first-child {
        position: sticky;
        left: 0;
        background-color: $white;
        z-index: 2;
      }
      th:last-child {
        position: sticky;
        right: 0;
        background-color: $white;
        z-index: 2;
      }
    }
  }
  tbody {
    tr {
      th:first-child {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 0;
      }
      td:last-child {
        z-index: 0;
        position: sticky;
        right: 0;
        background-color: #fff;
      }
    }
  }
}
.form-check {
  cursor: pointer !important;
  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border-radius: 2px !important;
    &:hover,
    &:focus {
      cursor: pointer;
      border: solid 1px $primary;
    }
  }
}
.back-icon {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.scroll-load {
  max-height: 465px;
  overflow-y: auto;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  padding-top: 200; /* Location of the box */
  left: 15%;
  top: 15%;
  width: 70%; /* Full width */
  height: 70%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.form-lg-fix {
  max-width: 910px;
  min-width: 400px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}

.zoom-enter-active {
  animation: bounce-in 150ms;
}

.zoom-leave-active {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

// .popup-container {
//   width: 100%;
//   height: 100%;
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 9999;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   .overlay {
//     background-color: rgba(0, 0, 0, 0.263);
//     width: 100%;
//     height: 100%;
//     position: absolute;
//   }
// }
.move-to-top-level .layout:nth-child(odd) {
  order: 2;
  margin-bottom: 16px !important;
}

.form-fix {
  // max-width: max-content;
  width: 820px;
}
</style>