<template>
  <model :modelTitle="artName" :idModel="idName" fontColor="delete">
    <div class="flex-column d-flex align-items-end justify-content-center">
      <div class="d-flex align-items-center">
        <component
          :is="artIcon"
          class="me-3"
          :class="classIcon"
          width="40"
          height="40"
        ></component>
        <!-- <span class="fnt-regular fnt-h6">{{ altMsgBox }}</span> -->
        <span class="fnt-regular fnt-h6">{{ altMsgBox }}</span>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <Button
          data-bs-dismiss="modal"
          aria-label="Close"
          :name="$t('36')"
          @click="deleted()"
          class="btn-fix btn-danger me-2"
          :class="classOkBtn"
        />
        <Button
          data-bs-dismiss="modal"
          aria-label="Close"
          :name="$t('35')"
          @click="cancel()"
          class="btn-fix btn-cancel text-danger"
          :class="classCancelBtn"
        />
      </div>
    </div>
  </model>
</template>

<script>
import Button from "../button/button.vue";
import { DeleteFill } from "@/components/icon/index";
// import { Icon } from "@iconify/vue";
import model from "../dialog/modalAlert.vue";
export default {
  components: {
    model,
    // Icon,
    Button,
    DeleteFill,
  },
  props: {
    artName: String,
    idName: String,
    artIcon: String,
    altMsgBox: String,
    classCancelBtn: String,
    classOkBtn: String,
    classIcon: String,
  },
  emits: "onInput",

  methods: {
    deleted() {
      this.$emit("onInput", true);
    },
    cancel() {
      this.$emit("onInput", false);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100px !important;
  height: 36px !important;
}
</style>