import { DraggableItem } from "../types/draggable-item.interface";
import { getIdGenerator } from "./id-generator";

const draggableItemIdGenrator = getIdGenerator();

export const toDraggableItems = (
  arr: Array<any>,
  ele: string
): Array<DraggableItem> => {
  return arr.map((e) => (
    {

      id: draggableItemIdGenrator(),
      data: e,
      draggable: ele,
      //fieldtype: "fieldset",
      fieldtype: e.items != undefined ? "fieldset" : "fieldnormal",
      itemID: e.strID
    }));
};

export const toOriginalArray = (arr: Array<DraggableItem>): Array<any> => {
  return arr.map((e) => e.data);
};
