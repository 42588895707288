<template>
  <div
    class="modal fade rounded-2"
    :id="idModel"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content p-5 border-0">
        <!-- <div class="modal-header p-0 mb-3">
          <div class="txt-pr" id="exampleModalLabel">
            <Icon :icon="modelIcon" class="me-2" width="32" height="32" />
            <span class="m-0 p-0 model-title" :class="fontColor">{{
              modelTitle
            }}</span>
          </div>
        </div> -->
        <div class="modal-body p-0">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Icon } from "@iconify/vue";

export default {
  components: {
    // Icon,
  },
  props: {
    styleDlg: { type: String },
    modelTitle: { type: String },
    modelIcon: { type: String },
    idModel: { type: String },
    fontColor: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.model {
  width: 329px !important;
}
.modal-content {
  min-width: fit-content;
  .model-title {
    font-size: 26px;
    line-height: 0;
  }
  .modal-header {
    border: 0 !important;
  }
}
.btn-close {
  box-shadow: none !important;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: fit-content;
    margin: 1.75rem auto;
  }
}
</style>
