<template>
  <div class="progress" :style="'width:' + percen + '%'"></div>
  <!-- v-if="$route.params.id == 4"  -->
</template>

<script>
export default {
  props: {
    percen: { type: String },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: fixed;
  top: 75px;
  left: 0;
  height: 5px;
  width: 100%;
  background-color: $black-300;
  z-index: 9999;
}
.progress-2 {
  position: fixed;
  top: 75px;
  left: 0;
  height: 5px;
  background-color: $secondary-300;
  z-index: 9999;
}
.progress-3 {
  position: fixed;
  top: 75px;
  left: 0;
  height: 5px;
  background-color: $primary-300;
  z-index: 9999;
}
</style>