<template>
  <div
    class="alert-box"
    :blnalert="blnAltStatus || blnAltStatusunsucess"
    :class="blnAltStatus || blnAltStatusunsucess ? '' : 'd-none'"
  >
    <AltStatus
      :blnalert="blnAltStatus"
      icon="CorrectFill"
      coloricon="fill-success"
      class="success"
      :name="$t('28')"
    />
    <AltStatus
      :blnalert="blnAltStatusunsucess"
      icon="IncorrectFill"
      coloricon="fill-danger"
      class="error"
      :name="$t('174')"
      @mousemove="hover = true"
      @mouseleave="hover = false"
    />
  </div>
  <Alert
    idName="ModalDltUserGroup"
    :altMsgBox="$t('186', { fieldname: $t(nametable).toLowerCase() })"
    @onInput="confirmDelete($event)"
    classIcon="fill-danger"
  />
  <div
    v-if="progressScore <= 99"
    class="d-flex align-items-center w-100 justify-content-center"
  >
    <Loading1 v-if="$route.params.id == 1" :percen="progressScore" />
    <Loading2 v-if="$route.params.id == 2 || $route.params.id == null" />
    <Loading3 v-if="$route.params.id == 3" />
    <Loading4 v-if="$route.params.id == 4" :percen="progressScore" />
  </div>
  <!-- <div
    class="overlay"
    :class="filter ? ' overlay-in' : 'overlay-out'"
    v-if="filter"
    @click="filter"
  ></div> -->
  <div class="popup-container" v-show="showDisplay == true">
    <transition name="zoom">
      <ConfigDisplay
        :DefaultField="ListDataTable.defaultField"
        :AllField="ListDataTable.dataFields"
        :SelectedListExcept="ListDataTable.selectedListExcept"
        :SelectedListOld="SelectedList"
        ref="config"
        v-if="showDisplay == true"
        @Ok="onSaveDisplay($event)"
        @Cancel="onShowConfigDisplay()"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-show="showDisplay == true"
        @click="showDisplay = false"
      ></div>
    </transition>
  </div>
  <div
    v-show="progressScore >= 99"
    class="p-4 bg-white rounded-2 shadow-sm con"
    :class="device == 'mobile' ? 'p-3' : ''"
  >
    <div
      class="table-container"
      @mouseleave="isDown = false"
      @mouseup="isDown = false"
      @mousedown="onTabledown"
      @mousemove="ontableScrollMove"
      :style="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData?.length == 0
          ? 'height:fit-content;'
          : ''
      "
      :class="
        ListDataTable.total == 0 ||
        ListDataTable == null ||
        listSortData?.length == 0
          ? 'none-scrollbar'
          : '' || device == 'mobile'
          ? 'table-mobile'
          : ''
      "
    >
      <!-- <button class="btn btn-primary" @click="checkWidth">
          Check width of table
        </button> -->
      <table class="table" aria-describedby="tableGeneral">
        <thead>
          <tr>
            <th scope="col" class="fnt-body" style="width: 0">
              <div
                class="d-flex align-items-center justify-content-center bg-white"
              >
                <label
                  class="fnt-body fnt-bold text-black-500 ms-1"
                  style="margin-bottom: 1.55px !important"
                >
                  {{ $t("18") }}
                </label>
              </div>
            </th>
            <th
              v-for="(items, index) in SelectedList"
              :key="index"
              scope="col"
              class="fnt-body"
            >
              <!-- <button class="btn fnt-body text-black-500 p-0 m-0" @click="listsortData(SelectedList[index].field, sort)"  > 
              {{ $t(items.head) }}
              
              <button/> -->

              <div class="d-flex align-items-center">
                <button
                  id="sortColumn"
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="btn p-0 m-0 fnt-body fnt-bold fnt-body text-black-500"
                  :class="
                    checkTableSort(SelectedList[index].field)
                      ? 'text-black'
                      : ''
                  "
                >
                  {{
                    typeof items.head == "string"
                      ? $t(items.head)
                      : items.head[filterLang(items.head)]
                  }}
                </button>
                <!-- <Button
                leftBtn="bi:arrow-down"
                classBtn="p-0 m-0 mb-1 sort text-black-500 ms-1 sorting-active"
                :class="checkTableSort(campus.state[index-1]) ? 'sorted text-black' : 'd-none'"
                width="14"
                height="14"
                class="arrow-sort"
                :class="
                  checkTableSort(SelectedList[index].field)
                    ? 'sorted text-black'
                    : 'd-none'
                "
                id="sortColumn"
                @click="listsortData(SelectedList[index].field, sort)"
              /> -->
                <button
                  id="sortColumn"
                  :class="
                    sort && checkTableSort(SelectedList[index].field, sort)
                      ? 'text-secondary sorted'
                      : 'hover-none text-secondary '
                  "
                  @click="
                    listsortData(
                      SelectedList[index].field,
                      checkTableSort(SelectedList[index].field),
                      sort
                    )
                  "
                  class="fnt-body btn p-0 m-0 sorting-active"
                >
                  <!-- <Icon
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : 'opacity-0'
                    "
                    icon="bi:arrow-down"
                    width="14"
                    height="14"
                  /> -->
                  <SortArrow
                    :class="
                      checkTableSort(SelectedList[index].field, sort)
                        ? ''
                        : ' opacity-0 '
                    "
                    width="18"
                    height="18"
                  />
                </button>
              </div>
            </th>
            <th
              scope="col"
              style="height: 48px; width: 0"
              :class="device == 'mobile' ? 'px-0' : ''"
            >
              <div
                class="d-flex justify-content-start"
                :class="
                  device == 'mobile'
                    ? blnEditUserGroup == true
                      ? 'justify-content-start'
                      : 'justify-content-end'
                    : ''
                "
              >
                <Button
                  icon="DisplayConfig"
                  classBtn="stroke-secondary p-0 m-0"
                  @click="onShowConfigDisplay()"
                  width="20"
                  height="20"
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          ref="tablebody"
          class="border-0"
          v-if="listSortData?.length != 0"
        >
          <tr
            v-for="(items, index) in listSortData"
            :key="index"
            class="border-bottom"
          >
            <th v-if="page == 1" id="">
              <div
                class="d-flex align-items-center justify-content-center fnt-regular fnt-body mt-1"
              >
                {{ index + 1 }}
              </div>
            </th>

            <th v-if="page != 1" id="">
              <div
                class="d-flex align-items-center fnt-regular fnt-body justify-content-center mt-1"
              >
                {{ (page - 1) * perpage + (index + 1) }}
              </div>
            </th>

            <td
              v-for="(itemsState, indexState) in SelectedList"
              :key="indexState"
              class="fnt-regular fnt-body"
            >
              <div
                v-if="
                  SelectedList[indexState].field == 'intRequire' ||
                  SelectedList[indexState].field == 'intIdentifier' ||
                  SelectedList[indexState].field == 'intRepeatable' ||
                  SelectedList[indexState].field == 'intNotnull' ||
                  SelectedList[indexState].field == 'intSearchflag' ||
                  SelectedList[indexState].field == 'intTemplateDifinest'
                "
                class="d-flex align-items-center mt-1"
              >
                <div
                  v-if="items[SelectedList[indexState].field] == 1"
                  :class="
                    SelectedList[indexState].field == 'intRequire'
                      ? $i18n.locale == 'en'
                        ? 'ms-3'
                        : 'ms-4'
                      : '' || SelectedList[indexState].field == 'intIdentifier'
                      ? $i18n.locale == 'en'
                        ? 'ms-4'
                        : 'ms-5'
                      : '' || SelectedList[indexState].field == 'intRepeatable'
                      ? $i18n.locale == 'en'
                        ? 'ms-4'
                        : 'ms-2 ps-1'
                      : '' || SelectedList[indexState].field == 'intNotnull'
                      ? $i18n.locale == 'en'
                        ? 'ms-2 ps-1'
                        : 'ms-4'
                      : '' || SelectedList[indexState].field == 'intSearchflag'
                      ? $i18n.locale == 'en'
                        ? 'ms-4'
                        : 'ms-2'
                      : '' ||
                        SelectedList[indexState].field == 'intTemplateDifinest'
                      ? $i18n.locale == 'en'
                        ? 'ms-4'
                        : 'ms-2'
                      : ''
                  "
                >
                  <Icon icon="bi:check-lg" class="text-success" width="24" />
                </div>

                <div
                  v-if="items[SelectedList[indexState].field] == 0"
                  :class="
                    SelectedList[indexState].field == 'intRequire'
                      ? $i18n.locale == 'en'
                        ? 'ms-4'
                        : 'ms-5'
                      : '' || SelectedList[indexState].field == 'intIdentifier'
                      ? $i18n.locale == 'en'
                        ? 'ms-5'
                        : 'ms-6'
                      : '' || SelectedList[indexState].field == 'intRepeatable'
                      ? $i18n.locale == 'en'
                        ? 'ms-5'
                        : 'ms-3 ps-1'
                      : '' || SelectedList[indexState].field == 'intNotnull'
                      ? $i18n.locale == 'en'
                        ? 'ms-3 ps-1'
                        : 'ms-5'
                      : '' || SelectedList[indexState].field == 'intSearchflag'
                      ? $i18n.locale == 'en'
                        ? 'ms-5'
                        : 'ms-3'
                      : '' ||
                        SelectedList[indexState].field == 'intTemplateDifinest'
                      ? $i18n.locale == 'en'
                        ? 'ms-5'
                        : 'ms-3'
                      : ''
                  "
                >
                  {{ "-" }}
                </div>
              </div>
              <div
                v-if="SelectedList[indexState].field == 'blnUsed'"
                class="d-flex align-items-center mt-1"
              >
                <Icon
                  v-if="items[SelectedList[indexState].field] == 1"
                  icon="bi:check-lg"
                  class="text-success"
                  width="24"
                />
                <div v-if="items[SelectedList[indexState].field] == 0">
                  {{ "-" }}
                </div>
              </div>
              <div
                v-if="SelectedList[indexState].field == 'strDatatype'"
                class="d-flex align-items-center mt-1"
              >
                {{
                  $t(ConvertUserField(items[SelectedList[indexState].field]))
                }}
              </div>

              <div
                v-if="SelectedList[indexState].field == 'intFieldType'"
                class="d-flex align-items-center mt-1"
              >
                {{
                  $t(
                    ConvertUserFieldType(items[SelectedList[indexState].field])
                  )
                }}
              </div>
              <div v-if="SelectedList[indexState].field == 'intValuetype'">
                {{
                  $t(
                    ConvertParameterValueType(
                      items[SelectedList[indexState].field]
                    )
                  )
                }}
              </div>

              <div
                v-if="
                  SelectedList[indexState].field != 'blnUserTypeStatus' &&
                  SelectedList[indexState].field != 'blnUserGroupStatus' &&
                  SelectedList[indexState].field != 'intCpstatus' &&
                  SelectedList[indexState].field != 'intPositionStatus' &&
                  SelectedList[indexState].field != 'intEducationStatus' &&
                  SelectedList[indexState].field != 'intTemplatestatus' &&
                  SelectedList[indexState].field != 'strPositionWorkPeriod' &&
                  SelectedList[indexState].field != 'strDatatype' &&
                  SelectedList[indexState].field != 'intFieldType' &&
                  SelectedList[indexState].field != 'intRequire' &&
                  SelectedList[indexState].field != 'intIdentifier' &&
                  SelectedList[indexState].field != 'intRepeatable' &&
                  SelectedList[indexState].field != 'intValuetype' &&
                  SelectedList[indexState].field != 'intNotnull' &&
                  SelectedList[indexState].field != 'intTemplateDifinest' &&
                  SelectedList[indexState].field != 'intSearchflag' &&
                  SelectedList[indexState].field != 'blnUsed' &&
                  SelectedList[indexState].field != 'intUsergroupID' &&
                  SelectedList[indexState].field != 'strValue' &&
                  SelectedList[indexState].field != 'strWorktypeEN'
                "
                class="d-flex align-items-center mt-1"
              >
                {{
                  items[SelectedList[indexState].field] == null
                    ? "-"
                    : items[SelectedList[indexState].field]
                }}
                <Label
                  v-if="
                    checkmainListDataTable(
                      SelectedList[indexState].field,
                      items
                    )
                  "
                  class="main-data text-primary m-0 ms-1 ps-1 p-0"
                  :label="
                    SelectedList[indexState].field == 'strCampusNameEN' ||
                    SelectedList[indexState].field == 'strCampusNameTH'
                      ? ' (' + $t('21') + ')'
                      : SelectedList[indexState].field == 'strUsertemplatename'
                      ? ' (' + $t('154') + ')'
                      : ''
                  "
                />
              </div>

              <div
                v-if="SelectedList[indexState].field == 'strValue'"
                class="d-flex align-items-center mt-1"
              >
                {{
                  items["strValue"] == undefined || items["strValue"] == ""
                    ? items["strLookupValue"] == null
                      ? "-"
                      : items["strLookupValue"]
                    : items["strValue"] == null
                    ? "-"
                    : items["strValue"]
                }}
              </div>
              <div
                v-if="SelectedList[indexState].field == 'strWorktypeEN'"
                class="d-flex align-items-center mt-1"
              >
                {{
                  locales == "en"
                    ? items.strWorkTypeNameEN
                    : items.strWorkTypeNameTH
                }}
              </div>
              <div
                v-if="SelectedList[indexState].field == 'intUsergroupID'"
                class="d-flex align-items-center mt-1"
              >
                {{
                  items[SelectedList[indexState].field] == null
                    ? "-"
                    : items[SelectedList[indexState].field] == 1
                    ? $t("450")
                    : items[SelectedList[indexState].field] == 2
                    ? $t("451")
                    : items[SelectedList[indexState].field] == 3
                    ? $t("452")
                    : "-"
                }}
              </div>
              <div
                v-if="SelectedList[indexState].field == 'strPositionWorkPeriod'"
                class="d-flex mt-1"
                :class="locales == 'th' ? 'work-th' : 'work-en'"
              >
                <div
                  class="item"
                  v-if="
                    PositionWorkPeriod(
                      items[SelectedList[indexState].field],
                      0,
                      1
                    ) == ''
                      ? false
                      : true
                  "
                >
                  {{
                    PositionWorkPeriod(
                      items[SelectedList[indexState].field],
                      0,
                      1
                    )
                  }}
                </div>
                <div
                  class="item"
                  v-if="
                    PositionWorkPeriod(
                      items[SelectedList[indexState].field],
                      2,
                      3
                    ) == ''
                      ? false
                      : true
                  "
                >
                  {{
                    PositionWorkPeriod(
                      items[SelectedList[indexState].field],
                      2,
                      3
                    )
                  }}
                </div>
                <div
                  class="item"
                  v-if="
                    PositionWorkPeriod(
                      items[SelectedList[indexState].field],
                      4,
                      5
                    ) == ''
                      ? false
                      : true
                  "
                >
                  {{
                    PositionWorkPeriod(
                      items[SelectedList[indexState].field],
                      4,
                      5
                    )
                  }}
                </div>
              </div>
              <div
                v-if="SelectedList[indexState].field == 'intCpstatus'"
                class="d-flex align-items-center mt-1"
              >
                <Status
                  v-if="
                    items[SelectedList[indexState].field] == 0 ||
                    items[SelectedList[indexState].field] == false
                  "
                  :name="$t('62')"
                  labelclass="bg-black-500 "
                />
                <Status
                  v-if="
                    items[SelectedList[indexState].field] == 1 ||
                    items[SelectedList[indexState].field] == true
                  "
                  :name="$t('61')"
                  labelclass="bg-success"
                />
              </div>
              <div
                v-if="
                  SelectedList[indexState].field == 'intPositionStatus' ||
                  SelectedList[indexState].field == 'intEducationStatus' ||
                  SelectedList[indexState].field == 'intTemplatestatus' ||
                  SelectedList[indexState].field == 'blnUserGroupStatus'
                "
                class="mt-1"
              >
                <Status
                  v-if="items[SelectedList[indexState].field] == 0"
                  :name="$t(statusInactiveName)"
                  labelclass="bg-black-500 "
                />
                <Status
                  v-if="items[SelectedList[indexState].field] == 1"
                  :name="$t(statusActiveName)"
                  labelclass="bg-success"
                />
              </div>
            </td>
            <th
              scope="col"
              style="width: 0px"
              :class="device == 'mobile' ? 'px-0' : ''"
            >
              <div
                class="d-flex align-items-center"
                :class="device == 'mobile' ? 'justify-content-end' : ''"
              >
                <Button
                  v-if="blnPreview == true"
                  icon="OpenEye"
                  classBtnIcon="fill-secondary p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="OnPreview(items)"
                  classBtn="p-0 me-2"
                />
                <Button
                  v-if="
                    ((items['intFieldType'] !== 1 &&
                      items['intFieldType'] !== 5) ||
                      items['intFieldType'] == undefined) &&
                    blnUseDeleteButton == false
                  "
                  icon="EditFill"
                  :classBtnIcon="classBtnIconEdit"
                  widthIcon="20"
                  heightIcon="20"
                  @click="editdata(items['strID'],items['intWorkTypeID'])"
                  classBtn="p-0 m-0"
                  :style="
                    classBtnIconEdit == 'fill-black-500'
                      ? 'pointer-events: none;'
                      : ''
                  "
                />
                <Button
                  v-if="
                    (items['intFieldType'] !== 1 ||
                      items['intFieldType'] == undefined ||
                      items['intFieldType'] !== 5) &&
                    blnEditUserGroup == true &&
                    blnUseDeleteButton == false
                  "
                  icon="EdituserField"
                  classBtnIcon="fill-secondary p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="EditUserGroup(items['strID'])"
                  classBtn="p-0 ms-3"
                />
                <Button
                  v-if="
                    (((items['intFieldType'] !== 1 ||
                      items['intFieldType'] == undefined ||
                      items['intFieldType'] !== 5) &&
                      blnEditUserGroup == true) ||
                      blnEditUserRoleGroup == true) &&
                    blnUseDeleteButton == false
                  "
                  icon="EdituserList"
                  classBtnIcon="fill-secondary p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  @click="EditUserList(items['strID'])"
                  classBtn="p-0 ms-3"
                />
                <!-- <Button
                  v-if="items['candelete'] == true"
                  icon="DeleteFill"
                  classBtnIcon="fill-secondary"
                  class="p-0 m-0"
                  widthIcon=20
                  heightIcon=20
                  dataToggle="modal"
                  @click="intID = items['strID']"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->

                <!-- ลบข้อมูลไม่ได้ ปุ่มเป็นสีเทา -->
                <!-- <Button
                  v-if="
                    items['candelete'] == false &&
                    items['intFieldType'] == null &&
                    items['intDefaultset'] != 1
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500 "
                  class="tooltip-config p-0 m-0"
                  widthIcon=20
                  heightIcon=20
                  @click="intID = items['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('24')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->

                <Button
                  v-if="
                    (items['intFieldType'] == 1 ||
                      items['intFieldType'] == 5) &&
                    blnUseDeleteButton == false
                  "
                  icon="EditFill"
                  classBtnIcon="fill-black-500"
                  class="tooltip-config p-0 m-0"
                  widthIcon="20"
                  heightIcon="20"
                  tooltipPlacement="tooltip-left"
                  :tooltipMsg="$t('228')"
                  classBtn="tooltiper p-0 m-0"
                />
                <Button
                  v-if="blnUseDeleteButton == true"
                  icon="DeleteFill"
                  classBtnIcon="fill-secondary"
                  widthIcon="20"
                  heightIcon="20"
                  @click="confirmDelete(items['intMemberID'])"
                  :tooltipMsg="$t('149')"
                  classBtn=" p-0 m-0"
                />
                <!-- 
                <Button
                  v-if="
                    items['candelete'] == false && items['intDefaultset'] == 1
                  "
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500"
                  class="tooltip-config p-0 m-0"
                  widthIcon=20
                  heightIcon=20
                  @click="intID = items['strID']"
                  dataToggle="tooltip"
                  :tooltipMsg="$t('163')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
                <!-- ลบข้อมูลไม่ได้ ปุ่มเป็นสีเทา -->
                <!-- <Button
                  v-if="items['intCpstatus'] == 1"
                  icon="DeleteFill"
                  classBtnIcon="fill-black-500 "
                  class="tooltip-config p-0 m-0"
                  widthIcon=20
                  heightIcon=20
                  dataToggle="tooltip"
                  @click="intID = items['strID']"
                  :tooltipMsg="checkUIDelete(null, items) ? $t('25') : $t('24')"
                  exampleModal="#ModalDlt"
                  classBtn=" p-0 m-0"
                /> -->
              </div>
            </th>
          </tr>
        </tbody>
      </table>
      <NotFound
        v-if="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData?.length == 0
        "
        :style="
          ListDataTable.total == 0 ||
          ListDataTable == null ||
          listSortData?.length == 0
            ? 'height:fit-content;'
            : ''
        "
        class="border-bottom w-100 notfound"
        :name="nametable"
      />
    </div>
    <Pagination
      v-show="ListDataTable.total > 20"
      ref="Pagination"
      :v-bind="ListDataTable"
      class="col-12"
      :total="datatotal"
      :datatotal="listSortData?.length"
      :total-pages="currentPage"
      :perPage="perpage"
      :currentPage="page"
      @pagechanged="onPageChange($event)"
      @perpage="perpageChnage($event)"
    />
  </div>
</template>

<script>
import { SortArrow } from "@/components/icon/index";
import ConfigDisplay from "../configDisplay/configDisplay.vue";
import Label from "../label/label.vue";
import Status from "../label/statusLabel.vue";
import NotFound from "../notFound/notFound.vue";
import AltStatus from "../alert/alertStatus.vue";
import Alert from "../alert/alert.vue";
// import AlertEdt from "../alert/alertDuplicated.vue";
import Pagination from "../pagination/pagination.vue";

import Button from "../button/button.vue";

import { Icon } from "@iconify/vue";
import Enumerable from "linq";
import Loading1 from "@/components/Loading.vue";
import Loading2 from "@/components/Skeleton.vue";
import Loading3 from "@/components/waveloading.vue";
import Loading4 from "@/components/progress.vue";
import customfilter from "@/typescript/filter";
import VueCookies from "vue-cookies";

export default {
  name: "Table",
  props: {
    ListDataTable: {},
    filter: { type: Boolean },
    firstSort: { type: String },
    secondSort: { type: String },
    nametable: { type: String },
    progressScore: { type: Number },
    statusActiveName: { type: String, default: "89" },
    statusInactiveName: { type: String, default: "90" },
    blnEditUserGroup: { type: Boolean, default: false },
    blnEditUserRoleGroup: { type: Boolean, default: false },
    blnPaginationOfFont: { type: Boolean, default: false },
    blnUseDeleteButton: { type: Boolean, default: false },
    blnPreview: { type: Boolean, default: false },
    classBtnIconEdit: { type: String, default: "fill-secondary" },
  },
  emits: [
    "edit",
    "delete",
    "ConfigDisplay",
    "onSaveConfigDisplay",
    "EditUserGroup",
    "EditUserList",
    "onLoadData",
  ],
  data() {
    return {
      locales: "en",
      showListDataTable: true,
      progress: 0,
      sort: false,
      blnAltStatus: false,
      blnAltStatusunsucess: false,
      perpage: 20,
      page: null,
      listSortData: [],
      currentData: [],
      intID: null,
      currentPage: null,
      datatotal: null,
      fieldSort: null,
      SelectedList: [],
      showDisplay: false,
      activeFilters: [],
      isDown: null,
      startX: Number,
      scrollLeft: Number,
      hover: false,
      timerCount: 0,
      timerEnabled: false,
    };
  },
  beforeUpdate() {
    // this.locales = localStorage.getItem("language");
    this.locales = VueCookies.get("language")
  },
  components: {
    Loading1,
    Loading2,
    Loading3,
    Loading4,
    Pagination,
    Button,
    Alert,
    AltStatus,
    NotFound,
    Status,
    Label,
    ConfigDisplay,
    SortArrow,
    Icon,
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    hover(value) {
      if (value == false) {
        setTimeout(() => {
          this.timerCount++;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value < 2 && this.timerEnabled && this.hover == false) {
          setTimeout(() => {
            this.timerCount++;
          }, 1000);
        }
        if (value >= 2) {
          this.blnAltStatusunsucess = false;
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },

  methods: {
    filterLang(items) {
      // console.log(items);
      // console.log(this.defaultLang);
      if (items !== undefined && this.locales == null) {
        return customfilter.filterLang(items, this.locales);
      } else if (items !== undefined && this.locales != null) {
        return customfilter.filterLang(items, this.locales);
      }
    },
    onTabledown(e) {
      this.isDown = true;
      const slider = document.querySelector(".table-container");
      const startX = e.pageX - slider.offsetLeft;
      const scrollLeft = slider.scrollLeft;
      this.startX = startX;
      this.scrollLeft = scrollLeft;
    },

    ontableScrollMove(e) {
      const slider = document.querySelector(".table-container");
      if (this.isDown == true) {
        e.preventDefault();
        this.isDown = true;
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - this.startX) * 1; //scroll-fast
        slider.scrollLeft = this.scrollLeft - walk;
      } else {
        this.isDown = false;
      }
    },

    onSaveDisplay(data) {
      this.$emit("onSaveConfigDisplay", data);
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },

    onShowConfigDisplay() {
      this.showDisplay = !this.showDisplay;

      if (this.showDisplay == false) {
        this.$emit("ConfigDisplay");
      }
    },
    //เปลี่ยนจำนวนข้อมูลต่อ 1 หน้า
    perpageChnage(perPage) {
      this.perpage = perPage;
      //คำนวน จำนวนหน้าทั้งหมด
      this.currentPage = Math.ceil(this.datatotal / perPage);
      this.page = 1;
      this.$refs["tablebody"].scrollTop = this.$refs["tablebody"].scrollHeight;
      // console.log();
      //ย้อนกลับไปหน้าแรก
      this.$refs.Pagination.currentPage = 1;
      //เรียงลำดับของข้อมูล
      if (this.blnPaginationOfFont == false) {
        this.listSortData = this.$refs.Pagination.renderTableData(
          1,
          this.currentData
        );
      } else {
        this.$emit("onLoadData", { page: this.page, perpage: this.perpage });
      }
    },

    //เปลียนหน้า
    onPageChange(page) {
      this.page = page;
      this.$refs["tablebody"].scrollTop = this.$refs["tablebody"].scrollHeight;
      //เรียงลำดับของข้อมูล
      if (this.blnPaginationOfFont == false) {
        this.listSortData = this.$refs.Pagination.renderTableData(
          page,
          this.currentData
        );
      } else {
        this.$emit("onLoadData", { page: this.page, perpage: this.perpage });
      }
    },

    async setSelectedList(data) {
      // console.log(data);
      if (data != null) {
        if (data.isTrusted != true) {
          this.SelectedList = data;
        } else if (data.isTrusted == true) {
          this.SelectedList = this.ListDataTable.defaultField;
        }
      }

      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        // console.log("sort");
        this.fieldSort = this.firstSort;
        this.sort = false;
        this.listsortData(this.fieldSort, this.sort, true);
      } else if (s.length == 0) {
        // console.log("sort 1");
        this.fieldSort = this.secondSort;
        this.sort = false;
        this.listsortData(this.fieldSort, this.sort, false);
      }
      return true;
    },

    //คำนวนจำนวนหน้า และจัดเรียงข้อมูล โดยค่าเริ่มต้นเป็น 20 ข้อมูล ต่อ 1 หน้า
    calculatePage(total, data) {
      this.datatotal = total;
      this.currentPage = Math.ceil(total / this.perpage);
      if (this.blnPaginationOfFont == false) {
        this.page = 1;
      }
      // console.log(this.currentPage);
      if (this.blnPaginationOfFont == false) {
        this.listSortData = this.$refs.Pagination.renderTableData(1, data);
      } else {
        // console.log(this.page);
        if (this.page == null) {
          this.page = 1;
        } else {
          this.page;
        }
        // this.page == null ? (this.page = 1) : this.page;
        this.listSortData = this.$refs.Pagination.renderTableDataOfAPI(
          this.page,
          data
        );
      }
      // console.log(this.listSortData);
    },

    //ค้นหน้าข้อมูลจาก list ที่เก็บไว้ในเครื่อง
    SearchListDataTable(event, sliceByKey = null) {
      // console.log(sliceByKey);

      if (event != null || event != "") {
        if (sliceByKey !== null && Array.isArray(sliceByKey)) {
          let searchfield = this.SelectedList.filter((item) =>
            sliceByKey.includes(item.field)
          );
          this.listSortData = this.filterSelected(
            this.currentData.slice(),
            searchfield,
            event
          );
          // console.log(this.SelectedList);
        } else {
          // this.listSortData = this.filterSelected(
          //   this.currentData.slice(),
          //   this.SelectedList,
          //   event
          // );
          if (Array.isArray(this.currentData)) {
            this.listSortData = this.filterSelected(
              this.currentData.slice(),
              this.SelectedList,
              event
            );
          } else {
            console.log("this.currentData is not an array.");
          }
        }
      }

      const s = this.SelectedList.filter((item) => {
        return item.field == this.firstSort;
      });

      if (s.length > 0) {
        // console.log("sort");
        this.fieldSort = this.firstSort;

        // this.listSortData = SortArray.multiplyArraySort(
        //   this.listSortData,
        //   this.firstSort,
        //   this.secondSort
        // );
        this.calculatePage(this.listSortData.length, this.listSortData);

        this.sort = false;
      } else if (s.length == 0) {
        // console.log("sort 1");

        this.fieldSort = this.secondSort;

        // this.listSortData = SortArray.SingleArraySort(
        //   this.listSortData,
        //   this.fieldSort
        // ).reverse();
        this.calculatePage(this.listSortData.length, this.listSortData);
        this.sort = false;
      }
    },

    filterSelected(array, filters, event) {
      // console.log(array);
      const filterKeys = Object.keys(filters);
      return array.filter((item) => {
        // validates all filter criteria
        return filterKeys.some((key) => {
          // ignores non-function predicates
          return (
            item[filters[key].field]
              .toString()
              .toLowerCase()
              .indexOf(event.toString().toLowerCase()) !== -1
          );
        });
      });
    },

    //ยกเลิกการค้นข้อมูล
    onClean() {
      this.listSortData = this.ListDataTable.data;
      this.currentData = this.ListDataTable.data;
      this.setSelectedList(this.SelectedList);
      if (this.listSortData !== undefined) {
        this.calculatePage(this.listSortData.length, this.currentData);
      }
    },
    //ตรวจสอบสถานะการเรียงข้อมูล
    checkTableSort(field) {
      // ถ้า fillter แล้วไม่มีข้อมูล จะไม่แสดงลูกศร (sort ไม่ได้)
      if (this.listSortData.length == 0) {
        return false;
      }

      if (field == this.fieldSort) {
        return true;
      } else if (field != this.fieldSort) {
        return false;
      }
    },
    //เรียงข้อมูล จากหัวตาราง
    listsortData(field, it, s) {
      // ถ้า fillter แล้วไม่มีข้อมูล จะ sort ไม่ได้
      if (this.listSortData.length == 0) {
        return;
      }
      this.fieldSort = field;
      if (field == null) {
        return false;
      }

      if (it === false) {
        s = true;
      }

      //sort A->Z
      if (s == true) {
        if (this.fieldSort !== this.firstSort) {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) => {
              if (this.fieldSort == "strWorktypeEN") {
                if (this.locales == "en") {
                  return r.strWorkTypeNameEN.toString().toLowerCase();
                } else {
                  return r.strWorkTypeNameTH.toString().toLowerCase();
                }
              } else if (this.fieldSort != "strWorktypeEN") {
                return r[this.fieldSort].toString().toLowerCase();
              }
            })

            .toArray();
          this.sort = false;
          this.currentData = reversed.reverse();
          // console.log(this.currentData);
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
          // console.log(this.listSortData);
        } else {
          const reversed = Enumerable.from(this.currentData)
          .orderByDescending((r) =>
              r[this.fieldSort].toString().toLowerCase()
            )

            .toArray();
          this.sort = false;
          this.currentData = reversed;
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
      //sort Z->A
      if (s == false) {
        if (this.fieldSort !== this.firstSort) {
          this.currentData = Enumerable.from(this.currentData)
            .orderByDescending((r) => {
              if (this.fieldSort == "strWorktypeEN") {
                if (this.locales == "en") {
                  return r.strWorkTypeNameEN.toString().toLowerCase();
                } else {
                  return r.strWorkTypeNameTH.toString().toLowerCase();
                }
              } else {
               
                return r[this.fieldSort].toString().toLowerCase();
              }
            })

            .toArray();

          // var reversed = SortArray.SingleArraySort(
          //   this.currentData,
          //   this.fieldSort
          // );
          //this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.sort = true;
          this.calculatePage(this.listSortData.length, this.currentData);
        } else {
          const reversed = Enumerable.from(this.currentData)
            .orderByDescending((r) =>
              r[this.fieldSort].toString().toLowerCase()
            )

            .toArray();
          this.sort = true;
          this.currentData = reversed.reverse();
          this.listSortData = this.currentData;

          this.calculatePage(this.listSortData.length, this.currentData);
        }
      }
    },

    //ตรวจ วิทยาเขต
    checkmainListDataTable(field, data) {
      if (
        (field == "strCampusNameEN" && data["intCampusMain"] == 1) ||
        (field == "strCampusNameTH" && data["intCampusMain"] == 1) ||
        (data["intDefaultset"] == 1 && field == "strUsertemplatename")
      ) {
        return true;
      } else {
        return false;
      }
    },

    checkUIDelete(field, data) {
      if (field == null) {
        if (data["intCampusMain"] == 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    EditUserList(id) {
      // console.log(id);
      this.$emit("EditUserList", id);
    },
    EditUserGroup(id) {
      // console.log(id);
      this.$emit("EditUserGroup", id);
    },

    editdata(id,WorkTypeID) {
      // console.log(id);
      this.$emit("editWorkTypeID", WorkTypeID);
      this.$emit("edit", id);
    },
    deleteListDataTable() {
      return true;
    },

    confirmDelete(state) {
      // console.log(state);
      this.$emit("delete", state);
    },
    checkstateofdelete(state) {
      this.timerEnabled = false;
      this.timerCount = 0;
      if (state == true) {
        setTimeout(() => {
          this.checkstateofalert();
        }, 2000);
        this.blnAltStatus = true;
        setTimeout(() => {
          this.blnAltStatus = false;
        }, 2000);
        return true;
      } else if (state == false) {
        setTimeout(() => {
          this.checkstateofalert();
        }, 2000);
        this.blnAltStatusunsucess = true;

        this.timerEnabled = true;
        return true;
      }
    },
    //แสดงข้อมูลบนตาราง
    renderTableData(req_per_page, page_no) {
      var all_data = this.ListDataTable.data;

      var resp;
      //เรียกข้อมูลใน array จากลำดับเริ่มต้นไปยังอีกจุด
      resp = all_data.slice(
        req_per_page * (page_no - 1),
        req_per_page * page_no
      );
      this.listSortData = resp;
    },

    ConvertUserField(field) {
      if (field != null) {
        let data = [
          {
            id: 1,
            lang: "120", //Text
          },
          {
            id: 2,
            lang: "121", //Number
          },
          {
            id: 3,
            lang: "123", //Currency
          },
          {
            id: 4,
            lang: "140", //Time
          },
          {
            id: 5,
            lang: "139", //Date
          },
          {
            id: 6,
            lang: "146", //Date & Time
          },
          {
            id: 7,
            lang: "70", //Email
          },
          {
            id: 8,
            lang: "203", //Image
          },
        ];
        let s = data.filter((elem) => elem.id == field);
        if (s.length != 0) {
          return typeof s !== "undefined" ? s[0].lang : "";
        } else {
          return "-";
        }
      } else if (field == null) {
        return "-";
      }
    },
    ConvertUserFieldType(field) {
      if (field != null) {
        let data = [
          { id: 3, lang: "135" }, //Variable
          { id: 1, lang: "136" }, //control
          { id: 5, lang: "136" }, //control
          { id: 4, lang: "137" }, //Lookup
          { id: 2, lang: "138" }, //Fixed
        ];
        let s = data.filter((elem) => elem.id == field);
        // console.log(s[0]);
        return typeof s[0] !== "undefined" ? s[0].lang : "";
      } else if (field == null) {
        return "-";
      }
    },
    PositionWorkPeriod(work, value1, value2) {
      const strPositionWorkPeriod = work.toString().split("");

      return (
        // workPeriod
        Number(strPositionWorkPeriod[value1] + strPositionWorkPeriod[value2]) !=
          0
          ? //   ? Number(strPositionWorkPeriod[value1] + strPositionWorkPeriod[value2])
            //   : '\u00A0 \u00A0') +
            Number(
              strPositionWorkPeriod[value1] + strPositionWorkPeriod[value2]
            ) +
              (value1 == 0
                ? " " + this.$t("413")
                : value1 == 2
                ? " " + this.$t("414")
                : value1 == 4
                ? " " + this.$t("415")
                : "")
          : "0" +
              (value1 == 0
                ? " " + this.$t("413")
                : value1 == 2
                ? " " + this.$t("414")
                : value1 == 4
                ? " " + this.$t("415")
                : "")
      );
    },
    ConvertParameterValueType(field) {
      if (field != null) {
        let data = [
          { id: null, lang: "79" }, //all
          { id: 1, lang: "120" }, //text
          { id: 2, lang: "121" }, //number
          { id: 3, lang: "122" }, //boolean
          { id: 4, lang: "123" }, //currency
        ];
        let s = data.filter((elem) => elem.id == field);
        return typeof s !== "undefined" ? s[0].lang : "";
      } else if (field == null) {
        return "-";
      }
    },
    OnPreview(items) {
      this.$emit("OnPreview", items);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}

.zoom-enter-active {
  animation: bounce-in 150ms;
}

.zoom-leave-active {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}

.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.sorted {
  transform: rotate(180deg) !important;
  position: relative;
}

.hidden-sort {
  display: none;
}

.table-mobile {
  max-height: calc(100vh - 330px) !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.table-container {
  overflow-x: auto;
  overflow-y: auto;
  max-height: calc(100vh - 303px);
  width: auto;

  .table {
    overflow-y: auto;

    // width: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: $white;
      z-index: 2;

      &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: $black-300;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }

      tr {
        th {
          height: 48px;
        }

        th:first-child {
          position: sticky;
          left: 0;
          background-color: $white;
          z-index: 2;
        }

        th:last-child {
          position: sticky;
          right: 0;
          background-color: $white;
          z-index: 2;
        }
      }
    }

    tbody {
      tr {
        th:first-child {
          position: sticky;
          left: 0;
          background-color: #fff;
          z-index: 0;
        }

        th:last-child {
          z-index: 0;
          position: sticky;
          right: 0;
          background-color: #fff;
        }

        &:hover {
          th:first-child {
            background-color: $black-50 !important;
          }

          th:last-child {
            background-color: $black-50 !important;
          }
        }
      }
    }

    td {
      padding-right: 1rem;
      height: 44px;
      width: fit-content;

      // padding: 15px auto;
      div {
        white-space: nowrap;
      }
    }

    th {
      padding-right: 1rem;
      white-space: nowrap;
    }

    tr {
      padding-right: 1rem;
      white-space: nowrap;
    }
  }
}

.sorting-active {
  border-radius: 50%;
  padding: 8px;
  width: 24px;
  height: 24px;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: $black-300 !important;
  }
}

.hover-none {
  &:hover {
    background: none !important;
  }
}

.overlay-in {
  opacity: 0.6;
  transition: 1s;
}

.overlay-out {
  opacity: 0;
  transition: 1s;
}

.rotate-180 {
  transform: rotate(180deg);
}

.fill-secondary {
  fill: $secondary !important;
}

// .contrue {
//   width: 100% !important;
// }
// .confalse {
//   width: fit-content !important;
// }
.none-scrollbar {
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.alert-box {
  background-color: rgba(255, 255, 255, 0.5);
  width: calc(100% - 220px);
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
}

.work-en {
  .item {
    width: 37px !important;
  }
}

.work-th {
  .item:first-child {
    width: 35px !important;
  }

  .item:last-child {
    width: 39px !important;
  }

  .item:nth-child(2) {
    width: 55px !important;
  }
}
</style>
