<template>
  <!-- <span > -->

  <span
    class="box"
    tabindex="0"
    :data-tooltip="tooltipMsg"
    :class="tooltipDelay + ' ' + tooltipPlacement + ' ' + tooltip"
  >
    <button
      class="toggle position-relative rounded-1 p-1 btn m-0 p-0 border-0"
      disabled
    >
      <input
        type="checkbox"
        name=""
        id=""
        v-model="checked"
        @change="checkToggle()"
        ref="checkbox"
      />

      <div
        class="w-100 rounded-1 slider-disable"
        :class="checked == true || checked == 1 ? 'active-slider' : ''"
      >
        <div class="slideStart"></div>

        <span
          v-if="checked == true || checked == 1"
          class="px-1 text-toggle-fst text-white"
          :class="
            checked == true || checked == 1 ? 'text-toggle-fst-active' : ''
          "
          >{{ open }}</span
        >
        <span v-else class="px-1 text-toggle-lst text-white">{{ close }}</span>
        <div
          class="slideEnd rounded-1"
          :class="checked == true || checked == 1 ? 'slideEnd-active' : ''"
        ></div>
      </div>
    </button>
  </span>
  <!-- </span> -->
</template>
<script scoped>
export default {
  data() {
    return {
      checked: null,
    };
  },
  created() {
    // console.log(this.$refs.checkbox);
    if (this.checked == false || this.checked == 0) {
      this.$emit("input", false);
    }
  },
  mounted() {
    if (this.check == null) {
      this.checked = true;
    } else {
      this.checked = this.check;
    }
  },
  methods: {
    checkToggle() {
      this.$emit("input", this.checked);
      // console.log(this.checked)
    },
    // กำหนดค่าใน Tooltip ของ Bootstrap

    // widthToggle() {
    //   let width = document.querySelector(".toggle");
    //   if(width.style.width > "76px"){
    //     width.style.width = "130px"
    //   }
    //   console.log(width.offsetWidth);
    // },
  },
  props: {
    open: { type: String },
    close: { type: String },
    disabled: { type: Boolean },
    tooltipMsg: { type: String, default: "" },
    tooltipDelay: { type: String, default: "150ms" },
    tooltipPlacement: { type: String, default: "tooltip-top" },
    tooltip: { type: String, default: "default" },
    check: { type: Boolean },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-top {
  &::before {
    left: 50%;
    bottom: 160%;
    transform: translateX(-50%);
  }
  &::after {
    left: 47%;
    bottom: calc(100% + 8px);
    transform: translateX(-50%);
  }
}
.tooltip-top-left-mobile {
  &::before {
    left: 150% !important;
    bottom: 130%;
    transform: translateX(-50%);
  }
  &::after {
    left: 47%;
    bottom: calc(100% + 8px);
    transform: translateX(-50%);
  }
}
.tooltip-bottom {
  &::before {
    left: 50%;
    top: 110%;
    transform: translateX(50%);
  }
  &::after {
    left: 47%;
    bottom: calc(0% - 8px);
    transform: translateX(-50%);
  }
}
.tooltip-left {
  &::before {
    right: 107%;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    right: calc(100% + 8px);
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
  }
}
.tooltip-right {
  &::before {
    left: 107%;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    left: calc(100% + 8px);
    top: 45%;
    transform: rotate(45deg) translateY(-50%);
  }
}
.tooltiper {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}
.tooltiper-mobile {
  position: relative;
  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition-property: opacity visibility;
      transition-duration: 150ms;
      transition-delay: v-bind("tooltipDelay");
    }
  }
  &::before {
    z-index: 9999;
    position: absolute;
    // left: 50%;
    // top: -28px;
    // transform: translate(-50%, -50%);
    content: attr(data-tooltip);
    color: $white;
    background-color: $black;
    padding: 8px;
    border-radius: 4px;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    font-size: 10px !important;
    font-weight: 400;
    transition-property: opacity visibility;
    transition-duration: 150ms;
    white-space: pre;
    // transition-delay: v-bind("delay");
  }
  &::after {
    position: absolute;
    // left: 50%;
    // top: -14px;
    // transform: translate(-50%, -50%);
    z-index: 9999;
    content: "";
    background-color: $black;
    width: 9px;
    height: 9px;
    transform: rotate(-45deg);
    opacity: 0;
    visibility: hidden;
    transition-property: opacity visibility;
    transition-duration: 100ms;
    // transition-delay: v-bind("delay");
  }
}

.active-slider {
  background-color: $primary-500 !important;
}

.box {
  width: fit-content !important;
  .toggle {
    width: fit-content;
    min-width: 76px;
    height: fit-content;
    position: relative;
    transition: 0.2s;
    display: inline-block;
    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 20;
      transition: 0.2s;
      // &:checked + .slider {
      //   background-color: $primary-500;
      //   transition: 0.2s;
      //   border: solid 1px $primary-200;
      // }
      // &:checked + .toggle-slide {
      //   background-color: $primary-500 !important;
      //   transition: 0.2s !important;
      //   border: solid 1px $primary-200 !important;
      // }
      // &:checked + .slider .slideEnd {
      //   position: absolute;
      //   left: calc(100% - 29px);
      //   transition: 0.2s;
      //   background-color: $primary-50;
      //   transform: translateY(-50%);
      // }
      // &:checked + .slider .text-toggle-fst {
      //   transform: translateX(-25px);
      //   transition: 0.2s;
      // }
      //   &:checked + .slider .slideEnd{
      //       display: none;
      //   }
    }
    .slider {
      background-color: $black-500;
      min-width: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      height: 36px;
      position: relative;
      padding: 2px;
      border: solid 1px $black-200;
      .slideStart {
        width: 25px;
        height: 100%;
        background-color: $white;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
      }
      .slideEnd {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $black-200;
        width: 25px;
        height: 90%;
        transition: 0.2s;
        margin-left: 2px;
      }
      .slideEnd-active {
        left: calc(100% - 29px) !important;
        transition: 0.2s !important;
        background-color: $primary-50 !important;
        transform: translateY(-50%) !important;
      }
      .text-toggle-fst {
        transition: 0.2s;
        transform: translateX(0);
      }
      .text-toggle-fst-active {
        transform: translateX(-25px);
        transition: 0.2s;
      }
    }
  }
}
// disabled
.slider-disable {
  background-color: $black-400;
  min-width: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  height: 36px;
  position: relative;
  padding: 2px;
  border: solid 1px $black-200;
  .slideStart {
    width: 25px;
    height: 100%;
    background-color: $white;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;
  }
  .slideEnd {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: $black-200;
    width: 25px;
    height: 90%;
    transition: 0.2s;
    margin-left: 2px;
  }
  .slideEnd-active {
    left: calc(100% - 29px) !important;
    transition: 0.2s !important;
    background-color: $primary-50 !important;
    transform: translateY(-50%) !important;
  }
  .text-toggle-fst {
    transition: 0.2s;
    transform: translateX(0);
  }
  .text-toggle-fst-active {
    transform: translateX(-25px);
    transition: 0.2s;
  }
}
// .toggle {
//   position: relative;
//   display: inline-block;
//   min-width: 76px !important;
//   width: fit-content !important;
//   height: 34px;
//   cursor: pointer;
//   input[type="checkbox"] {
//     opacity: 0;
//     width: 0;
//     height: 0;
//     &:checked + .slider {
//       transition: 0.2s;
//       background-color: $primary-500;
//     }
//     &:checked + .slider::before {
//       background-color: $primary-50;
//       transition: 0.2s;
//       transform: translateY(50%) translateX(43px);
//     }
//   }
//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: $black-500;
//     transition: 0.2s;
//     border-radius: 4px;
//     border: solid 1px $primary-200;
//     width: 100%;
//     &::before {
//       position: absolute;
//       content: "";
//       height: 26px;
//       width: 23px;
//       left: 3px;
//       bottom: 50%;
//       background-color: $white;
//       transition: 0.2s;
//       border-radius: 4px;
//       transform: translateY(50%);
//     }
//   }
// }
// .label-checked {
//   position: absolute;
//   top: 50%;
//   left: 10%;
//   transform: translateY(-50%);
// }
// .label-uncheck {
//   position: absolute;
//   top: 50%;
//   right: 10%;
//   transform: translateY(-50%);
// }
</style>
