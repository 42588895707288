<template>
  <div class="box">
    <div class="items1"></div>
    <div class="items2"></div>
    <div class="items3"></div>
    <div class="items2"></div>
    <div class="items1"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.box {
  //   background: #1C1C1C;
  //   padding: 5px;
  width: fit-content;
  height: 100px;
  //   border-radius: 15px;
  //   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
}
.box div {
  margin: 0 2px;
}
.items1,
.items2,
.items3 {
  width: 5px;
  height: 30px;
  border-radius: 25px;
  background-color: $secondary-600;
//   background-image: linear-gradient(315deg, $secondary-600 0%);
}
.items1 {
  animation: oj1 1s ease-in-out infinite;
}
.items2 {
  animation: oj1 1s ease-in-out infinite;
  animation-delay: 0.3s;
}
.items3 {
  animation: oj1 1s ease-in-out infinite;
  animation-delay: 0.6s;
}
@keyframes oj1 {
  0% {
    height: 30px;
  }
  50% {
    height: 10px;
  }
  100% {
    height: 30px;
  }
}
</style>