<template>
  <div
    :draggable="blndraggable"
    @transitionStart="transitionStart"
    @transitionEnd="transitionEnd"
    @dragover.prevent.stop="onDragOver"
    @dragstart.stop="onDragStart"
    @dragend.stop="onDragEnd"
    @dragenter.prevent
    ref="draggableItemEl"
    :class="isDragging ? 'dragging mb-2' : ''"
  >
    <slot></slot>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useDraggableItem } from "../typescript/composables/draggable";

export default {
  name: "DraggableItem",
  props: {
    item: Object,
    position: Number,
    containerId: Number,
    groupType: String,
    fieldType: String,
    blndraggable: {
      default: true,
      type: Boolean,
    },
  },

  setup(props, context) {
    const { item, position, containerId, groupType, fieldType } = toRefs(props);
    const {
      draggableItemEl,
      isDragging,
      onDragStart,
      onDragOver,
      onDragEnd,
      transitionStart,
      transitionEnd,
    } = useDraggableItem(
      item,
      position,
      containerId,
      context,
      groupType,
      fieldType
    );

    return {
      draggableItemEl,
      isDragging,
      onDragStart,
      onDragOver,
      onDragEnd,
      transitionStart,
      transitionEnd,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
