<template>
  <div
    class="
      my-3
      heading
      rounded-2
      w-100
      d-flex
      flex-row
      align-items-center
      justify-content-start
    "
  >
    <div
      class="
        profile
        rounded-2
        d-flex
        align-items-center
        justify-content-center
        me-3
      "
    >
      <!-- Heading รูปภาพ -->
      <div class="img">
        <Avatar
          image="https://images.unsplash.com/photo-1652528042257-ebaa90786218?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688"
        />
      </div>
    </div>

    <!-- ข้อมูลของ User (จะเปลี่ยนตามการพิมพ์) -->
    <div class="d-flex align-items-start justify-content-center flex-column">
      <div
        class="
          d-flex
          flex-wrap
          template-active
          align-items-center
          rounded-2
          mb-1
        "
      >
        <span class="fnt-medium fnt-h5 me-1">Mr. Porawee Panmas</span>
      </div>
      <!-- </div> -->
      <div class="heading-list mb-1 w-100 rounded-2 d-flex align-items-center">
        <span class="fnt-regular fnt-h6 me-1">60113669</span>
      </div>
      <div class="heading-list mb-1 w-100 rounded-2 d-flex align-items-center">
        <span class="fnt-regular fnt-body me-1">Informatics/ Digital Information Management</span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/avatar/avatar.vue";
export default {
  components: {
    Avatar,
  },
};
</script>

<style lang="scss" scoped>
.inputfile {
  height: 100px !important;
  width: 88px !important;
}
.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>