<template>
  <div
    class="popup-container align-items-center test"
    v-if="blnShowPopup == true && intWrongLogin < intPinCount"
  >
    <transition name="zoom" appear>
      <Alert
        v-if="blnShowPopup == true && intWrongLogin < intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="
          altMsgBox !== '358'
            ? $t(altMsgBox)
            : $t('358', { branch: getCampusName })
        "
        :Helper="$t('355')"
        ref="AlertPopup"
        @confirm="onConfirm($event)"
        :blnLoading="blnloadConfirm"
        :showpass="true"
        :btnpop="true"
        classIcon="fill-secondary"
        :errorMsg="$t(errorMsg)"
        :blnError="blnError"
        :sub="showSub == true ? $t(sub) : ''"
      />
    </transition>
    <transition name="fade" appear>
      <div
        class="overlay"
        v-if="blnShowPopup == true && intWrongLogin < intPinCount"
        @click="blnloadConfirm == false ? onConfirm(false) : ''"
      ></div>
    </transition>
  </div>
  <div
    class="popup-container align-items-center"
    v-if="blnShowPopup == true && intWrongLogin >= intPinCount"
  >
    <transition name="zoom">
      <Alert
        v-if="blnShowPopup == true && intWrongLogin >= intPinCount"
        type="Warning"
        classOkBtn="btn-secondary"
        artIcon="WarningOutline"
        :altMsgBox="$t('356', { number: intPinCount, phoneno: strTel })"
        :Helper="$t('345')"
        @confirm="reloadpage()"
        ref="AlertPopup"
        :btnpop="true"
        classIcon="fill-warning"
        :Cancel="false"
      />
    </transition>
    <transition name="fade">
      <div
        class="overlay"
        v-if="blnShowPopup == true && intWrongLogin >= intPinCount"
        @click="reloadpage()"
      ></div>
    </transition>
  </div>

  <div
    class="toggle d-flex flex-column align-items-start p-0 mb-3 justify-content-start"
  >
    <div class="d-flex flex-column align-items-start p-0 me-5 mb-3">
      <Label
        :label="name == undefined ? '' : $t(name)"
        class="fnt-subtitle fnt-medium"
      />
      <!-- <p class="p-0 m-0 fnt-body text-primary fw-bolder" v-if="checkshow">{{open}}</p>
      <p class="p-0 m-0 fnt-body text-muted" v-else>{{close}}</p> -->
    </div>

    <div
      v-show="inactive == false"
      class="w-100"
      :class="disabled == true ? 'pe-none opacity-75' : ''"
    >
      <Toggle
        ref="Toggle"
        @input="checkToggle($event)"
        :open="$t(open)"
        :close="$t(close)"
      />
    </div>

    <div v-show="inactive == true" class="w-100">
      <Toggledisable
        ref="Toggledisable"
        @input="checkToggle($event)"
        :open="$t(open)"
        :close="$t(close)"
        :check="enable"
        :tooltipMsg="tooltipMessage == '' ? $t(tooltipMsg) : $t('404')"
        :tooltipDelay="tooltipDelay"
        :tooltipPlacement="
          device == 'mobile' && tooltipPlacement == 'tooltip-top'
            ? 'tooltip-top-left-mobile'
            : 'tooltip-top'
        "
        :tooltip="device == 'mobile' ? 'tooltiper-mobile' : 'tooltiper'"
      />
    </div>
  </div>
</template>

<script>
import Label from "@/components/label/label.vue";
import Toggle from "../toggle/toggle.vue";
import Toggledisable from "../toggle/tooltipDisable.vue";
import Alert from "@/components/alert/alertPopup.vue";
import axios from "axios";
import urlapi from "@/typescript/urlapi/url";
import AdsInventories from "@/typescript/inventory";
import Login from "@/typescript/login";
import VueCookies from "vue-cookies";
import cookiesPath from "@/typescript/urlapi/cookiesPath";
export default {
  data() {
    return {
      tooltipMessage: "",
      enable: null,
      checkshow: null,
      inactive: false,
      tooltip: true,
      alertMsgStatus: false,
      blnShowPopup: false,
      blnConfirm: false,
      intWrongLogin: 0,
      locales: "en",
      intPinCount: 3,
      strTel: "",
      blnError: false,
      showSub: false,
      errorMsg: "",
      blnloadConfirm: false,
    };
  },

  components: {
    Toggle,
    Alert,
    Toggledisable,
    Label,
  },

  updated() {
    this.locales = localStorage.getItem("language");
  },
  computed: {
    getCampusName() {
      // console.log(localStorage.getItem("strCampusNameTH"));

      // console.log(this.locales);
      return this.locales == "EN" || this.locales == "en"
        ? localStorage.getItem("strCampusNameEN")
        : localStorage.getItem("strCampusNameTH");
    },
  },
  methods: {
    Valid() {
      return true;
    },
    checkToggle(input) {
      if (this.blnConfirm == false) {
        this.checkshow = input;
        // this.firstcheck = input;

        // console.log(this.enable);
        this.$emit("input", { value: this.checkshow, confirm: false });
      } else if (this.blnConfirm == true) {
        this.checkshow = input;
        this.blnShowPopup = true;
        if (this.blnUserinfo == true) {
          this.$emit("input", { value: this.checkshow, confirm: false });
        }
      }
    },

    changeToggle(input) {
      this.checkshow = input;
      // console.log(this.checkshow);
      // console.log(this.$refs.Toggledisable);

      this.$refs.Toggledisable.checked = this.checkshow;

      this.$refs.Toggle.checked = this.checkshow;
      this.$emit("input", { value: this.checkshow, confirm: true });
    },

    changeInactive(state) {
      this.inactive = state;
    },
    async onConfirm(state) {
      try {
        this.blnError = false;
        this.blnloadConfirm = true;
        // this.blnShowPopup = false;
        // console.log(state);
        if (state !== false && state != null && state != "") {
          var res = await Login.login(
            AdsInventories.userinfo.strUsername,
            state,
            axios,
            urlapi
          );
          // console.log(res);
          this.blnloadConfirm = false;
          this.strTel = res.tel;
          if (res.logined == true) {
            this.blnError = false;
            this.blnShowPopup = false;
            this.$emit("input", { value: this.checkshow, confirm: true });
            return true;
          } else if (res.logined == false) {
            this.intWrongLogin = res.WrongLogin;
            this.strTel = res.tel;
            this.intPinCount = res.pincode;
            this.errorMsg = this.$t("357", {
              number: this.intPinCount - this.intWrongLogin,
            });
            this.blnError = true;
            this.blnShowPopup = true;
            if (this.intWrongLogin >= this.intPinCount) {
                VueCookies.remove("UserInfo", "/", cookiesPath);
              }
          }
        }
        //ถ้า login ไม่สำเร็จหรือกด Cancel ค่าจะเปลี่ยนกลับไปเป็นค่าก่อนหน้า
        else {
          if (state == false) {
            this.blnShowPopup = false;
            this.blnloadConfirm = false;
            this.$refs.Toggle.checked = !this.checkshow;

            this.$emit("input", {
              value: !this.checkshow,
              confirm: false,
            });
            return true;
          } else if (state == null || state == "") {
            this.errorMsg = this.$t("202", { fieldname: "(PIN)" });
            this.blnShowPopup = true;
            this.blnError = true;
          }
        }
      } catch (error) {
        this.blnloadConfirm = false;
        console.log(error);
      }
    },
    reloadpage() {
      Login.logout();
      //ไปยังหน้าแรก
      // setTimeout(() => {
      //   this.$router.push("/login");
      //   this.$router.go();
      // }, 100);
    },
    alertRequest() {
      // console.log(this.alertMsg);
      if (this.alertMsg !== undefined) {
        this.msgError = this.$t(this.alertMsg, {
          fieldname: this.$t(this.name).toLowerCase(),
        });
      }
      this.alertMsgStatus = true;
    },
  },
  props: {
    name: { type: String },
    open: { type: String },
    close: { type: String },
    value: { type: Number, default: 0 },
    alertMsg: { type: String },
    altMsgBox: { type: String },
    tooltipMsg: { type: String, default: "" },
    tooltipDelay: { type: String, default: "150ms" },
    tooltipPlacement: { type: String, default: "tooltip-top" },
    sub: { type: String, default: "" },
    blnUserinfo: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  mounted() {
    setTimeout(() => {
      if (this.value == 1 || this.value == true) {
        this.$refs.Toggle.checked = true;
        this.$emit("input", { value: 1, confirm: true });
      } else if (this.value == 0 || this.value == false || this.value == null) {
        this.$emit("input", { value: 0, confirm: true });
        if (this.$refs.Toggle != undefined) {
          this.$refs.Toggle.checked = false;
        }
      }
      // console.log(this.value);
    }, 1000);
    // if(this.tooltipMessage != null){
    //     this.tooltipMsg == this.tooltipMessage;
    //   }
    //   // console.log(this.tooltipMsg)
    //   console.log(this.tooltipMessage)
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-from,
.zoom-leave-to {
  opacity: 0;
}
.zoom-enter-active {
  animation: bounce-in 150ms;
}
.zoom-leave-active {
  opacity: 0;
}
@keyframes bounce-in {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.popup-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: start;
  justify-content: center;
  .overlay {
    background-color: rgba(0, 0, 0, 0.263);
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
</style>
