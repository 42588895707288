import { number } from "@intlify/core-base";
import { ref, onMounted, onUpdated, watch, SetupContext, Ref } from "vue";
import { DraggableItem } from "../types/draggable-item.interface";
import {
  changeArrayOrder,
  changeArrayOrderDupplicate,
} from "../utils/change-order";
import { getIdGenerator } from "../utils/id-generator";
import { throttle } from "../utils/throttle";
import { toOriginalArray, toDraggableItems } from "../utils/to-draggable-items";

const itemCurrentlyDragging = ref<DraggableItem>();
const containerIdCurrentlyDraggedOver = ref<number>();
const itemgroupTypeID = ref<string>();

const containerGroup = ref<string>();
let transitioning = false;
const containerIdGenerator = getIdGenerator();
let item_ID = 0;
const useDraggableContainer = (
  originalItems: Ref<Array<any>>,
  context: SetupContext,
  groupType: Ref<string>
) => {
  const id = containerIdGenerator();

  const items = ref<Array<DraggableItem>>(
    toDraggableItems(originalItems.value, groupType.value)
  );
  // const items = ref<Array<DraggableItem>>(
  //   toDraggableItems(originalItems.value, groupType.value)
  // );

  // update v-model when dropped
  watch(itemCurrentlyDragging, () => {
    if (itemCurrentlyDragging.value) {
      return;
    }
    context.emit("update:modelValue", toOriginalArray(items.value));
  });

  // case when an item is being dragged to another container
  watch(containerIdCurrentlyDraggedOver, () => {

    // console.log(itemCurrentlyDragging.value?.data?.strDatatype)
    if (containerIdCurrentlyDraggedOver.value === id) {
      return;
    } else if (containerIdCurrentlyDraggedOver.value !== id) {

      if (containerGroup.value === "clone" && itemCurrentlyDragging.value?.data?.strDatatype !== "8" && itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
        // console.log("case 1");
        if (
          itemCurrentlyDragging.value?.draggable !== "" &&
          items.value[0] !== undefined
        ) {
          if (itemCurrentlyDragging.value?.draggable !== "original") {
            if (
              items.value[0].draggable === "clone" &&
              itemCurrentlyDragging.value?.itemID !== undefined
              && itemCurrentlyDragging.value?.fieldtype !== "fieldset"
              && itemCurrentlyDragging.value?.data?.strDatatype !== "8"
            ) {
              // console.log("case 1 - 3");


              //console.log(itemCurrentlyDragging.value?.itemID);
              //console.log(items.value);
              items.value = items.value.filter((item) => {
                if (itemCurrentlyDragging.value?.itemID !== undefined) {
                  return item.itemID !== itemCurrentlyDragging.value?.itemID;
                } else {
                  return item.itemID !== item_ID;
                }
              });


              // items.value = items.value.filter(
              //   (item) => item.id !== itemCurrentlyDragging.value?.id
              // );
              // items.value = items.value;
              // console.log(items.value);
              // console.log(items.value)
            } else if (items.value[0].draggable === "clone" && itemCurrentlyDragging.value?.fieldtype !== "fieldset" && itemCurrentlyDragging.value?.data?.strDatatype !== "8") {
              // console.log("case 1 - 4");
              // console.log(items.value);
              if (items.value.length <= 1) {
                return;
              }
              if (items.value.length > 1) {
                items.value = items.value.filter((item) => {
                  if (itemCurrentlyDragging.value?.itemID !== undefined) {
                    return item.itemID !== itemCurrentlyDragging.value?.itemID;
                  } else {
                    // console.log(item.itemID, item_ID);
                    return item.itemID !== item_ID;
                  }
                });
                // console.log(items.value);
              }
            }
          }
          else {
            // console.log("case left to head")
            items.value = items.value.filter(
              (item) =>
                item.id !== itemCurrentlyDragging.value?.id
            );
          }
        } else if (
          itemCurrentlyDragging.value?.draggable === "" &&
          items.value[0] !== undefined

        ) {
          if (items.value[0].draggable !== "") {
            // console.log("case 1 - 2");
            //console.log(items.value);
            items.value = items.value.filter((item) => {
              if (itemCurrentlyDragging.value?.itemID !== undefined) {
                return item.itemID !== itemCurrentlyDragging.value?.itemID;
              } else {
                return item.itemID !== item_ID;
              }
            });
            //items.value = items.value;
            // console.log(items.value);
          }
        }
        return;
      }
      if (containerGroup.value === "singleimage" && itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
        // console.log("case 1");
        if (
          itemCurrentlyDragging.value?.draggable !== "" &&
          items.value[0] !== undefined
        ) {
          if (itemCurrentlyDragging.value?.draggable !== "original") {
            if (
              items.value[0].draggable === "singleimage" &&
              itemCurrentlyDragging.value?.itemID !== undefined
              && itemCurrentlyDragging.value?.fieldtype !== "fieldset"
            ) {
              // console.log("case 1 - 3");


              //console.log(itemCurrentlyDragging.value?.itemID);
              //console.log(items.value);
              items.value = items.value.filter((item) => {
                if (itemCurrentlyDragging.value?.itemID !== undefined) {
                  return item.itemID !== itemCurrentlyDragging.value?.itemID;
                } else {
                  return item.itemID !== item_ID;
                }
              });


              // items.value = items.value.filter(
              //   (item) => item.id !== itemCurrentlyDragging.value?.id
              // );
              // items.value = items.value;
              // console.log(items.value);
              //console.log(items.value)
            } else if (items.value[0].draggable === "singleimage" && itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
              // console.log("case 1 - 4");
              // console.log(items.value);
              if (items.value.length <= 1) {
                return;
              }
              if (items.value.length > 1) {
                items.value = items.value.filter((item) => {
                  if (itemCurrentlyDragging.value?.itemID !== undefined) {
                    return item.itemID !== itemCurrentlyDragging.value?.itemID;
                  } else {
                    // console.log(item);
                    return item.itemID !== item_ID;
                  }
                });
                // console.log(items.value);
              }
            }
          }
          else {
            // console.log("case left to head")
            if (itemCurrentlyDragging.value?.data?.strDatatype === "8" && itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
              items.value = items.value.filter(
                (item) =>
                  item.id !== itemCurrentlyDragging.value?.id
              );
            }
          }
        } else if (
          itemCurrentlyDragging.value?.draggable === "" &&
          items.value[0] !== undefined
          && itemCurrentlyDragging.value?.fieldtype !== "fieldset"
        ) {
          if (items.value[0].draggable !== "") {
            // console.log("case 1 - 2");
            //console.log(items.value);
            items.value = items.value.filter((item) => {
              if (itemCurrentlyDragging.value?.itemID !== undefined) {
                return item.itemID !== itemCurrentlyDragging.value?.itemID;
              } else {
                return item.itemID !== item_ID;
              }
            });
            //items.value = items.value;
            // console.log(items.value);
          }
        }
        return;
      }
      if (
        itemCurrentlyDragging.value?.draggable !== "" &&
        containerGroup.value !== "singleimage"
        && itemCurrentlyDragging.value?.fieldtype !== "fieldset"
        && itemCurrentlyDragging.value?.data?.strDatatype !== "8"

      ) {
        // console.log("case 4");

        items.value = items.value.filter(
          (item) =>
            item.id !== itemCurrentlyDragging.value?.id
        );
      } else if (containerGroup.value === "" && items.value[0] !== undefined) {
        //console.log(itemCurrentlyDragging.value?.draggable);
        // console.log("case 5");
        // 
        items.value = items.value.filter(
          (item) => item.id !== itemCurrentlyDragging.value?.id
        );

      }
    }
  });

  // สิ้นสุดการ drag
  const onDragEndClone = () => {
    // console.log("Endclone");

    if (
      itemCurrentlyDragging.value?.draggable !== ""
    ) {
      console.log("Endclone");
    }
  };

  // when an item is moved to an empty container
  const onDragOver = () => {
    // console.log(items.value)
    // console.log(groupType.value);
    // console.log(itemCurrentlyDragging.value?.draggable)
    // console.log(containerIdCurrentlyDraggedOver.value)
    // console.log(id)
    // console.log(transitioning)
    // console.log(itemCurrentlyDragging.value)
    // console.log(items.value.length)

    if (
      transitioning ||
      !itemCurrentlyDragging.value ||
      containerIdCurrentlyDraggedOver.value === id
    ) {
      return;
    }

    if (items.value.length > 0) {

      items.value = changeArrayOrder(
        items.value,
        itemCurrentlyDragging.value,
        items.value.length
      );

      items.value = items.value.filter(
        (item) => item.id !== itemCurrentlyDragging.value?.id
      );
      return;
    }

    containerGroup.value = groupType.value.slice();
    // console.log(itemCurrentlyDragging.value.itemID)
    containerIdCurrentlyDraggedOver.value = id;
    item_ID = itemCurrentlyDragging.value.itemID;

    if (
      itemCurrentlyDragging.value?.draggable === "" &&
      containerGroup.value === "clone"
      && itemCurrentlyDragging.value?.data?.strDatatype !== "8"

    ) {
      if (itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
        // console.log("event 1");

        //itemCurrentlyDragging.value.draggable = "clone";

        items.value = [
          {
            data: itemCurrentlyDragging.value.data,
            id: itemCurrentlyDragging.value.id,
            fieldtype: itemCurrentlyDragging.value.fieldtype,
            draggable: "clone",
            itemID: itemCurrentlyDragging.value.itemID,
          },
        ].slice();

        //itemCurrentlyDragging.value = undefined;

        //items.value = items.value;
        //console.log(items.value);

        return;
      } else if (itemCurrentlyDragging.value?.fieldtype !== "fieldset") {

        // console.log("event 2");
        items.value = [itemCurrentlyDragging.value];
        itemCurrentlyDragging.value = undefined;
        //items.value = items.value;

        //console.log(items.value);
        return;
      }
    }

    if (
      itemCurrentlyDragging.value?.draggable !== "" &&
      containerGroup.value === "clone" && itemCurrentlyDragging.value?.fieldtype !== "fieldset"
      && itemCurrentlyDragging.value?.data?.strDatatype !== "8"
    ) {

      // console.log("event 3");
      items.value = [
        {
          data: itemCurrentlyDragging.value.data,
          id: itemCurrentlyDragging.value.id,
          fieldtype: itemCurrentlyDragging.value.fieldtype,
          draggable: "clone",
          itemID: itemCurrentlyDragging.value.itemID,
        },
      ].slice();


      //itemCurrentlyDragging.value = undefined;
      //items.value = items.value;
      // console.log(items.value);

      return;
    } else if (
      itemCurrentlyDragging.value?.draggable !== "" &&
      containerGroup.value === "singleimage" && itemCurrentlyDragging.value?.data?.strDatatype === "8"
    ) {

      // console.log("event 3");
      items.value = [
        {
          data: itemCurrentlyDragging.value.data,
          id: itemCurrentlyDragging.value.id,
          fieldtype: itemCurrentlyDragging.value.fieldtype,
          draggable: "singleimage",
          itemID: itemCurrentlyDragging.value.itemID,
        },
      ].slice();


      //itemCurrentlyDragging.value = undefined;
      //items.value = items.value;
      // console.log(items.value);

      return;
    } else if (containerGroup.value === "") {
      // console.log("out event");
      items.value = [itemCurrentlyDragging.value];
      //console.log(items.value);
    }
  };

  // handle event emitted from draggableItem
  const onItemDragOver = ({ position }: { position: number }) => {
    if (transitioning || !itemCurrentlyDragging.value) {
      return;
    }
    // console.log(itemCurrentlyDragging.value?.data?.strDatatype)
    // console.log("switch");
    // console.log(groupType.value)
    item_ID = itemCurrentlyDragging.value.itemID;
    if (groupType.value === "clone") {
      if (containerGroup.value === "singleimage" && itemCurrentlyDragging.value?.data?.strDatatype === "8" && itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
        items.value = changeArrayOrder(
          items.value,
          itemCurrentlyDragging.value,
          position
        );
        items.value = items.value.filter(
          (item) => {
            return item.id == itemCurrentlyDragging.value?.id
          }
        );
      } else if (containerGroup.value !== "singleimage" && itemCurrentlyDragging.value?.data?.strDatatype !== "8" && itemCurrentlyDragging.value?.fieldtype !== "fieldset") {
        // console.log("switch 3")
        items.value = changeArrayOrder(
          items.value,
          itemCurrentlyDragging.value,
          position
        );
        // console.log(items.value)
      }


    } else if (groupType.value !== "clone") {
      // console.log("switch 2")
      items.value = changeArrayOrder(
        items.value,
        itemCurrentlyDragging.value,
        position
      );
      // console.log(items.value)
    }
  };

  return {
    id,
    items,
    onDragOver,
    onItemDragOver,
    onDragEndClone,
  };
};

const useDraggableItem = (
  item: Ref<any>,
  position: Ref<number>,
  containerId: Ref<number>,
  context: SetupContext,
  groupType: Ref<string>
) => {
  const draggableItemEl = ref();
  const isDragging = ref(
    item.value.id === itemCurrentlyDragging.value?.id ? true : false
  );
  const middleY = ref();

  onMounted(async () => {
    const box = draggableItemEl.value.getBoundingClientRect();
    middleY.value = box.top + box.height / 2;
  });

  onUpdated(() => {
    const box = draggableItemEl.value.getBoundingClientRect();
    middleY.value = box.top + box.height / 2;
  });

  const onDragStart = () => {
    itemCurrentlyDragging.value = item.value;
    containerIdCurrentlyDraggedOver.value = containerId.value;
    isDragging.value = true;
    containerGroup.value = groupType.value.slice();
  };

  const onDragEnd = () => {
    //console.log(groupType.value)
    if (groupType.value === "clone") {
      context.emit("DragEndClone");
      item.value.draggable = groupType.value.slice();

      itemCurrentlyDragging.value = undefined;
    } else {
      item.value.draggable = "";
      itemCurrentlyDragging.value = undefined;
    }
  };

  const onDragOver = throttle((e: DragEvent) => {
    //console.log(groupType.value);
    containerGroup.value = groupType.value.slice();
    item.value.draggable = groupType.value.slice();
    if (item.value.id === itemCurrentlyDragging.value?.id) {
      return;
    }

    if (containerIdCurrentlyDraggedOver.value !== containerId.value) {
      containerIdCurrentlyDraggedOver.value = containerId.value;
    }

    const offset = middleY.value - e.clientY;
    //context.emit("DragEndClone");
    context.emit("itemDragOver", {
      position: offset > 0 ? position.value : position.value + 1,
    });
  }, 10);

  const transitionStart = () => {
    transitioning = true;
  };

  const transitionEnd = () => {
    transitioning = false;
  };

  watch(itemCurrentlyDragging, () => {
    if (itemCurrentlyDragging.value) {
      return;
    }
    isDragging.value = false;
  });

  return {
    draggableItemEl,
    isDragging,
    onDragStart,
    onDragOver,
    onDragEnd,
    transitionStart,
    transitionEnd,
  };
};

export { useDraggableContainer, useDraggableItem };
