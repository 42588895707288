<template>
  <!-- <div class="overlay" @click="$emit('Cancel')"></div> -->
  <div class="p-5 rounded-3 shadow config-display bg-white m-sm-3" :style="
    device == 'mobile' || device == 'tablet' ? 'width:80% !important;' : ''
  ">
    <label for="" class="fnt-medium fnt-subtitle mb-3" :class="device == 'mobile' ? 'fnt-body' : ''">{{ $t("23")
    }}</label>
    <div class="row g-3">
      <div class="col-md-6 col-sm-12">
        <div class="left-config-display rounded-2 p-3">
          <div class="d-flex justify-content-between align-items-center p-3">
            <label for="" class="fnt-medium fnt-subtitle p-0 m-0" :class="device == 'mobile' ? 'fnt-caption' : ''">{{
                $t("75")
            }}</label>
            <button @click="onSelectAll()" class="btn p-0 m-0 fnt-regular fnt-body text-secondary"
              :class="device == 'mobile' ? 'fnt-caption' : ''">
              {{ $t("77") }}
            </button>
          </div>
          <div class="config-list-group" :style="device == 'mobile' ? 'height:150px !important;' : ''">
            <div class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                w-100
              ">
              <template v-if="FieldList.length != 0">
                <div v-for="(item, index) in FieldList" :key="index" class="w-100">
                  <ListAdd @Add="selectionList(item)" :name="
                    typeof item.head == 'string'
                      ? $t(item.head)
                      : item.head[filterLang(item.head)]
                  " />
                </div>
              </template>
              <!-- <draggable v-model="FieldList" transition="100" class="drop-zone">
                <template v-slot:item="{ item }">
                  <div>
                    <ListAdd @Add="selectionList(item)" :name="$t(item.head)" />
                  </div>
                </template>
                 </draggable>
              -->

              <template v-if="FieldList.length == 0">
                <label for="" class="p-3">{{ $t("81") }}</label>
              </template>
            </div>
            <!-- <pre>{{ JSON.stringify(FieldList, undefined, 4) }}</pre> -->
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="left-config-display rounded-2 p-3">
          <div class="d-flex justify-content-between align-items-center p-3">
            <label for="" class="fnt-medium fnt-subtitle p-0 m-0" :class="device == 'mobile' ? 'fnt-caption' : ''">{{
                $t("76")
            }}</label>
            <div class="d-flex align-items-center justify-content-between">
              <!-- <button class="btn p-0 m-0 fnt-body me-3">{{ $t("80") }}</button> -->
              <button @click="onClear()" class="btn p-0 m-0 fnt-regular fnt-body text-secondary"
                :class="device == 'mobile' ? 'fnt-caption' : ''">
                {{ $t("80") }}
              </button>
            </div>
          </div>
          <div class="config-list-group" v-if="SelectedListOld != []"
            :style="device == 'mobile' ? 'height:150px !important;' : ''">
            <div class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                w-100
              ">
              <!-- <div class="drop-zone">
                <div v-for="(item, index) in SelectedListExcept" :key="index">
                  <div><ListLock :name="$t(item.head)" /></div>
                </div>
              </div> -->
              <!-- <template v-if="SelectedList.length != 0">
                <div v-for="(item, index) in SelectedList" :key="index">
                  <ListClose
                    @Remove="unselectionList(item)"
                    :name="$t(item.head)"
                  />
                </div>
              </template> -->
              <draggable v-if="show == true" v-model="DPSelectedList" transition="100" ref="draggable"
                class="drop-zone w-100" :groupType="''">
                <template v-slot:item="{ item }">
                  <div>
                    <component :is="
                      onCheckSelectedListExcept(item)
                        ? 'ListLock'
                        : 'ListClose'
                    " @Remove="unselectionList(item)" :name="
  typeof item.head == 'string'
    ? $t(item.head)
    : item.head[filterLang(item.head)]
" />
                  </div>
                </template>
              </draggable>
            </div>
            <!-- <pre>{{ JSON.stringify(SelectedList, undefined, 4) }}</pre> -->
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-flex">
      <Button @click="onSaveSelectedList()" :name="$t('36')" classBtn="btn-primary me-3 btn-sm" />
      <Button @click="$emit('Cancel')" :name="$t('35')" classBtn="btn-cancel text-primary btn-sm" />
    </div>
  </div>
</template>

<script>
import Button from "../button/button.vue";
import ListClose from "./listRemove.vue";
import ListAdd from "./listAdd.vue";
import ListLock from "./listLock.vue";
import Draggable from "../DraggableContainer";
import customfilter from "@/typescript/filter";

export default {
  name: "Sample",
  emits: ["Ok", "Cancel"],
  components: {
    ListClose,
    ListAdd,
    ListLock,
    Button,
    Draggable,
  },

  props: {
    AllField: { type: Array },
    DefaultField: { type: Array },
    SelectedListExcept: { type: Array },
    SelectedListOld: { type: Array },
  },
  data() {
    return {
      FieldList: [],
      DPSelectedList: [],
      show: true,
      locales: '',
    };
  },
  beforeMount() {
    this.CheckIntersection();
  },

  mounted() {
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
  },
  methods: {
    filterLang(items) {
      // console.log(items);
      // console.log(this.defaultLang);
      if (items !== undefined && this.locales == null) {
        return customfilter.filterLang(items, this.locales);
      } else if (items !== undefined && this.locales != null) {
        return customfilter.filterLang(items, this.locales);
      }
    },
    showComponent() {
      this.show = true;
    },
    hideComponent() {
      this.show = false;
    },
    CheckIntersection() {
      // console.log(this.SelectedListOld);
      if (this.SelectedListOld == []) {
        this.FieldList = this.AllField.filter(
          (x) => !this.DefaultField.some((item) => x.field === item.field)
        );
        this.DPSelectedList = this.DefaultField.filter(
          (x) => !this.SelectedListExcept.some((item) => x.field === item.field)
        );
      } else if (this.SelectedListOld != []) {
        // console.log(typeof this.AllField)
        if (typeof this.AllField != "undefined") {
          this.FieldList = this.AllField.filter(
            (x) => !this.SelectedListOld.some((item) => x.field === item.field)
          );
        }
        this.DPSelectedList = this.SelectedListOld.slice();
        // console.log(this.DPSelectedList);
      }
    },
    selectionList(event) {
      this.hideComponent();
      // console.log(this.SelectedListOld);
      this.DPSelectedList.push.apply(
        this.DPSelectedList,
        this.FieldList.filter((item) => item == event)
      );
      this.FieldList = this.FieldList.filter((item) => item !== event);

      setTimeout(() => {
        this.showComponent();
      }, 20);
    },
    unselectionList(event) {
      this.hideComponent();
      this.FieldList.push.apply(
        this.FieldList,
        this.DPSelectedList.filter((item) => item == event)
      );
      this.DPSelectedList = this.DPSelectedList.filter(
        (item) => item !== event
      );

      this.FieldList = this.FieldList.sort((a, b) => {
        const x = this.$t(a["head"]);
        const y = this.$t(b["head"]);

        return x < y ? -1 : x > y ? 1 : 0;
      });
      setTimeout(() => {
        this.showComponent();
      }, 20);
    },
    onSaveSelectedList() {
      // console.log(this.DPSelectedList);

      this.$emit("Ok", this.DPSelectedList);
    },
    onClear() {
      this.hideComponent();
      this.FieldList = this.AllField?.filter(
        (x) => !this.DefaultField.some((item) => x.field === item.field)
      );
      this.DPSelectedList = [];
      //this.SelectedList = this.SelectedListExcept;
      this.DPSelectedList = this.DefaultField?.filter((x) =>
        this.AllField.some((item) => x.field === item.field)
      );

      setTimeout(() => {
        this.showComponent();
      }, 50);
    },
    onSelectAll() {
      this.hideComponent();
      //this.SelectedList = Object.assign(this.SelectedList, this.AllField);
      for (var field in this.FieldList) {
        // console.log(this.FieldList[field]);
        this.DPSelectedList.push(this.FieldList[field]);
      }
      this.FieldList = [];
      setTimeout(() => {
        this.showComponent();
      }, 20);
    },
    onCheckSelectedListExcept(Except) {
      return this.SelectedListExcept.some(
        (item) => Except.field === item.field
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.config-display {
  z-index: 2;
  width: 745px;

  .left-config-display {
    border: solid 1px $black-300;

    .config-list-group {
      height: 350px;
      overflow: auto;
    }
  }

  .right-config-display {
    border: solid 1px $black-300;

    .config-list-group {
      height: 350px;
      overflow: auto;
    }
  }
}

.draggable-item {
  display: flex;

  margin: 1%;
  padding: 1%;
}

.drop-zone {
  margin: 0px;
  padding: 0px;
}

pre {
  background-color: #eee;
  margin: 30px;
  padding: 10px;
  width: 400px;
  min-height: 200px;
}

.btn-sm {
  width: 66px !important;
}

.list-mobile {
  padding: 4px !important;
}
</style>
