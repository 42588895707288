'use strict';


const crypto = require('crypto');
// const buffer = require('Buffer');
// const ENC_KEY = "bf3c199c2470cb477d907b1e0917c17b"; // set random encryption key
const ENC_KEY = "MCG8ThWbyITJ72Rl/I5rJNZ5WFPgWA74VkPsdWsW6JQ="; // set random encryption key
const IV = "YJe7IEsc0Dw/N3VaJcQYMg=="; // set random initialisation vector
// const IV = "HK6lHnWcWB+KLWLMY68UQQ=="; // set random initialisation vector
// ENC_KEY and IV can be generated as crypto.randomBytes(32).toString('hex');
const utf8Encode = new TextEncoder();
// class AesEncrytion {

export function encrypt(val: any) {
    const cipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(ENC_KEY, 'base64'), Buffer.from(IV, 'base64'));
    let encrypted = cipher.update(val, "utf8", "base64");
    encrypted += cipher.final('base64');
    return encrypted;
}

export function decrypt(encrypted: any) {
    // const iv = crypto.randomBytes(16);
    // const key = crypto.randomBytes(32);
    // console.log(iv);
    // console.log(iv.toString('base64'));
    // console.log(key.toString('base64'));

    // console.log(utf8Encode.encode(ENC_KEY));
    // const iv = Buffer.from(IV, 'base64').toString('hex').substring(0, 16)

    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(ENC_KEY, 'base64'), Buffer.from(IV, 'base64'));
    const decrypted = decipher.update(encrypted, 'base64', 'utf8');

    return (decrypted + decipher.final('utf8'));
}

// }
// export { encrypt, encrypted }
// as AesEncrytion;

// star this gist if you found it useful