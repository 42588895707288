<template>
  <!-- <label class="toggle">
    <input type="checkbox" v-model="checked" @change="checkToggle" />
    <span class="slider"></span>
    <p class="label-checked fnt-body text-white" v-if="checked === true">
      {{ open }}
    </p>
    <p class="label-uncheck fnt-body text-white" v-else>{{ close }}</p>
  </label> -->

  <button class="box btn m-0 p-0 border-0">
    <div class="toggle position-relative rounded-1 p-1">
      <input
        type="checkbox"
        name=""
        id=""
        v-model="checked"
        @change="checkToggle"
        ref="checkbox"
        :disabled="disabled"
      />

      <div
        class="w-100 rounded-1 slider"
        :class="checked == true || checked == 1 ? 'active-slider' : ''"
      >
        <div class="slideStart"></div>

        <span
          v-if="checked == true || checked == 1"
          class="px-1 text-toggle-fst text-white"
          :class="
            checked == true || checked == 1 ? 'text-toggle-fst-active' : ''
          "
          >{{ open }}</span
        >
        <span v-else class="px-1 text-toggle-lst text-white">{{ close }}</span>
        <div
          class="slideEnd rounded-1"
          :class="checked == true || checked == 1 ? 'slideEnd-active' : ''"
        ></div>
      </div>
    </div>
  </button>

  <!-- <button
    class="box btn m-0 p-0 border-0 "
    data-bs-toggle="tooltip"
    :title="message"
    data-placement="top"
    v-else
  >
    <div class="toggle position-relative rounded-1 p-1">
      <input
        type="checkbox"
        name=""
        id=""
        v-model="checked"
        @change="checkToggle"
        ref="checkbox"
        :disabled="disabled"
      />

      <div
        class="w-100 rounded-1 slider-disable"
        :class="checked == true || checked == 1 ? 'active-slider' : ''"
      >
        <div class="slideStart"></div>

        <span
          v-if="checked == true || checked == 1"
          class="px-1 text-toggle-fst text-white"
          :class="
            checked == true || checked == 1 ? 'text-toggle-fst-active' : ''
          "
          >{{ open }}</span
        >
        <span v-else class="px-1 text-toggle-lst text-white">{{ close }}</span>
        <div
          class="slideEnd rounded-1"
          :class="checked == true || checked == 1 ? 'slideEnd-active' : ''"
        ></div>
      </div>
    </div>
  </button> -->
</template>

<script>
export default {
  data() {
    return {
      checked: null,
    };
  },
  emits: ["input", "close", "post", "delete"],
  mounted() {
    // console.log(this.checked);
    // console.log(this.$refs.checkbox);
    if (this.checked == false || this.checked == 0) {
      this.checked = this.usechecked;
      this.$emit("input", this.checked);
    }
    if (this.blnAuto == true) {
      this.checked = this.usechecked;
      this.$emit("input", this.checked);
    }
    this.checked = this.usechecked==1|| this.usechecked;

  },
  methods: {
    checkToggle() {
      this.$emit("input", this.checked);

      // console.log(this.checked)
    },

    // widthToggle() {
    //   let width = document.querySelector(".toggle");
    //   if(width.style.width > "76px"){
    //     width.style.width = "130px"
    //   }
    //   console.log(width.offsetWidth);
    // },
  },
  props: {
    open: { type: String },
    close: { type: String },
    disabled: { type: Boolean },
    tooltip: { type: Boolean, default: true },
    message: { type: String },
    usechecked: { type: Boolean, default: false },
    blnAuto: { type: Boolean, default: false },
    // size: { type: String, default: "default" },
  },
  watch: {
    usechecked: {
      deep: true,
      handler(newValue){
        this.checked = newValue
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color.scss";
.active-slider {
  background-color: $primary-500 !important;
}

.box {
  width: fit-content !important;
  .toggle {
    width: fit-content;
    min-width: 76px;
    height: fit-content;
    position: relative;
    transition: 0.2s;
    display: inline-block;
    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      transition: 0.2s;
      // &:checked + .slider {
      //   background-color: $primary-500;
      //   transition: 0.2s;
      //   border: solid 1px $primary-200;
      // }
      // &:checked + .toggle-slide {
      //   background-color: $primary-500 !important;
      //   transition: 0.2s !important;
      //   border: solid 1px $primary-200 !important;
      // }
      // &:checked + .slider .slideEnd {
      //   position: absolute;
      //   left: calc(100% - 29px);
      //   transition: 0.2s;
      //   background-color: $primary-50;
      //   transform: translateY(-50%);
      // }
      // &:checked + .slider .text-toggle-fst {
      //   transform: translateX(-25px);
      //   transition: 0.2s;
      // }
      //   &:checked + .slider .slideEnd{
      //       display: none;
      //   }
    }
    .slider {
      background-color: $black-500;
      min-width: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      height: 36px;
      position: relative;
      padding: 2px;
      border: solid 1px $black-200;
      .slideStart {
        width: 25px;
        height: 100%;
        background-color: $white;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
      }
      .slideEnd {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $black-200;
        width: 25px;
        height: 90%;
        transition: 0.2s;
        margin-left: 2px;
      }
      .slideEnd-active {
        left: calc(100% - 29px) !important;
        transition: 0.2s !important;
        background-color: $primary-50 !important;
        transform: translateY(-50%) !important;
      }
      .text-toggle-fst {
        transition: 0.2s;
        transform: translateX(0);
      }
      .text-toggle-fst-active {
        transform: translateX(-25px);
        transition: 0.2s;
      }
    }
  }
}
// .toggle {
//   position: relative;
//   display: inline-block;
//   min-width: 76px !important;
//   width: fit-content !important;
//   height: 34px;
//   cursor: pointer;
//   input[type="checkbox"] {
//     opacity: 0;
//     width: 0;
//     height: 0;
//     &:checked + .slider {
//       transition: 0.2s;
//       background-color: $primary-500;
//     }
//     &:checked + .slider::before {
//       background-color: $primary-50;
//       transition: 0.2s;
//       transform: translateY(50%) translateX(43px);
//     }
//   }
//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: $black-500;
//     transition: 0.2s;
//     border-radius: 4px;
//     border: solid 1px $primary-200;
//     width: 100%;
//     &::before {
//       position: absolute;
//       content: "";
//       height: 26px;
//       width: 23px;
//       left: 3px;
//       bottom: 50%;
//       background-color: $white;
//       transition: 0.2s;
//       border-radius: 4px;
//       transform: translateY(50%);
//     }
//   }
// }
// .label-checked {
//   position: absolute;
//   top: 50%;
//   left: 10%;
//   transform: translateY(-50%);
// }
// .label-uncheck {
//   position: absolute;
//   top: 50%;
//   right: 10%;
//   transform: translateY(-50%);
// }
</style>